import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { FunctionComponent } from "react";

export interface ShimmerBox {
	height: string;
	width: string;
	marginTop?: string;
	borderRadius?: string;
}

export interface ShimmerProps {
	boxes: ShimmerBox[];
	isBrand?: boolean;
	gap?: string;
	direction?: "row" | "column";
}

export const Shimmer: FunctionComponent<ShimmerProps> = (props) => {
	return (
		<ShimmerStyled
			isBrand={props.isBrand}
			direction={props.direction}
			gap={props.gap}
		>
			{props.boxes.map((box, index) => {
				return (
					<div
						className={"shine"}
						style={{
							width: box.width,
							height: box.height,
							marginTop: box.marginTop || "16px",
							borderRadius: box.borderRadius || "4px",
						}}
						key={index}
					></div>
				);
			})}
		</ShimmerStyled>
	);
};

const keyframesAnimation = keyframes`
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
`;

export const ShimmerStyled = styled.div<{
	isBrand?: boolean;
	direction?: "row" | "column";
	gap?: string;
}>`
	display: flex;
	flex-direction: ${(props) => props.direction || "column"};
	width: 100%;
	height: 100%;
	gap: ${(props) => props.gap || "0px"};

	.shine {
		border-radius: 4px;
		background: ${(props) =>
			props.isBrand
				? "linear-gradient(90deg, rgba(255, 255, 255, 0.30) 0%, rgba(224, 224, 224, 0.00) 100%)"
				: "#f6f7f8 linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #F0F0F0 100%) no-repeat"};
		background-size: 800px 304px;
		display: inline-block;
		position: relative;

		-webkit-animation-duration: 1s;
		-webkit-animation-fill-mode: forwards;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-name: ${keyframesAnimation};
		-webkit-animation-timing-function: linear;
	}
`;
