import { Icon } from "../common/icons-props";

export const LogoutIcon: Icon = (props) => {
	const { width, height, color, isSelected } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
		>
			<g clip-path="url(#clip0_2112_174)">
				<path
					d="M21.7717 17.4238C21.2539 17.4238 20.8342 17.8436 20.8342 18.3613V22.0209C20.8342 22.7278 20.2592 23.3027 19.5524 23.3027H14.6364V8.65746C14.6364 7.49918 14.0611 6.50279 13.058 5.92359L6.04563 1.875H19.5524C20.2592 1.875 20.8342 2.44998 20.8342 3.1568V7.33107C20.8342 7.84887 21.2539 8.26857 21.7717 8.26857C22.2894 8.26857 22.7092 7.84887 22.7092 7.33107V3.1568C22.7092 1.41615 21.293 0 19.5524 0H4.76617C3.02553 0 1.60938 1.41615 1.60938 3.1568V22.9589C1.60938 24.1172 2.18465 25.1136 3.18777 25.6928L9.90121 29.5688C10.4028 29.8583 10.9412 30.0032 11.4796 30.0031C12.018 30.003 12.5565 29.8583 13.058 29.5688C14.0611 28.9897 14.6363 27.9932 14.6363 26.8349V25.1777H19.5523C21.293 25.1777 22.7091 23.7616 22.7091 22.0209V18.3613C22.7092 17.8436 22.2895 17.4238 21.7717 17.4238ZM12.7614 26.8349C12.7614 27.3122 12.5338 27.7063 12.1205 27.9449C11.7072 28.1835 11.252 28.1835 10.8388 27.9449L4.12527 24.069C3.71195 23.8304 3.48438 23.4362 3.48438 22.9589V3.1568C3.48438 2.96812 3.52533 2.78883 3.59887 2.6274L12.1205 7.5474C12.5338 7.786 12.7614 8.18027 12.7614 8.65746V26.8349ZM28.118 13.2518L25.0715 16.2982C24.8885 16.4813 24.6486 16.5728 24.4087 16.5728C24.1688 16.5728 23.9288 16.4813 23.7458 16.2982C23.3796 15.9321 23.3796 15.3385 23.7458 14.9724L25.1918 13.5264H16.5786C16.0608 13.5264 15.6411 13.1067 15.6411 12.5889C15.6411 12.0711 16.0608 11.6514 16.5786 11.6514H25.1918L23.7458 10.2053C23.3796 9.83924 23.3796 9.24563 23.7458 8.87953C24.1119 8.51338 24.7055 8.51338 25.0716 8.87953L28.118 11.9259C28.4841 12.2921 28.4841 12.8857 28.118 13.2518Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2112_174">
					<rect width="30" height="30" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
