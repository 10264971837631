import React, { useEffect, useState } from "react";
import { Modal } from "../../components/Modal";
import { TitleMedium } from "../../common/Typography";
import { Input } from "../../components/Input";
import { COLORS } from "../../common/Theme";
import { Button } from "../../components/Button";
import styled from "styled-components";

export const AddEditSlackModal = (props: {
	type: "add" | "edit";
	onSubmit: (slackName: string, slackChannel: string) => void;
	onClose: () => void;
	defaultInput: any;
}) => {
	const [slackName, setSlackName] = useState(props.defaultInput?.slackName);
	const [slackChannel, setSlackChannel] = useState(
		props.defaultInput?.slackChannel
	);

	useEffect(() => {
		setSlackName(props.defaultInput?.slackName);
		setSlackChannel(props.defaultInput?.slackChannel);
	}, [props.defaultInput]);

	return (
		<Modal width={"700px"} onClose={props.onClose}>
			<StyledSlackAddEditModal>
				<TitleMedium color={COLORS.common.black}>
					{props.type === "add" ? "Add Slack" : "Edit Slack"}
				</TitleMedium>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						background: "rgba(101, 27, 220, 0.10)",
						padding: "20px",
					}}
				>
					<div className="title-text">
						HOW TO SET UP THE SLACK WEBHOOK?
					</div>
					<div className="body-text">
						or setting up a Slack webhook follow the link to your
						Slack team and set up a{" "}
						<a
							className="link-text" target="_blank"
							href="https://my.slack.com/services/new/incoming-webhook/"
						>
							Incoming Webhook
						</a>
					</div>
					<div className="body-text">
						Once the webhook is configured, copy the webhook URL and
						paste it in the configuration section below. Customise
						the channel or user where the message will be delivered.
					</div>
				</div>
				<Input
					inputValue={slackName}
					onInputChange={setSlackName}
					placeHolder="Enter Slack Channel name"
					width="auto"
					style={{ margin: "20px 0px" }}
				/>
				<Input
					inputValue={slackChannel}
					onInputChange={setSlackChannel}
					placeHolder="Enter Slack Webhook URL"
					width="auto"
					style={{ margin: "20px 0px" }}
				/>
				<div
					style={{
						display: "flex",
						gap: "10px",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<Button
						buttonType="inverse"
						text="Cancel"
						width="auto"
						fontSize={14}
						onClick={() => {
							props.onClose();
						}}
					/>
					<Button
						buttonType="primary"
						text={props.type === "add" ? "Add" : "Update"}
						width="auto"
						fontSize={14}
						onClick={() => {
							props.onSubmit(slackName, slackChannel);
						}}
					/>
				</div>
			</StyledSlackAddEditModal>
		</Modal>
	);
};

const StyledSlackAddEditModal = styled.div`
	background: white;
	padding: 40px;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	.title-text {
		color: #000;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
	}

	.body-text {
		color: #000;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.link-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 900;
		line-height: normal;
		text-decoration-line: underline;
	}
`;
