import { useAppStore } from "./common/store";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type ApiResponse<T> = {
	success: boolean;
	data?: T;
	error?: string;
};

export type ApiOptions = {
	contentType?: "json" | "form-data";
	includeCsrf?: boolean;
};

const baseUrl = "https://pagespeed.fulgid.in/api"; // Replace with your API base URL

async function fetchCsrfToken(): Promise<string> {
	// Implement the logic to fetch the CSRF token from your API
	// For example, it might look something like this:
	const response = await fetch(`${baseUrl}/csrf-token`, { method: "GET" });
	const data = await response.json();
	console.log("data", data);

	return data?.[0] ?? ""; // Adjust this based on your API's response structure
}

async function callApi<T>(
	url: string,
	method: HttpMethod,
	data?: any,
	options?: ApiOptions
): Promise<ApiResponse<T>> {
	const fullUrl = baseUrl + url;

	let headers = new Headers({
		"Content-Type":
			options?.contentType !== "form-data"
				? "application/json"
				: "application/x-www-form-urlencoded",
		Authorization: `Bearer ${useAppStore.getState().token}`,
	});

	let body: BodyInit | undefined;

	console.log("dev callApi", { url, method, data, options });

	if (data) {
		if (options?.contentType === "form-data") {
			const formData = new FormData();
			for (const key in data) {
				formData.append(key, data[key]);
			}

			// Remove the Content-Type header so the browser can set it with the correct boundary
			headers.delete("Content-Type");

			if (options.includeCsrf) {
				const csrfToken = await fetchCsrfToken();
				document.cookie = `XSRF-TOKEN=${csrfToken}; path: '/'`; // You might want to set other cookie attributes as needed
				headers.append("X-CSRF-TOKEN", csrfToken);
				// formData.append("XSRF-TOKEN", csrfToken); // Adjust based on your CSRF token field name
			}

			body = formData;
		} else {
			body = JSON.stringify(data);
		}
	}

	const requestOptions: RequestInit = {
		method,
		headers,
		body,
	};

	console.log("headers", headers);

	try {
		const response = await fetch(fullUrl, requestOptions);
		const responseData: T = await response.json();

		return {
			success: response.ok,
			data: responseData,
		};
	} catch (error) {
		return {
			success: false,
			error: "An error occurred while fetching the data.",
		};
	}
}

export const api = {
	get: async <T>(
		url: string,
		options?: ApiOptions
	): Promise<ApiResponse<T>> => callApi<T>(url, "GET", undefined, options),
	post: async <T>(
		url: string,
		data: any,
		options?: ApiOptions
	): Promise<ApiResponse<T>> => callApi<T>(url, "POST", data, options),
	put: async <T>(
		url: string,
		data: any,
		options?: ApiOptions
	): Promise<ApiResponse<T>> => callApi<T>(url, "PUT", data, options),
	delete: async <T>(
		url: string,
		options?: ApiOptions
	): Promise<ApiResponse<T>> => callApi<T>(url, "DELETE", undefined, options),
	patch: async <T>(
		url: string,
		data: any,
		options?: ApiOptions
	): Promise<ApiResponse<T>> => callApi<T>(url, "PATCH", data, options),
};
