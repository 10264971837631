import React from "react";
import styled from "styled-components";
import { EllipseIcon } from "../../icons/EllipseIcon";
import { format } from "date-fns";

// export const InfoHoverComponent = () => {
// 	return <div>InfoHoverComponent</div>;
// };

export const InfoHoverComponent = (props: {
	createdDate: string;
	quickResults: any;
}) => {
	return (
		<StyledInfoHoverComponent>
			<div className="info-date-text">
				{format(new Date(props.createdDate), "dd MMM yy")}
			</div>
			<div style={{
					display: "flex",
					flexDirection: "column",
					gap: "5px"
				}}>
				<SinglePercentageComponent
					name={"Performance"}
					color={"#0FC000"}
					percentage={props.quickResults?.performance_score ?? 0}
				/>
				<SinglePercentageComponent
					name={"Accessibility"}
					color={"#651BDC"}
					percentage={props.quickResults?.accessibility_score ?? 0}
				/>
				<SinglePercentageComponent
					name={"Best Practices"}
					color={"#0494A0"}
					percentage={props.quickResults?.best_practices_score ?? 0}
				/>
				<SinglePercentageComponent
					name={"SEO"}
					color={"#ED7717"}
					percentage={props.quickResults?.seo_score ?? 0}
				/>
				<SinglePercentageComponent
					name={"PWA"}
					color={"#FF3333"}
					percentage={props.quickResults?.pwa_score ?? 0}
				/>
			</div>
			<div className="info-footer-text">
				Status is based only on performance.
			</div>
		</StyledInfoHoverComponent>
	);
};

const SinglePercentageComponent = (props: {
	name: string;
	color: string;
	percentage: number;
}) => {
	return (
		<div
			style={{
				display: "flex",
				alignItems: "center",
				gap: "10px",
				
			}}
		>
			<div style={{ display: "flex" }}>
				<EllipseIcon width={5} height={5} color={props.color} />
			</div>
			<div className="info-threshold-text">{props.name}</div>
			<div className="info-per-text">
				{Math.ceil(props.percentage * 100)}%
			</div>
		</div>
	);
};

const StyledInfoHoverComponent = styled.div`
	display: flex;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
	flex-direction: column;
	align-items: center;
	padding: 15px 5px;
	background: #fff;
	border-radius: 6px;
	gap: 10px;

	
	.info-footer-text {
		color: #333 !important;
		font-family: Lato;
		font-size:10px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.info-date-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.info-threshold-text {
		color: #333;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		display: flex;
		flex: 2;
	}

	.info-per-text {
		color: #000;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
`;
