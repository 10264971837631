import { Input } from "../../components/Input";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { Avatar, Switch } from "@mui/material";
import { useApi } from "../../useApi";
import { ChangePasswordModal } from "./ChangePasswordModal";
import toast from "react-hot-toast";

export const UserProfile = () => {
	const { data, error, loading } = useApi<any>({
		method: "get",
		url: "/user",
	});

	const [showModal, setShowModal] = useState(false);

	const [requestData, setRequestData] = useState<any>();

	const {
		data: cpData,
		error: cpError,
		fetchData: changePassword,
	} = useApi<any>({
		autoFetch: false,
		method: "post",
		url: `/password-change`,
		options: {
			contentType: "form-data",
		},
		requestData: requestData,
	});

	useEffect(() => {
		if (cpData) {
			toast.success("Password change success");
			setShowModal(false);
			setRequestData(undefined);
		}
		if (cpError) {
			toast.error("Password change failed");
			setShowModal(false);
			setRequestData(undefined);
		}
	}, [cpData, cpError]);

	useEffect(() => {
		if (requestData) {
			changePassword();
		}
	}, [requestData]);

	return (
		<StyledUserProfile>
			<div className="label-input-container">
				<div className="label-and-fixed-text">Display Picture</div>
				<Avatar>{data?.name?.[0] ?? ""}</Avatar>
			</div>
			<div className="label-input-container">
				<div className="label-and-fixed-text">Name</div>
				<Input
					width="250px"
					inputValue={data?.name ?? ""}
					disabled={true}
				/>
			</div>
			<div className="label-input-container">
				<div className="label-and-fixed-text">Email</div>
				<Input
					width="250px"
					inputValue={data?.email ?? ""}
					disabled={true}
				/>
			</div>
			<div className="label-input-container" style={{ gap: "70px" }}>
				<div className="label-and-fixed-text">Password</div>
				<Input
					width="250px"
					inputValue={"............"}
					disabled={true}
				/>
				<Button
					onClick={() => setShowModal(true)}
					fontSize={16}
					width="auto"
					text={"Change Password"}
				/>
			</div>
			{showModal && (
				<ChangePasswordModal
					onSubmit={function (
						password: string,
						confirmPassword: string
					): void {
						setRequestData({
							password: password,
							password_confirmation: confirmPassword,
						});
					}}
					onClose={function (): void {
						setShowModal(false);
					}}
				/>
			)}
		</StyledUserProfile>
	);
};

const StyledUserProfile = styled.div`
	display: flex;
	margin: 20px;
	flex-direction: column;
	.text-s {
		font-size: 12px;
		color: #959595;
	}
	.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
		color: #651bdc;
	}
	.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked
		+ .MuiSwitch-track {
		background-color: #651bdc;
	}
	.label-and-fixed-text {
		color: #959595;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		display: flex;
		width: auto;
	}

	.label-input-container {
		display: flex;
		flex: 1;
		align-items: start;
		gap: 100px;
		margin: 20px;
		align-items: center;
	}

	.alert-info-text {
		color: #959595;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;

const StyledFrame = styled.div`
	background-color: #ffffff;
	border: 1px solid;
	border-color: #cdcdcd;
	border-radius: 0px 20px 20px 20px;
	height: 503px;
	position: relative;
	width: 1260px;

	& .group {
		height: 39px;
		left: 520px;
		position: absolute;
		top: 339px;
		width: 187px;
	}

	& .div-wrapper {
		align-items: center;
		background-color: #651bdc;
		border-radius: 10px;
		box-shadow: 0px 0px 4px #00000040;
		display: inline-flex;
		gap: 10px;
		justify-content: center;
		padding: 10px 30px;
		position: relative;
	}

	& .text-wrapper {
		color: #ffffff;
		font-family: "Lato-Bold", Helvetica;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: normal;
		margin-top: -1px;
		position: relative;
		white-space: nowrap;
		width: fit-content;
	}

	& .div {
		height: 100px;
		left: 60px;
		position: absolute;
		top: 21px;
		width: 271px;
	}

	& .group-2 {
		background-image: url(./mask-group.png);
		background-size: 100% 100%;
		height: 100px;
		left: 171px;
		position: absolute;
		top: 0;
		width: 100px;
	}

	& .group-3 {
		height: 19px;
		left: 0;
		position: absolute;
		top: 40px;
		width: 108px;
	}

	& .text-wrapper-2 {
		color: #949494;
		font-family: "Lato-Medium", Helvetica;
		font-size: 16px;
		font-weight: 500;
		left: 0;
		letter-spacing: 0;
		line-height: normal;
		position: absolute;
		top: 0;
		white-space: nowrap;
	}

	& .img {
		height: 40px;
		left: 231px;
		position: absolute;
		top: 418px;
		width: 40px;
	}

	& .group-4 {
		height: 59px;
		left: 60px;
		position: absolute;
		top: 151px;
		width: 424px;
	}

	& .div-wrapper-2 {
		align-items: flex-start;
		border-bottom-style: solid;
		border-bottom-width: 1px;
		border-color: #d9d9d9;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		left: 171px;
		padding: 20px 10px;
		position: absolute;
		top: 0;
		width: 251px;
	}

	& .text-wrapper-3 {
		color: #333333;
		font-family: "Lato-Medium", Helvetica;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: normal;
		margin-top: -1px;
		position: relative;
		white-space: nowrap;
		width: fit-content;
	}

	& .text-wrapper-4 {
		color: #949494;
		font-family: "Lato-Medium", Helvetica;
		font-size: 16px;
		font-weight: 500;
		left: 0;
		letter-spacing: 0;
		line-height: normal;
		position: absolute;
		top: 20px;
		white-space: nowrap;
	}

	& .group-5 {
		height: 59px;
		left: 60px;
		position: absolute;
		top: 240px;
		width: 424px;
	}

	& .text-wrapper-5 {
		color: #949494;
		font-family: "Lato-Medium", Helvetica;
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: normal;
		margin-top: -1px;
		position: relative;
		white-space: nowrap;
		width: fit-content;
	}

	& .group-6 {
		height: 59px;
		left: 60px;
		position: absolute;
		top: 329px;
		width: 424px;
	}

	& .group-7 {
		height: 19px;
		left: 60px;
		position: absolute;
		top: 429px;
		width: 44px;
	}

	& .p {
		color: #949494;
		font-family: "Lato-Medium", Helvetica;
		font-size: 12px;
		font-weight: 500;
		left: 299px;
		letter-spacing: 0;
		line-height: normal;
		position: absolute;
		top: 430px;
		white-space: nowrap;
	}
`;

export const Frame = () => {
	return (
		<StyledFrame>
			<div className="group">
				<div className="div-wrapper">
					<div className="text-wrapper">Change Password</div>
				</div>
			</div>
			<div className="div">
				<div className="group-2" />
				<div className="group-3">
					<div className="text-wrapper-2">Display Picture</div>
				</div>
			</div>
			<img className="img" alt="Frame" src="frame.svg" />
			<div className="group-4">
				<div className="div-wrapper-2">
					<div className="text-wrapper-3">John Doe</div>
				</div>
				<div className="text-wrapper-4">Name</div>
			</div>
			<div className="group-5">
				<div className="div-wrapper-2">
					<div className="text-wrapper-5">john@email.com</div>
				</div>
				<div className="text-wrapper-4">Email</div>
			</div>
			<div className="group-6">
				<div className="div-wrapper-2">
					<div className="text-wrapper-5">*****************</div>
				</div>
				<div className="text-wrapper-4">Password</div>
			</div>
			<div className="group-7">
				<div className="text-wrapper-2">Alerts</div>
			</div>
			<p className="p">
				By turning this off, you will not receive any alerts if any of
				your monitor goes down
			</p>
		</StyledFrame>
	);
};
