import { Icon } from "../common/icons-props";

export const DownArrowIcon: Icon = (props) => {
	const { width, height, color } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M12.7969 5.39844L8.1875 10.0078L3.57812 5.39844"
				stroke="#333333"
				stroke-width="1.38281"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
