import { useCallback, useRef } from "react";
import { UploadFilesIcon } from "../../icons/UploadFIlesIcon";
import { COLORS } from "../../common/Theme";
import { BodyPrimary, BodyPrimaryLink, TitleSmall } from "../../common/Typography";
import useFileReader from "../../useFilerReader";
import { UrlsBox } from "./MultipleUrlInput";

export const UploadFileInput = (props: {
    urls: string[];
    onUploadSuccess: (urls: string[]) => void;
    onManualClick: () => void;
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length === 0) return;

        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const result = e.target?.result;
            if (result && typeof result === "string") {
                const parsedUrls = parseFile(result, file.type);
                props.onUploadSuccess(parsedUrls);
            }
        };
        reader.readAsText(file);
    }, [props.onUploadSuccess]);

    const parseFile = (content: string, fileType: string): string[] => {
        if (fileType === "application/json") {
            return parseJson(content);
        } else if (fileType === "text/csv") {
            return parseCsv(content);
        } else {
            // Fallback if the file type is not recognized
            return [];
        }
    };

    const parseJson = (content: string): string[] => {
        try {
            const data = JSON.parse(content);
            if (Array.isArray(data)) {
                return data.map((url) => url.toString().trim());
            } else {
                return [];
            }
        } catch (e) {
            console.error("Failed to parse JSON", e);
            return [];
        }
    };

    const parseCsv = (content: string): string[] => {
        return content.trim().split("\n").map((line) => line.trim());
    };

    const handleBrowseClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <TitleSmall color={"#959595"}>
                Please enter all the domains you want to monitor:
            </TitleSmall>
            {props.urls.length > 0 ? (
                <UrlsBox urls={props.urls} onUrlsChange={props.onUploadSuccess} />
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: `2px dashed ${COLORS.common.brand}`,
                        borderRadius: "4px",
                        padding: "20px",
                        gap: "10px",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <UploadFilesIcon width={40} height={40} />
                    </div>
                    <BodyPrimary color={"#1E1E1E"}>
                        Drag your files here or &nbsp;
                        <BodyPrimaryLink
                            color={COLORS.common.brand}
                            style={{ display: "inline-flex" }}
                            onClick={handleBrowseClick}
                        >
                            Browse
                        </BodyPrimaryLink>
                        <input
                            type="file"
                            accept=".csv, .json"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                    </BodyPrimary>
                    <TitleSmall color={COLORS.common.black}>
                         csv, json
                    </TitleSmall>
                </div>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
                <BodyPrimary color={"#1E1E1E"}>
                    You can add all the URLs &nbsp;
                    <BodyPrimaryLink
                        color={COLORS.common.brand}
                        style={{ display: "inline-flex" }}
                        onClick={() => {
                            props.onManualClick();
                        }}
                    >
                        manually
                    </BodyPrimaryLink>
                </BodyPrimary>
            </div>
        </div>
    );
};
