import { Icon } from "../common/icons-props";

export const OptionsIcon: Icon = (props) => {
	const { width, height, color } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M6.5 4C6.5 3.173 7.173 2.5 8 2.5C8.827 2.5 9.5 3.173 9.5 4C9.5 4.827 8.827 5.5 8 5.5C7.173 5.5 6.5 4.827 6.5 4ZM8 6.5C7.173 6.5 6.5 7.173 6.5 8C6.5 8.827 7.173 9.5 8 9.5C8.827 9.5 9.5 8.827 9.5 8C9.5 7.173 8.827 6.5 8 6.5ZM8 10.5C7.173 10.5 6.5 11.173 6.5 12C6.5 12.827 7.173 13.5 8 13.5C8.827 13.5 9.5 12.827 9.5 12C9.5 11.173 8.827 10.5 8 10.5Z"
				fill="black"
			/>
		</svg>
	);
};
