import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../common/store";
import { useApi } from "../../useApi";
import { useEffect } from "react";
import { CustomSliderWrapperProps } from "../AddMonitorScreen/CustomSliderWrapper";
import toast from "react-hot-toast";

type CreateMonitorApiPayload = {
	user_id: number;
	name: string;
	devices: string[];
	url: string;
	score_thresholds: {
		performance: number;
		accessibility: number;
		"best-practices": number;
		seo: number;
		pwa: number;
	};
	frequency: number;
	tags: string[];
	locations: string[];
	notifications: string[];
};

export type UpdateMonitorApiProps = {
	uuid: string;
	name: string;
	device: string;
	url: string;
	sliderValues: CustomSliderWrapperProps[];
	frequency: string;
	tag: string;
	onClose: () => void;
	fetchDashboardData: () => Promise<void>;
	toast?: any;
};

export const useUpdateMonitorApi = (props: UpdateMonitorApiProps) => {
	const userId = useAppStore((state) => state.userId);

	const navigate = useNavigate();

	const getFrequencyAsNumber = (freq: string) => {
		if (freq === "Weekly") {
			return 7;
		}

		if (freq === "Monthly") {
			return 30;
		}

		return 0;
	};

	const getScoreThresholdsFromSliderValues = (
		sliderValues: CustomSliderWrapperProps[]
	) => {
		const score_thresholds: CreateMonitorApiPayload["score_thresholds"] = {
			performance: 0,
			accessibility: 0,
			"best-practices": 0,
			seo: 0,
			pwa: 0,
		};

		sliderValues.forEach((sv) => {
			//@ts-ignore
			score_thresholds[sv.id] = sv.value;
		});

		return score_thresholds;
	};

	const payload: CreateMonitorApiPayload = {
		user_id: userId,
		name: props.name,
		devices: [props.device],
		url: props.url,
		score_thresholds: getScoreThresholdsFromSliderValues(
			props.sliderValues
		),
		frequency: getFrequencyAsNumber(props.frequency),
		locations: ["India"],
		notifications: ["email"],
		tags: [props.tag],
	};

	console.log("payload", payload);

	const { data, error, loading, fetchData } = useApi<any>({
		autoFetch: false,
		method: "patch",
		url: `/monitors/${props.uuid}`,
		requestData: payload,
	});

	useEffect(() => {
		if (data) {
			toast.success("Successfully updated");
			props.fetchDashboardData();
			props.onClose();
		}

		if (error) {
			toast.error(error);
			props.onClose();
		}
	}, [data, error]);

	return { data, error, loading, fetchData };
};
