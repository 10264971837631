import React, { useState } from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "../../common/Theme";
import { useNavigate } from "react-router-dom";
import { validateUrl } from "../../common/utils";

type UrlInputProps = {
	type?: string;
	inputValue?: string;
	onInputChange?: (value: string) => void;
	placeHolder?: string;
	width?: string;
	borderRadius?: string;
	onSubmit: (url: string) => void;
};

export const UrlInput: React.FC<UrlInputProps> = ({
	type = "text",
	inputValue = "",
	onInputChange,
	placeHolder = "https://www.websitelink.com",
	width = "786px",
	borderRadius = "6px",
	
	onSubmit,
}) => {
	const [input, setInput] = useState(inputValue);
	const navigate = useNavigate();

	const [error, setError] = useState(false);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		setInput(newValue);
		onInputChange?.(newValue);
	};

	function containsDot(): boolean {
		const dotIndex = input.indexOf(".");
		return dotIndex !== -1;
	}

	// const onSubmit = () => {
	// 	navigate(`/instant-report/mobile?url=${input}`);
	// };

	return (
		<StyledInputWrapper inputDot={containsDot()}>
			<InputContainer>
				<Title>Enter URL</Title>
				<StyledUrlInput
					width={width}
					type={type}
					placeholder={placeHolder}
					borderRadius={borderRadius}
					value={input}
					onChange={handleChange}
					error={error}
				/>
			</InputContainer>
			<ButtonContainer>
				<StyledButton
					onClick={() => {
						if (!validateUrl(input)) {
							setError(true);
							return;
						}
						onSubmit(input);
					}} >
					Run Tests 
					<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" style={{paddingLeft:"10px"}} className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-narrow-right">
						<path stroke="none" d="M0 0h24v24H0z" fill="none" />
						<path d="M5 12l14 0" />
						<path d="M15 16l4 -4" />
						<path d="M15 8l4 4" />
					</svg>
				</StyledButton>
			</ButtonContainer>
		</StyledInputWrapper>
	);
};

const StyledUrlInput = styled.input<{
	width: string;
	borderRadius: string;
	error: boolean;
}>`
	display: flex;
	width: ${(props) => props.width};
	padding: 10px;
	align-items: center;
	border: ${(props) => (props.error ? "1px solid red" : "none")};
	border-radius: ${(props) => props.borderRadius};
	font-family: Lato;
	&:focus-visible {
		outline: unset;
	}
`;

const StyledInputWrapper = styled.div<{ inputDot: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 20px;
	border-radius: ${(props) =>
		props.inputDot ? "20px 20px 0px 0px" : "20px 20px 20px 20px"};
	background: #fff;
	height: 70px;
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonContainer = styled.div`
	padding: 10px;
`;

const Title = styled.div`
	color: #000;
	font-family: Lato;
	font-size: 16px;
	font-weight: 600;
	margin: 10px;
`;

const StyledButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${COLORS.common.brand};
	border-radius: 10px;
	border: 1px solid white;
	padding: 13px 30px;
	width: auto;
	color: ${COLORS.common.white};
	font-family: "Lato-Bold", Helvetica;
	font-size: ${FONTS.titleRegular.fontSize}px;
	font-weight: ${FONTS.titleRegular.fontWeight};
	&:hover {
		cursor: pointer;
		background-color: #8b49ff;
	}
`;
