import React from "react";
import styled from "styled-components";
import { CustomSlider } from "../../components/CustomSlider";
import { Button } from "../../components/Button";
import { DiamondIcon } from "../../icons/DiamondIcon";
import { useNavigate } from "react-router-dom";

export const SignUpButtonComponent = () => {
	const navigate = useNavigate();

	return (
		<StyledSignUpButtonComponent>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
					width: "100%",
				}}
			>
				{[
					"Performance",
					"Accessibility",
					"Best Performance",
					"SEO",
					"PWA",
				].map((_) => {
					return (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "flex-start",
								gap: "40px",
							}}
						>
							<div
								className="l-text"
								style={{ display: "flex", padding: "10px" }}
							>
								{_}
							</div>
							<div
								style={{
									display: "flex",
									width: "298px",
									padding: "10px",
								}}
							>
								<CustomSlider
									disabled={true}
									displayValue={70}
									onChange={function (value: number): void {}}
								/>
							</div>
						</div>
					);
				})}
				<SignUpOverLay>
					<div>
						<DiamondIcon />
					</div>
					<div className="signup-info-text">
						Signup to Configure Alerts and Get Notified{" "}
					</div>
					<div>
						<Button
							text={"Sign up"}
							width="auto"
							onClick={() => {
								navigate("/signup");
							}}
						/>
					</div>
				</SignUpOverLay>
			</div>
		</StyledSignUpButtonComponent>
	);
};

const StyledSignUpButtonComponent = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 100%;
	flex: 2;

	.l-text {
		color: #000;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;

const SignUpOverLay = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 1000; // Ensure it's above other content (use a higher number if necessary)
	border-radius: 20px;
	background: linear-gradient(
		354deg,
		#fff 5.46%,
		rgba(255, 255, 255, 0.72) 94.47%
	);
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	gap: 20px;

	.signup-info-text {
		color: #000;
		text-align: center;
		font-family: Lato;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 140%; /* 28px */
	}
`;
