import { useState, useEffect } from "react";
import { ApiOptions, api } from "./api";

type UseApiResult<T> = {
	data: T | null;
	loading: boolean;
	error: string | null;
	fetchData: () => Promise<void>;
};

type UseApiOptions<T> = {
	autoFetch?: boolean;
	method?: keyof typeof api;
	url: string;
	requestData?: any;
	options?: ApiOptions;
};

export function useApi<T>({
	autoFetch = true,
	method = "get",
	url,
	requestData,
	options,
}: UseApiOptions<T>): UseApiResult<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await api[method]<T>(url, requestData, options);
			console.log("response", response);
			if (response.success) {
				setData(response.data || null);
				setError(null);
			} else {
				setData(null);
				//@ts-ignore
				setError(response.data?.error || "An error occurred");
			}
		} catch (error) {
			setData(null);
			setError("An error occurred while fetching data");
		} finally {
			setLoading(false);
		}
	};

	// If autoFetch is true, initiate the API call on component mount
	useEffect(() => {
		if (autoFetch) {
			fetchData();
		}
	}, [autoFetch]);

	return {
		data,
		loading,
		error,
		fetchData,
	};
}
