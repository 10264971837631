import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../common/Theme";
import { LogoIcon } from "../../icons/LogoIcon";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import { UploadFileInput } from "./UploadFileInput";
import { MultipleUrlInput } from "./MultipleUrlInput";
import {
	CustomSliderWrapper,
	CustomSliderWrapperProps,
} from "./CustomSliderWrapper"; // Assuming this is a separate file
import { TitleLarge, TitleSmall } from "../../common/Typography";
import { useCreateMonitorApi } from "./useCreateMonitorApi";
import InputWithDropdown from "../DashboardScreen/InputWithDropdown";
import { useApi } from "../../useApi";
import toast, { Toaster } from "react-hot-toast";
import { validateUrl } from "../../common/utils";
import { AddMonitor } from "./AddMonitor";
import { AddAnyMonitor } from "../DashboardScreen/AddAnyMonitor";

export const deviceOptions: DropDownOption[] = [
	{ id: "desktop", value: "Desktop", selected: true },
	{ id: "mobile", value: "Mobile", selected: false },
];

export const locationOptions: DropDownOption[] = [
	{ id: "india", value: "India", selected: true },
];

export const frequencyOptions: DropDownOption[] = [
	{ id: "weekly", value: "Weekly", selected: true },
	{ id: "monthly", value: "Monthly", selected: false },
];

const tagOptions: DropDownOption[] = [
	{ id: "tag1", value: "Tag 1", selected: false },
	{ id: "tag2", value: "Tag 2", selected: false },
];

export const sliderOptions: CustomSliderWrapperProps[] = [
	{
		id: "performance",
		label: "Performance",
		value: 90,
	},
	{
		id: "accessibility",
		label: "Accessibility",
		value: 90,
	},
	{
		id: "best-practices",
		label: "Best practices",
		value: 90,
	},
	{
		id: "seo",
		label: "SEO",
		value: 90,
	},
	{
		id: "pwa",
		label: "PWA",
		value: 90,
	},
];

export const SingleMonitor = () => {
	const { monitorType } = useParams();

	return (
		<MonitorContainer>
			<Toaster />
			<LogoSection>
				<LogoIcon width={220} height={40} color={COLORS.common.brand} />
			</LogoSection>
			<AddAnyMonitor monitorType={monitorType as any} />
			<div></div>
		</MonitorContainer>
	);
};

const MonitorContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-image: url(${require("../../bgmonitor.png")});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: scroll;
`;

const LogoSection = styled.div`
	margin: 50px 0 0;
`;
