import { addMinutes, format, parse } from "date-fns";

export function getRunningTime(date1: Date, date2: Date) {
	const msPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds per day
	const hours = 60 * 60 * 1000;
	let differenceInMs = Math.abs(date2.getTime() - date1.getTime()); // Absolute difference in milliseconds
	const roundedDays = Math.round(differenceInMs / msPerDay); // Round to nearest whole number
	if (roundedDays) {
		return roundedDays === 1
			? `For ${roundedDays} day`
			: `For ${roundedDays} days`;
	}

	const roundedHours = Math.round(differenceInMs / hours);
	if (roundedHours) {
		return roundedHours === 1
			? `For ${roundedHours} hour`
			: `For ${roundedHours} hours`;
	}

	return "less than an hour";
}

export function formatDateString(dateString: string): string {
	const offsetInMinutes = 0;

	if (!dateString)
		return format(addMinutes(new Date(), offsetInMinutes), "d MMM h:mm a");
	// Parse the date string assuming it's in UTC (since there's no timezone info)
	const date = parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());

	// Calculate the total offset in minutes (5 hours 30 minutes for +5:30)

	// Apply the offset to get the date and time in the desired timezone
	const dateWithOffset = addMinutes(date, offsetInMinutes);

	// Format the date into the desired format
	return format(dateWithOffset, "d MMM h:mm a");
}

export function formatDateObject(dateObj: Date): string {
	const offsetInMinutes = 0;

	if (!dateObj)
		return format(addMinutes(new Date(), offsetInMinutes), "d MMM h:mm a");
	// Parse the date string assuming it's in UTC (since there's no timezone info)

	// Calculate the total offset in minutes (5 hours 30 minutes for +5:30)

	// Apply the offset to get the date and time in the desired timezone
	const dateWithOffset = addMinutes(dateObj, offsetInMinutes);

	// Format the date into the desired format
	return format(dateWithOffset, "d MMM, yyyy h:mm a");
}

const urlPattern =
	/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const validateUrl = (url: string) => {
	return urlPattern.test(url);
};
