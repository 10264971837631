import styled from "styled-components";
import { TitleLarge, TitleRegular, TitleSmall } from "../../common/Typography";
import { COLORS } from "../../common/Theme";
import { Button } from "../../components/Button";
import { LogoIcon } from "../../icons/LogoIcon";
import { UrlInput } from "./UrlInput";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/Input";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import { ConfigureComponent } from "./ConfigureComponent";
import { SignUpButtonComponent } from "./SignUpButtonComponent";
import { useRef, useState, useEffect } from "react";

type UnAuthenticatedHomeProps = {};

export const UnAuthenticatedHome: React.FC<UnAuthenticatedHomeProps> = () => {
	const navigate = useNavigate();

	const [isUrlInputFocused, setIsUrlInputFocused] = useState(false);

	const [selectedDevice, setSelectedDevice] = useState<DropDownOption>({
		id: "desktop",
		value: "Desktop",
		selected: true,
	});

	const [instantUrl, setInstantUrl] = useState("");

	function containsDot(): boolean {
		const dotIndex = instantUrl.indexOf(".");
		return dotIndex !== -1;
	}
	useEffect(() => {
		navigate('/login', { replace: true });
	  }, [navigate]);
	
	  return null; // or you can return a loader or a message if needed
	return (
		<StyledUnAuthenticated>
			<Header>
				<LogoWrapper>
					<LogoIcon width={220} height={40} />
				</LogoWrapper>
				{/* <Menu>
					<TitleRegular color={COLORS.common.white}>
						Features
					</TitleRegular>
					<TitleRegular color={COLORS.common.white}>
						Pricing
					</TitleRegular>
				</Menu> */}
				<LoginButtonWrapper>
					<Button
						text={"Sign up"}
						buttonType="inverse"
						size="small"
						onClick={() => {
							navigate("/signup");
						}}
					/>
					<Button
						text={"Login"}
						buttonType="inverse"
						size="small"
						onClick={() => {
							navigate("/login");
						}}
					/>
				</LoginButtonWrapper>
			</Header>
			<MainContent>
				<TitleLargeWrapper>
					<TitleLarge color={COLORS.common.white}>
						Your One-Stop Analytic Platform to Boost your Page Speed
					</TitleLarge>
				</TitleLargeWrapper>
				<TitleSmall color={COLORS.common.white} style={{fontSize:'24px'}}>
				Just type in the web address of the page you’re looking to keep an eye on.
				</TitleSmall>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						borderRadius: "20px",
					}}
				>
					<UrlInput
						onInputChange={setInstantUrl}
						onSubmit={(url) => {
							navigate(
								`/instant-report/${selectedDevice.id}?url=${url}`
							);
						}}
					/>

					{containsDot() ? (
						<div className="bottom-stuff">
							<ConfigureComponent
								device={selectedDevice}
								setDevice={setSelectedDevice}
							/>
							<SignUpButtonComponent />
						</div>
					) : (
						<></>
					)}
				</div>
			</MainContent>
		</StyledUnAuthenticated>
	);
};

export default UnAuthenticatedHome;

const StyledUnAuthenticated = styled.div`
	display: flex;
	flex: 1;
	background: linear-gradient(
			208deg,
			#651bdc 0%,
			#9a2c80 33.35%,
			#f66060 68.23%,
			#ffb19b 101.08%
		),
		#651bdc;
	flex-direction: column;
	justify-content: space-between;

	.bottom-stuff {
		border-radius: 0px 0px 20px 20px;
		background: #fff;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
		display: flex;
	}
`;

const Header = styled.div`
	display: flex;
	flex: 0.1;
	justify-content: space-between;
	margin: 20px 20px;
	align-items: center;
`;

const LogoWrapper = styled.div`
	display: flex;
`;

const Menu = styled.div`
	display: flex;
	flex-direction: row;
	gap: 40px;
	margin: 10px;
`;

const LoginButtonWrapper = styled.div`
	display: flex;
	margin: 20px;
	gap: 20px;
`;

const MainContent = styled.div`
	display: flex;
	padding-top: 40px;
	flex: 1;
	align-items: center;
	flex-direction: column;
	gap: 40px;
`;

const TitleLargeWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 900px;
	text-align: center;
`;
