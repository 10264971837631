import { Icon } from "../common/icons-props";

export const MonitorDashboardIcon: Icon = (props) => {
	const { width, height, color } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M0.00043416 -1.77832C-0.976899 -1.77832 -1.77734 -0.977876 -1.77734 -0.000542402V9.77724H17.7782V-0.000542402C17.7782 -0.977876 16.9778 -1.77832 16.0004 -1.77832H0.00043416ZM-1.77734 10.6661V12.4439C-1.77734 13.4212 -0.976899 14.2217 0.00043416 14.2217H5.63243L4.86399 16.8883H2.66799C2.55012 16.8883 2.43707 16.9352 2.35372 17.0185C2.27037 17.1019 2.22355 17.2149 2.22355 17.3328C2.22355 17.4507 2.27037 17.5637 2.35372 17.6471C2.43707 17.7304 2.55012 17.7772 2.66799 17.7772H13.3329C13.4508 17.7772 13.5638 17.7304 13.6472 17.6471C13.7305 17.5637 13.7773 17.4507 13.7773 17.3328C13.7773 17.2149 13.7305 17.1019 13.6472 17.0185C13.5638 16.9352 13.4508 16.8883 13.3329 16.8883H11.1369L10.3684 14.2217H16.0004C16.9778 14.2217 17.7782 13.4212 17.7782 12.4439V10.6661H-1.77734ZM6.6671 11.9995H9.33377C9.45164 11.9995 9.56469 12.0463 9.64804 12.1296C9.73139 12.213 9.77821 12.326 9.77821 12.4439C9.77821 12.5618 9.73139 12.6748 9.64804 12.7582C9.56469 12.8415 9.45164 12.8883 9.33377 12.8883H6.6671C6.54923 12.8883 6.43618 12.8415 6.35283 12.7582C6.26948 12.6748 6.22266 12.5618 6.22266 12.4439C6.22266 12.326 6.26948 12.213 6.35283 12.1296C6.43618 12.0463 6.54923 11.9995 6.6671 11.9995Z"
				fill="#651BDC"
			/>
		</svg>
	);
};
