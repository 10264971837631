// Modal.tsx
import React from "react";
import styled from "styled-components";

// Define props interface
interface ModalProps {
	width: string;
	children: React.ReactNode;
	onClose: () => void; // Function to close the modal
}

// Styled components
const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
`;

const ModalContainer = styled.div<{ width: string }>`
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	width: ${(props) => props.width};
	z-index: 1001;
`;

const ModalFooter = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 20px;
`;

const Button = styled.button<{ variant: "success" | "cancel" }>`
	padding: 10px 20px;
	margin-left: 10px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	background-color: ${(props) =>
		props.variant === "success" ? "#0FC000" : "#959595"};
	color: white;
`;

// Modal component
export const Modal: React.FC<ModalProps> = ({ width, children, onClose }) => {
	return (
		<ModalOverlay onClick={onClose}>
			<ModalContainer onClick={(e) => e.stopPropagation()} width={width}>
				{children}

				{/* <ModalFooter>
					<Button variant="cancel" onClick={onClose}>
						Cancel
					</Button>
					<Button variant="success">Success</Button>
				</ModalFooter> */}
			</ModalContainer>
		</ModalOverlay>
	);
};
