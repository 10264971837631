import styled from "styled-components";
import { EditIcon } from "../../../icons/EditIcon";
import { EllipseIcon } from "../../../icons/EllipseIcon";
import { RunIcon } from "../../../icons/RunIcon";
import { IndiaIcon } from "../../../icons/IndiaIcon";
import { IconDeviceImac, IconDeviceMobile } from "@tabler/icons-react";
import { IconWorld } from "@tabler/icons-react";
import { IconRefresh } from "@tabler/icons-react";
import { IconActivity } from "@tabler/icons-react";
import { formatDateString, getRunningTime } from "../../../common/utils";
import { SingleUrlDetailsShimmer } from "./SingleUrlShimmer";

export const SingleUrlDetails = (props: {
	name: string;
	url: string;
	device: string;
	lastChecked: string;
	frequency: number;
	tag: string;
	activeStatus: string;
	status: string;
	statusColor: string;
	imageUrl: string;
	onEdit?: () => void;
	onRun?: () => void;
	onRefresh?: () => void;
	loading?: boolean;
}) => {
	const runningTime = getRunningTime(new Date(props.lastChecked), new Date());

	if (props.loading) {
		return <SingleUrlDetailsShimmer />;
	}

	return (
		<StyledSingleUrlDetailScreen>
			<div
				style={{
					display: "flex",
					justifyItems: "center",
					justifyContent: "space-between",
					alignItems: "center",
					paddingLeft: "10px",
					paddingRight: "20px",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					{!!props.name ? (
						<div className="single-title-text capitalize">
							{props.name} {props.device}
						</div>
					) : (
						<div className="single-title-text">
							{props.url} {props.device}
						</div>
					)}
					{props.activeStatus && (
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								gap: "5px",
							}}
						>
							<div className="ellipse-icon">
								<EllipseIcon
									width={8}
									height={8}
									color="#651BDC"
								/>
							</div>
							<div className="single-active-text">
								{props.activeStatus}
							</div>
						</div>
					)}
					{props.lastChecked && (
						<div style={{ display: "flex", gap: "10px" }}>
							<div
								className="single-poor-container"
								style={{ background: props.statusColor }}
							>
								<div className="single-poor-text">
									{props.status}
								</div>
							</div>
							<div className="single-last-text">
								{runningTime}
							</div>
						</div>
					)}
				</div>
				<div style={{ display: "flex", gap: "20px" }}>
					{props.onRefresh && (
						<div
							style={{
								display: "flex",
								gap: "10px",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={props.onRefresh}
						>
							<IconRefresh
								stroke={2}
								style={{ color: "#333333", width: "20px" }}
							/>
							<div className="single-edit-text">Refresh</div>
						</div>
					)}
					{props.onEdit && (
						<div
							style={{
								display: "flex",
								gap: "10px",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={props.onEdit}
						>
							<div>
								<EditIcon />
							</div>
							<div className="single-edit-text">Edit</div>
						</div>
					)}
					{props.onRun && (
						<div
							style={{
								display: "flex",
								gap: "10px",
								alignItems: "center",
								cursor: "pointer",
							}}
							onClick={props.onRun}
						>
							<div>
								<RunIcon />
							</div>
							<div className="single-run-text">Run Now</div>
						</div>
					)}
				</div>
			</div>
			<div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
				<img
					src={props.imageUrl || require("../../../ind_url.png")}
					alt=""
					style={{
						maxHeight: "calc(var(--gauge-circle-size-big)* 2.8)",
						maxWidth: "calc(var(--gauge-circle-size-big)* 3.5)",
						border: "1px solid #ece",
						padding: "4px",
						borderRadius: "3px",
						display: "block",
					}}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
						padding: "20px",
					}}
				>
					<div
						style={{
							fontSize: "20px",
							display: "flex",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<IconWorld stroke={2} />
						{props.url}
					</div>
					<div
						style={{
							display: "flex",
							gap: "50px",
						}}
					>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "10px",
							}}
						>
							<IndiaIcon style={{ width: "20px" }} />
							India
						</div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "10px",
								textTransform: "capitalize",
							}}
						>
							{props.device.toLowerCase() === "desktop" ? (
								<IconDeviceImac
									stroke={2}
									style={{ color: "#333333", width: "20px" }}
								/>
							) : (
								<IconDeviceMobile
									stroke={2}
									style={{ color: "#333333", width: "20px" }}
								/>
							)}
							{props.device}
						</div>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<IconRefresh
							stroke={2}
							style={{ color: "#333333", width: "20px" }}
						/>
						Last checked at {formatDateString(props.lastChecked)}
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<IconActivity
							stroke={2}
							style={{ color: "#333333", width: "20px" }}
						/>{" "}
						Checks {props.frequency === 7 ? "weekly" : "monthly"}{" "}
						once
					</div>
					{props.tag && (
						<div>
							Tag:{" "}
							<span
								style={{
									padding: "5px 30px",
									borderRadius: "40px",
									border: "1px solid #CDCDCD",
								}}
							>
								{props.tag}
							</span>
						</div>
					)}
				</div>
			</div>
			{/* <div>No data found</div> */}
		</StyledSingleUrlDetailScreen>
	);
};

export const StyledSingleUrlDetailScreen = styled.div`
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
	padding: 20px;
	gap: 20px;
	display: flex;
	flex-direction: column;
	margin: 20px;
	width: 72%;
	flex-wrap: wrap;

	.single-title-text {
		color: #000;
		font-family: Lato;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.single-active-text {
		color: #959595;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: capitalize;
	}

	.singe-last-text {
		color: #959595;
		font-family: Lato;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.ellipse-icon {
		display: flex;
		border-radius: 4px;
		justify-content: center;
		align-self: center;
	}

	.single-edit-text {
		color: #333;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.single-run-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.single-poor-container {
		border-radius: 20px;
		background: #f33;
		display: flex;
		padding: 0px 20px;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.single-poor-text {
		color: #fff;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.capitalize {
		text-transform: capitalize;
	}
`;
