import { COLORS } from "../../common/Theme";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { UserProfile } from "./UserProfile";
import { NotificationComponent } from "./NotificationComponent";
import SubscribeAlert from "../DashboardScreen/SubscribeAlert";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import { useNavigate } from "react-router-dom";
import { BillingAndPlan } from "./BillingAndPlan";

export const SettingsScreen = () => {
	const navigate = useNavigate();

	return (
		<StyledSettingsScreen>
			<div className="dashboard-text">Settings</div>
			<Tabs>
				<TabContent label="User Profile">
					<UserProfile />
				</TabContent>
				<TabContent label="Billing & Plan">
					<BillingAndPlan />
				</TabContent>
				<TabContent label="Notifications">
					<NotificationComponent />
				</TabContent>
			</Tabs>
		</StyledSettingsScreen>
	);
};

export const StyledSettingsScreen = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	background: "#F9F9F9";
	padding-top: 20px;
	margin: 30px 50px;

	.settings-text {
		color: #000;
		font-family: Lato;
		font-size: 31px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	.dashboard-text {
		margin-top: 20px;
		margin-left: 20px;
		color: #651bdc;
		font-family: Lato;
		font-size: 38px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
`;

export const TabList = styled.div`
	display: flex;
	margin: 20px;
	margin-bottom: 0px;
`;

// Styled component for individual tabs
export const TabButton = styled.button<{ isActive: boolean }>`
	padding: 20px;
	cursor: pointer;
	border-bottom: ${({ isActive }) =>
		isActive ? "1px solid white" : "1px solid #ddd"};
	background: ${({ isActive }) => (isActive ? "#fff" : "transparent")};
	margin-bottom: -1px;
	z-index: ${({ isActive }) => (isActive ? 1 : 0)};
	border-radius: 20px 20px 0px 0px;
	border: ${(props) => (props.isActive ? "1px solid #cdcdcd" : "none")};
	border-bottom: ${(props) =>
		!props.isActive ? "1px solid #cdcdcd" : "none"};
	color: ${(props) => (props.isActive ? "#651bdc" : "#333")};
	text-align: center;
	font-family: Lato;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;
// Styled component for the content area of each tab
export const TabContentArea = styled.div<{ isActive: boolean }>`
	display: ${({ isActive }) => (isActive ? "flex" : "none")};
	border-radius: 0px 20px 20px 20px;
	border: 1px solid #cdcdcd;
	background: #fff;
	padding: 20px;
	margin: 20px;
	margin-top: 0px;
	border-top: 1px solid #ddd;
`;

// Types for the TabContent component props
export type TabContentProps = {
	label: string;
	isActive?: boolean;
	children: React.ReactNode;
};

// TabContent component
export const TabContent: React.FC<TabContentProps> = ({
	label,
	isActive,
	children,
}) => {
	return (
		<TabContentArea isActive={isActive || false}>{children}</TabContentArea>
	);
};

export type TabsProps = {
	children: React.ReactElement<TabContentProps>[];
};

// Tabs container component
export const Tabs: React.FC<TabsProps> = ({ children }) => {
	const [activeTab, setActiveTab] = useState(0);

	const handleClick = (index: number) => {
		setActiveTab(index);
	};

	return (
		<>
			<TabList>
				{children.map((child, index) => (
					<TabButton
						key={index}
						isActive={index === activeTab}
						onClick={() => handleClick(index)}
					>
						{child.props.label}
					</TabButton>
				))}
			</TabList>
			{children.map((child, index) =>
				React.cloneElement(child, { isActive: index === activeTab })
			)}
		</>
	);
};
