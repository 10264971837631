import React from "react";
import styled from "styled-components";

export const TempResourceScreen = () => {
	return <StyledTempResourceScreen>Coming Soon</StyledTempResourceScreen>;
};

const StyledTempResourceScreen = styled.h1`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;
