import { Icon } from "../common/icons-props";

export const InfoIcon: Icon = (props) => {
	const { width, height, color } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16px"
			height="16px"
			viewBox="0 0 10 10"
			fill="none"
		>
			<path
				d="M4.5 7.5H5.5V4.5H4.5V7.5ZM5 3.5C5.14167 3.5 5.2605 3.452 5.3565 3.356C5.4525 3.26 5.50033 3.14133 5.5 3C5.5 2.85833 5.452 2.73967 5.356 2.644C5.26 2.54833 5.14133 2.50033 5 2.5C4.85833 2.5 4.73967 2.548 4.644 2.644C4.54833 2.74 4.50033 2.85867 4.5 3C4.5 3.14167 4.548 3.2605 4.644 3.3565C4.74 3.4525 4.85867 3.50033 5 3.5ZM5 10C4.30833 10 3.65833 9.86867 3.05 9.606C2.44167 9.34333 1.9125 8.98717 1.4625 8.5375C1.0125 8.0875 0.656333 7.55833 0.394 6.95C0.131667 6.34167 0.000333333 5.69167 0 5C0 4.30833 0.131333 3.65833 0.394 3.05C0.656667 2.44167 1.01283 1.9125 1.4625 1.4625C1.9125 1.0125 2.44167 0.656333 3.05 0.394C3.65833 0.131667 4.30833 0.000333333 5 0C5.69167 0 6.34167 0.131333 6.95 0.394C7.55833 0.656667 8.0875 1.01283 8.5375 1.4625C8.9875 1.9125 9.34383 2.44167 9.6065 3.05C9.86917 3.65833 10.0003 4.30833 10 5C10 5.69167 9.86867 6.34167 9.606 6.95C9.34333 7.55833 8.98717 8.0875 8.5375 8.5375C8.0875 8.9875 7.55833 9.34383 6.95 9.6065C6.34167 9.86917 5.69167 10.0003 5 10ZM5 9C6.11667 9 7.0625 8.6125 7.8375 7.8375C8.6125 7.0625 9 6.11667 9 5C9 3.88333 8.6125 2.9375 7.8375 2.1625C7.0625 1.3875 6.11667 1 5 1C3.88333 1 2.9375 1.3875 2.1625 2.1625C1.3875 2.9375 1 3.88333 1 5C1 6.11667 1.3875 7.0625 2.1625 7.8375C2.9375 8.6125 3.88333 9 5 9Z"
				fill="#959595"
			/>
		</svg>
	);
};
