import React, { useEffect, useState, KeyboardEvent } from "react";
import styled from "styled-components";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import { EyeIconHidden } from "../icons/EyeIconHidden";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    type?: string;
    inputValue?: string;
    onInputChange?: (input: string) => void;
    placeHolder?: string;
    width?: string;
    borderRadius?: string;
    fontSize?: string;
    onEnter?: (input: string) => void;
    multiline?: boolean;
    rows?: number;
};

const InputContainer = styled.div<{ width: string }>`
    display: flex;
    align-items: center;
    width: ${(props) => props.width ?? "100%"};
`;

const StyledInput = styled.input<{ width: string; borderRadius: string; fontSize: string }>`
    flex: 1;
    padding: 20px 10px;
    border: none;
    border-radius: ${(props) => props.borderRadius};
    border-bottom: 1px solid #d9d9d9;
    font-family: Lato;
    font-size: ${(props) => props.fontSize};
    width: ${(props) => props.width};
    &:focus-visible {
        outline: unset;
    }

    &:disabled {
        background: none;
    }
`;

const StyledTextarea = styled.textarea<{ width: string; borderRadius: string; fontSize: string }>`
    flex: 1;
    padding: 20px 10px;
    border: none;
    border-radius: ${(props) => props.borderRadius};
    border-bottom: 1px solid #d9d9d9;
    font-family: Lato;
    font-size: ${(props) => props.fontSize};
    width: ${(props) => props.width};
    &:focus-visible {
        outline: unset;
    }

    &:disabled {
        background: none;
    }
`;

const IconWrapper = styled.div`
    margin-left: -30px; // Adjust based on the size of your icon and desired spacing
    cursor: pointer;
`;

export const Input: React.FC<InputProps> = ({
    type = "text",
    inputValue = "",
    onInputChange,
    placeHolder = "",
    width = "100%",
    borderRadius = "6px",
    fontSize = "12px",
    onEnter,
    multiline = false,
    rows = 3,
    ...rest
}) => {
    const [input, setInput] = useState(inputValue);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setInput(inputValue);
    }, [inputValue]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(e.target.value);
        onInputChange?.(e.target.value);
    };

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === "Enter" && onEnter && !multiline) {
            onEnter(input);
            setInput("");
        }
    };

    const handleBlur = () => {
        if (multiline && onEnter) {
            onEnter(input);
            setInput("");
        }
    };

    const toggleVisibility = () => {
        setVisible(!visible);
    };

    return (
        <InputContainer width={width}>
            {multiline ? (
                <StyledTextarea
                    placeholder={placeHolder}
                    width={width}
                    borderRadius={borderRadius}
                    fontSize={fontSize}
                    value={input}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    onBlur={handleBlur}
                    rows={rows}
                    {...rest}
                />
            ) : (
                <StyledInput
                    type={type === "password" && !visible ? "password" : "text"}
                    placeholder={placeHolder}
                    width={width}
                    borderRadius={borderRadius}
                    fontSize={fontSize}
                    value={input}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    {...rest}
                />
            )}
            {type === "password" && (
                <IconWrapper onClick={toggleVisibility}>
                    {visible ? <IconEyeOff style={{ paddingRight: "5px" }} /> : <IconEye style={{ paddingRight: "5px" }} />}
                </IconWrapper>
            )}
        </InputContainer>
    );
};
