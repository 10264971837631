import { Icon } from "../common/icons-props";

export const MinusIcon: Icon = (props) => {
	const { width, height, color } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || "16"}
			height={height || "16"}
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M9.24414 7.65625H5.55664M12.9316 7.65625C12.9316 8.38262 12.7886 9.10189 12.5106 9.77297C12.2326 10.444 11.8252 11.0538 11.3116 11.5674C10.798 12.0811 10.1882 12.4885 9.51711 12.7665C8.84603 13.0444 8.12676 13.1875 7.40039 13.1875C6.67402 13.1875 5.95475 13.0444 5.28367 12.7665C4.61259 12.4885 4.00283 12.0811 3.48921 11.5674C2.97558 11.0538 2.56815 10.444 2.29018 9.77297C2.01221 9.10189 1.86914 8.38262 1.86914 7.65625C1.86914 6.18927 2.4519 4.78238 3.48921 3.74507C4.52652 2.70776 5.93341 2.125 7.40039 2.125C8.86737 2.125 10.2743 2.70776 11.3116 3.74507C12.3489 4.78238 12.9316 6.18927 12.9316 7.65625Z"
				stroke="#FF3333"
				stroke-width="1.38281"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
