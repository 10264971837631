import React, { useEffect, useState } from "react";
import { Modal } from "../../components/Modal";
import { TitleMedium } from "../../common/Typography";
import { Input } from "../../components/Input";
import { COLORS } from "../../common/Theme";
import { Button } from "../../components/Button";

export const ChangePasswordModal = (props: {
	onSubmit: (password: string, confirmPassword: string) => void;
	onClose: () => void;
}) => {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	return (
		<Modal width={"500px"} onClose={props.onClose}>
			<div
				style={{
					background: "white",
					padding: "40px",
					borderRadius: "10px",
					display: "flex",
					flexDirection: "column",
					gap: "10px",
				}}
			>
				<TitleMedium color={COLORS.common.black}>
					Change Password
				</TitleMedium>
				<Input
					inputValue={password}
					onInputChange={setPassword}
					placeHolder="Enter new Password"
					width="auto"
					style={{ margin: "20px 0px" }}
					type="password"
				/>
				<Input
					inputValue={confirmPassword}
					onInputChange={setConfirmPassword}
					placeHolder="Re enter password"
					width="auto"
					style={{ margin: "20px 0px" }}
					type="password"
				/>
				<div
					style={{
						display: "flex",
						gap: "10px",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<Button
						buttonType="inverse"
						text="Cancel"
						width="auto"
						fontSize={14}
						onClick={() => {
							props.onClose();
						}}
					/>
					<Button
						buttonType="primary"
						text={"Update Password"}
						width="auto"
						fontSize={14}
						onClick={() => {
							props.onSubmit(password, confirmPassword);
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};
