import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LogoIcon } from "../../icons/LogoIcon";
import { COLORS } from "../../common/Theme";
import { TitleLarge, TitleSmall } from "../../common/Typography";
import { CardButton } from "./CardButton";

export const AddMonitor: React.FC = () => {
	const navigate = useNavigate();

	const onCardClick = (id: string) => {
		console.log("clicked", id);
		navigate(`/add-monitor/${id}`);
	};

	return (
		<StyledAddMonitor>
			<Header>
				<LogoIcon width={220} height={40} color={'#651BDC'} />
				<HeaderText>
					<TitleLarge color={COLORS.common.black}>
						Alright! Let's get you started
					</TitleLarge>
					<TitleSmall color={"#959595"}>
						Just a quick step and you're there
					</TitleSmall>
				</HeaderText>
			</Header>
			<ButtonContainer>
				<CardButton
					id={"single"}
					text={"Add Single Monitor"}
					onClick={onCardClick}
				/>
				<CardButton
					id={"multiple"}
					text={"Add Multiple Monitors"}
					onClick={onCardClick}
				/>
			</ButtonContainer>
			<Footer></Footer>
		</StyledAddMonitor>
	);
};

const StyledAddMonitor = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-image: url(${require("../../bgmonitor.png")});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

const Header = styled.div`
	display: flex;
	flex: 0.4;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 50px 0;
`;

const HeaderText = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	margin: 10px;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin: 10px;
`;

const Footer = styled.div`
	display: flex;
	flex: 0.2;
	justify-content: center;
`;
