import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input } from "../../components/Input";
import { DropDownOption } from "../../components/Dropdown";
import { IconCross, IconX } from "@tabler/icons-react";

const OptionsContainer = styled.div`
	position: absolute;
	top: 100%;
	top: 10;
	z-index: 1;
	background-color: #fff;
	border: 1px solid #ccc;
	border-top: none;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 100%;
	background-color: #fff;
	border: 1px solid #ccc;
	border-top: none;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
`;

const Option = styled.div`
	padding: 8px;
	cursor: pointer;

	&:hover {
		background-color: #f2f2f2;
	}

	color: #000;
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Tag = styled.div`
	display: inline-flex;
	align-items: center;
	padding: 10px;
	background: #f0f0f0;
	border-radius: 16px;
	font-size: 15px;
	position: absolute;
	top: 10px;
`;

const CloseButton = styled.button`
	border: none;
	background-color: transparent;
	cursor: pointer;
	display: inline-flex;
`;

const InputWithDropdown = (props: {
	options: DropDownOption[];
	onInputChange: (input: string) => void;
	defaultValue?: string;
}) => {
	const { options, onInputChange, defaultValue = "" } = props;

	const [inputValue, setInputValue] = useState(props.defaultValue);
	const [filteredOptions, setFilteredOptions] = useState<DropDownOption[]>(
		[]
	);
	const [tag, setTag] = useState(props.defaultValue); // State to store the current tag

	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		const filtered = options.filter((option) =>
			option.value.toLowerCase().includes(inputValue?.toLowerCase() ?? "")
		);
		setFilteredOptions(filtered);
	}, [inputValue, options]);

	const handleInputChange = (e: any) => {
		setInputValue(e.target.value);
		onInputChange(e.target.value);
	};

	const handleOptionClick = (value: string) => {
		setInputValue(value);
		setTag(value);
		setIsFocused(false); // Close the options list
		onInputChange(value);
	};

	return (
		<div style={{ display: "inline-flex", position: "relative" }}>
			{tag && (
				<Tag>
					<span className="tag-text">{tag}</span>
					<CloseButton
						onClick={() => {
							setTag("");
						}}
					>
						<IconX width={15} height={15} />
						{/* Replace with your actual CloseIcon component */}
					</CloseButton>
				</Tag>
			)}

			<Input
				fontSize="16px"
				width="266px"
				type="text"
				value={inputValue}
				onChange={handleInputChange}
				onFocus={() => setIsFocused(true)}
				onBlur={() => setTimeout(() => setIsFocused(false), 100)} // Delay for click event processing
				placeholder={!tag ? "Enter a Tag (Eg: Project)" : ""}
				onEnter={(input) => {
					setTag(inputValue);
				}}
				disabled={!!tag}
			/>
			{isFocused && filteredOptions.length > 0 && (
				<OptionsContainer>
					{filteredOptions.map((option, index) => (
						<Option
							key={index}
							onClick={() => handleOptionClick(option.value)}
						>
							{option.value}
						</Option>
					))}
				</OptionsContainer>
			)}
		</div>
	);
};

export default InputWithDropdown;
