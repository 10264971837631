import { COLORS } from "../../common/Theme";
import styled from "styled-components";

export const CardButton: React.FC<{
	id: string;
	text: string;
	onClick: (id: string) => void;
}> = ({ id, text, onClick }) => {
	return (
		<StyledCardButton onClick={() => onClick(id)}>
			<div className="image-and-text">
				<div className="card-button-icon">
					{id === "single" ? (
						<img
							src={require("../../single-monitor.png")}
							alt="something"
							width={60}
						/>
					) : (
						<img
							src={require("../../multiple-monitor.png")}
							alt="something"
							width={60}
						/>
					)}
				</div>
				<div className="monitor-title-text">{text}</div>
			</div>
		</StyledCardButton>
	);
};

const StyledCardButton = styled.div`
	display: flex;
	width: 420px;
	height: 260px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
	margin: 20px;
	background: ${COLORS.common.white};
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px -0.055px 2.684px 0px rgba(0, 0, 0, 0.03),
		0px -0.133px 6.451px 0px rgba(0, 0, 0, 0.04),
		0px -0.25px 12.146px 0px rgba(0, 0, 0, 0.05),
		0px -0.447px 21.666px 0px rgba(0, 0, 0, 0.05),
		0px -0.836px 40.524px 0px rgba(0, 0, 0, 0.06),
		0px -2px 97px 0px rgba(0, 0, 0, 0.09);

	.image-and-text {
		display: flex;
		flex-direction: column;
		margin: 50px;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.card-button-icon {
		width: 60px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		padding: 10px;
		background: rgba(101, 27, 220, 0.1);
	}

	img {
		width: 60px;
	}

	.monitor-title-text {
		font-family: Lato;
		font-size: 28px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	&:hover {
		background-color: ${COLORS.common.brand};
		color: white;
		cursor: pointer;

		.card-button-icon {
			background: white;
		}
	}
`;
