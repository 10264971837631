import React from "react";
import styled from "styled-components";
import { COLORS, FONTS } from "../common/Theme";
import { CircularProgress } from "@mui/material";

export type ButtonProps = {
	text: string;
	buttonType?: "primary" | "inverse";
	size?: "small" | "large";
	fontSize?: number;
	width?: string;
	isLoading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: React.FC<ButtonProps> = ({
	text,
	buttonType = "primary",
	size = "small",
	fontSize,
	width,
	isLoading = false,
	...rest
}) => {
	return (
		<StyledButton
			buttonType={buttonType}
			size={size}
			fontSize={fontSize}
			width={width}
			{...rest}
		>
			{isLoading ? (
				<CircularProgress
					style={{ color: "white", width: 20, height: 20 }}
				/>
			) : (
				<div className={`text-wrapper ${size}`}>{text}</div>
			)}
		</StyledButton>
	);
};

const StyledButton = styled.button<{
	buttonType: "primary" | "inverse";
	size: "small" | "large";
	fontSize?: number;
	width?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 10px 30px;
	position: relative;
	border-radius: 10px;
	border: 1px solid white;
	width: ${(props) =>
		props.width || (props.size === "large" ? "305px" : "auto")};
	background-color: ${(props) =>
		props.buttonType === "primary"
			? COLORS.common.brand
			: COLORS.common.white};
	color: ${(props) =>
		props.buttonType === "primary"
			? COLORS.common.white
			: COLORS.common.brand};
	font-family: "Lato-Bold", Helvetica;
	font-size: ${(props) => props.fontSize || FONTS.titleRegular.fontSize}px;
	font-weight: ${FONTS.titleRegular.fontWeight};

	&:hover {
		cursor: pointer;
		background-color: #8b49ff;
	}
`;
