import styled from "styled-components";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import { useApi } from "../../useApi";
import { useEffect, useState } from "react";
import { useAppStore } from "../../common/store";
import { AddEditEmailModal } from "./AddEditEmailModal";
import { AddEditSlackModal } from "./AddEditSlackModal";
import toast from "react-hot-toast";

const addOptions: DropDownOption[] = [
	{ id: "add_email", value: "Add email", selected: false },
	{ id: "add_slack", value: "Add Slack", selected: false },
];

export const getChannel = (d: any) => {
	if (d && "email" in d) {
		return "Email";
	}
	if (d && "slack_channel" in d) {
		return "Slack";
	}
	return "";
};

export const NotificationComponent = () => {
	const { data, error, loading, fetchData } = useApi<any>({
		method: "get",
		url: "/combined-data",
	});

	const combinedData = [
		...(data?.emails ?? []),
		...(data?.slackSettings ?? []),
	];

	const userId = useAppStore((store) => store.userId);

	const [selectedUuid, setSelectedUuid] = useState("");
	const [method, setMethod] = useState("");

	const [selectedEmail, setSelectedEmail] = useState<any>();
	const [selectedSlack, setSelectedSlack] = useState<any>();

	const [showEmailModal, setShowEmailModal] = useState(false);
	const [showSlackModal, setShowSlackModal] = useState(false);
	const [channel, setChannel] = useState("");

	const { data: toggledData, fetchData: toggle } = useApi<any>({
		autoFetch: false,
		method: "patch",
		url: `/${channel}/${selectedUuid}/toggle-status`,
	});

	const { data: deleteData, fetchData: deleteApi } = useApi<any>({
		autoFetch: false,
		method: "delete",
		url: `/${channel}/${selectedUuid}/delete`,
	});

	const { data: addData, fetchData: add } = useApi<any>({
		autoFetch: false,
		method: "post",
		url: `/user/${userId}/emails`,
		requestData: {
			emails: [selectedEmail],
		},
	});

	const { data: updateData, fetchData: update } = useApi<any>({
		autoFetch: false,
		method: "put",
		url: `/notification/update/${selectedUuid}`,
		requestData: {
			email: selectedEmail,
		},
	});

	const { data: addDataSlack, fetchData: addSlack } = useApi<any>({
		autoFetch: false,
		method: "post",
		url: `/slack/create`,
		requestData: selectedSlack,
	});

	useEffect(() => {
		if (toggledData || deleteData) {
			if (toggledData) {
				toast.success("Toggled Successfully!");
			}
			if (deleteData) {
				toast.success("Deleted Successfully!");
			}
			setSelectedUuid("");
			setMethod("");
			setChannel("");
			fetchData();
		}
	}, [toggledData, deleteData]);

	useEffect(() => {
		if (selectedUuid && method === "toggle") {
			toggle();
		}
		if (selectedUuid && method === "delete") {
			deleteApi();
		}
	}, [selectedUuid, method]);

	useEffect(() => {
		if (selectedEmail && method === "add") {
			add();
		}
		if (selectedEmail && method === "update") {
			update();
		}
	}, [selectedEmail, method]);

	useEffect(() => {
		if (updateData || addData) {
			if (addData) {
				toast.success("Added successfully!");
			}
			if (updateData) {
				toast.success("Updated successfully!");
			}
			setSelectedEmail(null);
			setMethod("");
			setShowEmailModal(false);
			fetchData();
		}
	}, [updateData, addData]);

	useEffect(() => {
		if (selectedSlack && method === "add") {
			addSlack();
		}
		if (selectedSlack && method === "update") {
			addSlack();
		}
	}, [selectedSlack, method]);

	useEffect(() => {
		if (addDataSlack) {
			toast.success("Add/Edit success!");
			setSelectedSlack(null);
			setMethod("");
			setShowSlackModal(false);
			fetchData();
		}
	}, [addDataSlack]);

	return (
		<StyledNotificationComponent>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex" }}>Notification Users</div>
				<div style={{ display: "flex" }}>
					<Dropdown
						placeholder={"Add"}
						options={addOptions}
						onChange={(selectedOption: DropDownOption) => {
							if (selectedOption.id === "add_email") {
								setShowEmailModal(true);
							}
							if (selectedOption.id === "add_slack") {
								setShowSlackModal(true);
							}
							return;
						}}
						width={"200px"}
						type="button"
					/>
				</div>
			</div>
			<div style={{ display: "flex" }}>
				<NotificationTableComponent
					data={combinedData}
					onToggle={(uuid: string, channel: string) => {
						console.log("uuid", uuid);
						setChannel(channel.toLowerCase());
						setSelectedUuid(uuid);
						setMethod("toggle");
					}}
					onEdit={(
						uuid: string,
						email: string,
						slackChannel: string
					) => {
						if (slackChannel) {
							setSelectedSlack({
								slackName: email,
								slackChannel,
							});
							setShowSlackModal(true);
						} else {
							setSelectedEmail(email);
							setShowEmailModal(true);
						}
						setSelectedUuid(uuid);
					}}
					onDelete={(uuid: string, channel: string) => {
						setChannel(channel.toLowerCase());
						setSelectedUuid(uuid);
						setMethod("delete");
					}}
				/>
			</div>
			{showEmailModal && !selectedEmail && (
				<AddEditEmailModal
					type={"add"}
					onSubmit={(input) => {
						setShowEmailModal(false);
						setMethod("add");
						setSelectedEmail(input);
					}}
					onClose={() => setShowEmailModal(false)}
					defaultInput={""}
				/>
			)}
			{showEmailModal && selectedEmail && (
				<AddEditEmailModal
					type={"edit"}
					onSubmit={(input) => {
						setShowEmailModal(false);
						setMethod("update");
						setSelectedEmail(input);
					}}
					onClose={() => {
						setSelectedEmail("");
						setShowEmailModal(false);
					}}
					defaultInput={selectedEmail}
				/>
			)}
			{showSlackModal && !selectedSlack && (
				<AddEditSlackModal
					type={"add"}
					onSubmit={(slackName, slackChannel) => {
						setShowSlackModal(false);
						setMethod("add");
						setSelectedSlack({
							slack_name: slackName,
							slack_channel: slackChannel,
						});
					}}
					onClose={() => setShowSlackModal(false)}
					defaultInput={""}
				/>
			)}
			{showSlackModal && selectedSlack && (
				<AddEditSlackModal
					type={"edit"}
					onSubmit={(slackName, slackChannel) => {
						setShowSlackModal(false);
						setMethod("update");
						setSelectedSlack({
							slack_name: slackName,
							slack_channel: slackChannel,
						});
					}}
					onClose={() => {
						setSelectedSlack(undefined);
						setShowSlackModal(false);
					}}
					defaultInput={selectedSlack}
				/>
			)}
		</StyledNotificationComponent>
	);
};

const StyledNotificationComponent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const notificatoinTableRowOptions: DropDownOption[] = [
	{ id: "edit", value: "Edit", selected: false },
	{ id: "delete", value: "Delete", selected: false },
];

export const NotificationTableComponent = (props: {
	data: any;
	onToggle: (uuid: string, channel: string) => void;
	onEdit: (id: string, email: string, slack?: any) => void;
	onDelete: (id: string, channel: string) => void;
}) => {
	const navigate = useNavigate();

	const initializeTableRows = () => {
		return (
			props.data?.map((d: any, index: number) => {
				const tableRow: NotificationTableRowType = {
					uuid: d.uuid,
					index: index,
					userOrGroup: d.email || d.slack_name,
					channel: getChannel(d),
					status: d.status === "active",
					onEdit: function (id: string): void {},
					onDelete: function (id: string): void {},
					onPause: function (id: string): void {},
					slackChannel: d.slack_channel,
				};
				return tableRow;
			}) ?? []
		);
	};

	const tableRows: NotificationTableRowType[] = initializeTableRows();

	return (
		<StyledNotificationTableComponent>
			<div className="table-header">
				<div className="header-text flex-two">User/Group</div>
				<div className="header-text flex-one">Channel</div>
				<div className="header-text flex-one">Status</div>
				<div></div>
			</div>
			{tableRows.map((tableRow) => {
				return (
					<NotificationTableRow
						uuid={tableRow.uuid}
						index={tableRow.index}
						userOrGroup={tableRow.userOrGroup}
						channel={tableRow.channel}
						status={tableRow.status}
						onEdit={function (id: string): void {
							props.onEdit(
								id,
								tableRow.userOrGroup,
								tableRow.slackChannel
							);
						}}
						onDelete={function (id: string, channel: string): void {
							props.onDelete(id, channel);
						}}
						onPause={function (id: string, channel: string): void {
							props.onToggle(id, channel);
						}}
						slackChannel={tableRow.slackChannel}
					/>
				);
			})}
		</StyledNotificationTableComponent>
	);
};

export type NotificationTableRowType = {
	uuid: string;
	index: number;
	userOrGroup: string;
	channel: string;
	status: boolean;
	slackChannel: string;
	onPause: (id: string, channel: string) => void;
	onEdit: (id: string) => void;
	onDelete: (id: string, channel: string) => void;
};

export const NotificationTableRow = (props: NotificationTableRowType) => {
	console.log("dev props", props);

	return (
		<StyledNotificationTableRow>
			<div className="row-text flex-two">{props.userOrGroup}</div>
			<div className="row-text flex-one">{props.channel}</div>
			<BrandSwitch
				defaultChecked={props.status}
				onChange={() => {
					console.log("change status", props.uuid);
					props.onPause(props.uuid, props.channel);
				}}
			/>
			<div>
				<Dropdown
					options={notificatoinTableRowOptions}
					placeholder={""}
					onChange={(selected) => {
						if (selected.id === "edit") {
							props.onEdit(props.uuid);
						}
						if (selected.id === "delete") {
							props.onDelete(props.uuid, props.channel);
						}
						return;
					}}
					width={"100px"}
					type="icon"
				/>
			</div>
		</StyledNotificationTableRow>
	);
};

export const StyledNotificationTableComponent = styled.div`
	display: flex;
	flex: 0.5;
	flex-direction: column;
	border: 1px solid #d9d9d9;

	.table-header {
		background: rgba(101, 27, 220, 1);
		display: flex;
		gap: 10px;
		padding: 10px;
		align-items: center;
		position: sticky;
		top: 0; /* Ensures the header is sticky at the top of the viewport */
		z-index: 5;
	}

	.header-text {
		color: #fff;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin: 5px;
	}

	.flex-lil {
		/* display: flex; */
		flex: 1;
	}

	.flex-one {
		/* display: flex; */
		flex: 2;
	}

	.flex-two {
		/* display: flex; */
		flex: 4;
	}

	.center {
		display: flex;
		justify-content: center;
	}
`;

export const StyledNotificationTableRow = styled.div`
	border: 1px solid #d9d9d9;
	background: #fff;
	display: flex;
	flex: 1;
	padding: 10px;
	align-items: center;

	&:hover {
		background-color: #e0e0e0;
		cursor: pointer;
	}

	.row-text {
		overflow: hidden;
		color: #000;
		text-overflow: ellipsis;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 5px;
		gap: 10px;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.flex-lil {
		flex: 1;
	}

	.flex-one {
		flex: 2;
	}

	.flex-two {
		flex: 3;
	}

	.flex-three {
		flex: 4;
	}

	.center {
		display: flex;
		justify-content: center;
	}

	.tag {
		border-radius: 30px;
		border: 1px solid #cdcdcd;
		display: inline-flex;
		padding: 8px 10px;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.status-container {
		display: flex;
		flex: 2;
		border-radius: 20px;
		background: #0fc000;
		justify-content: center;
		padding: 5px 0px;
		align-items: center;
	}

	.status-text {
		color: #fff;
		font-family: Lato;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
`;

const BrandSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase.Mui-checked": {
		color: "#651BDC",
	},
	"& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
		backgroundColor: "#651BDC",
	},
}));
