import { Icon } from "../common/icons-props";

export const EditIcon: Icon = (props) => {
	const { width, height, color, isSelected } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="15"
			viewBox="0 0 14 15"
			fill="none"
		>
			<path
				d="M9.83617 3.11732L10.8202 2.13265C11.0254 1.92751 11.3036 1.81226 11.5937 1.81226C11.8839 1.81226 12.1621 1.92751 12.3672 2.13265C12.5724 2.3378 12.6876 2.61603 12.6876 2.90615C12.6876 3.19627 12.5724 3.47451 12.3672 3.67965L6.17283 9.87407C5.86444 10.1823 5.48413 10.4088 5.06625 10.5332L3.5 10.9999L3.96667 9.43365C4.09108 9.01577 4.31762 8.63546 4.62583 8.32707L9.83617 3.11732ZM9.83617 3.11732L11.375 4.65615M10.5 8.66657V11.4374C10.5 11.7855 10.3617 12.1193 10.1156 12.3655C9.86944 12.6116 9.5356 12.7499 9.1875 12.7499H3.0625C2.7144 12.7499 2.38056 12.6116 2.13442 12.3655C1.88828 12.1193 1.75 11.7855 1.75 11.4374V5.3124C1.75 4.9643 1.88828 4.63046 2.13442 4.38432C2.38056 4.13818 2.7144 3.9999 3.0625 3.9999H5.83333"
				stroke="#333333"
				stroke-width="0.8"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
