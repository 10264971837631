import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useApi } from "../../useApi";
import styled from "styled-components";
import { SingleUrlDetails } from "../DashboardScreen/SingleUrlScreen/SingleUrlDetails";
import { TabContent, Tabs } from "../SettingsScreen/SettingsScreen";
import { InstantUrlRightContainer } from "./InstantUrlRightContainer";
import { Menu } from "../../components/Menu";
import { CircularProgress } from "@mui/material"; // Import CircularProgress for the loading indicator

interface LoaderProps {
	height: string;
}

export const InstantUrlReportScreen = () => {
	const { device } = useParams();
	const search = useLocation().search;
	const url = new URLSearchParams(search).get("url");

	const { data, error, loading, fetchData } = useApi<any>({
		autoFetch: false,
		method: "post",
		url: `/html-report`,
		requestData: {
			url: url,
			device: device,
		},
	});
	const [iframeHeight, setIframeHeight] = useState("100vh"); // Initialize iframeHeight state

	useEffect(() => {
		if (url) {
			setIframeHeight("100vh");
			fetchData();
		}
	}, [url]);
	console.log(device, url, data, error, iframeHeight); // Log iframeHeight to check its value

	const [iframeLoaded, setIframeLoaded] = useState(false);

	const handleIframeLoad = () => {
		setIframeLoaded(true);
	};

	return (
		<StyledInstantUrlReportScreen>
			<Menu width="100%" />
			<div style={{ display: "flex" }}>
				<SingleUrlDetails
					name={""}
					url={url ?? ""}
					device={device ?? ""}
					lastChecked={""}
					frequency={0}
					tag={""}
					status={""}
					imageUrl={""}
					statusColor=""
					activeStatus=""
				/>
				<InstantUrlRightContainer />
			</div>
			<div>
				<Tabs>
					<TabContent label="Lighthouse Report">
						{loading ? (
							<LoaderDiv
								height="100px"
								style={{ fontSize: "18px", fontWeight: "700" }}
							>
								{" "}
								<CircularProgress
									style={{ color: "#651bdc" }}
								/>{" "}
								The report is in process....
							</LoaderDiv>
						) : error ? (
							<div>Error: {error}</div>
						) : (
							<iframe
								src={data?.url}
								width="100%"
								onLoad={handleIframeLoad}
								title="Iframe Content"
								style={{ border: "none", height: iframeHeight }} // Apply iframeHeight to style
							></iframe>
						)}
					</TabContent>
					<div></div>
				</Tabs>
			</div>
		</StyledInstantUrlReportScreen>
	);
};

const StyledInstantUrlReportScreen = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow-y: scroll;
`;

const LoaderDiv = styled.div<LoaderProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	color: #333;
	overflow: hidden;
	transition: height 0.3s ease;
	height: ${(props) => props.height}; /* Set the height dynamically */
`;
