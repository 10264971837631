import { Icon } from "../common/icons-props";

export const EllipseIcon: Icon = (props) => {
	const { width, height, color } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || "9"}
			height={height || "9"}
			style={{
				borderRadius: "10px",
			}}
			viewBox={`0 0 ${width || "9"} ${height || "9"}`}
			fill="none"
		>
			<circle cx="1" cy="1" r={width || 9} fill={color || "#0FC000"} />
		</svg>
	);
};
