export const BASE_COLORS = {
	brand: {
		900: "#28034E",
		800: "#4B1583",
		500: "#731DCF",
		50: "#EFE6F9",
		10: "#F9F3FF",
	},
	grayscale: {
		900: "#212121",
		700: "#616161",
		500: "#9E9E9E",
		400: "#BDBDBD",
		200: "#E0E0E0",
		100: "#F0F0F0",
		70: "#F7F7F7",
		50: "#FAFAFA",
		white: "#FFFFFF",
		alpha: "#00000088",
	},
	negative: {
		900: "#B92321",
		700: "#D53434",
		100: "#FFCED4",
		50: "#FFEBEF",
	},
	warning: {
		900: "#99631B",
		500: "#FEC02D",
		100: "#FFEABE",
		50: "#FFF7E5",
	},
	positive: {
		900: "#00580E",
		700: "#1A872C",
		100: "#C3E4C4",
		50: "#E6F4E7",
	},
	bikAi: {
		500: "linear-gradient(93deg, #FF166A 0.37%, #FB5F08 100%)",
		100: "linear-gradient(93deg, #FEBAD2 0.37%, #FDD1B9 100%)",
		50: "linear-gradient(93deg, #FFE6EE 0.37%, #FFF0E7 100%)",
	},
};

export const COLORS = {
	background: {
		inactive: BASE_COLORS.grayscale["200"],
		base: BASE_COLORS.grayscale["100"],
		inverse: BASE_COLORS.brand["900"],
		inverseLight: BASE_COLORS.brand["800"],
		brand: BASE_COLORS.brand["500"],
		brandLight: BASE_COLORS.brand["50"],
		brandVeryLight: BASE_COLORS.brand["10"],
		negative: {
			vibrant: BASE_COLORS.negative["700"],
			light: BASE_COLORS.negative["50"],
		},
		warning: {
			vibrant: BASE_COLORS.warning["500"],
			light: BASE_COLORS.warning["50"],
		},
		positive: {
			vibrant: BASE_COLORS.positive["700"],
			light: BASE_COLORS.positive["50"],
		},
		blue: {
			light: "#E5F2FF",
		},
	},
	icon: { placeholder: "#9E9E9E" },
	scrim: {
		standard: "#00000099",
	},
	surface: {
		standard: BASE_COLORS.grayscale["white"],
		subdued: BASE_COLORS.grayscale["50"],
		hovered: BASE_COLORS.grayscale["100"],
		grey: BASE_COLORS.grayscale["70"],
		ai: BASE_COLORS.bikAi["500"],
		aiLight: BASE_COLORS.bikAi["50"],
	},
	content: {
		primary: BASE_COLORS.grayscale["900"],
		secondary: BASE_COLORS.grayscale["700"],
		placeholder: BASE_COLORS.grayscale["500"],
		inactive: BASE_COLORS.grayscale["400"],
		brand: BASE_COLORS.brand["500"],
		primaryInverse: BASE_COLORS.grayscale["white"],
		secondaryInverse: "#ffffffcc",
		negative: BASE_COLORS.negative["900"],
		warning: BASE_COLORS.warning["900"],
		positive: BASE_COLORS.positive["900"],
		blue: "#28458F",
		ai: BASE_COLORS.bikAi["500"],
	},
	stroke: {
		primary: BASE_COLORS.grayscale["200"],
		brand: BASE_COLORS.brand["500"],
		negative: {
			lightAlt: BASE_COLORS.negative["100"],
			vibrant: BASE_COLORS.negative["700"],
		},
		warning: {
			lightAlt: BASE_COLORS.warning["100"],
			vibrant: BASE_COLORS.warning["500"],
		},
		positive: {
			lightAlt: BASE_COLORS.positive["100"],
			vibrant: BASE_COLORS.positive["700"],
		},
		brandLightAlt: "#DDCCF0",
		blue: {
			lightAlt: "#C3D5E5",
		},
		ai: {
			lightAlt: "#FEBAD2",
			vibrant: "#F23275",
		},
	},
	special: {
		whatsapp: {
			brand: "#25D366",
			gradient: "linear-gradient(360deg, #20B038 0%, #60D66A 100%)",
			dark: "#455A64",
			gradientArr: ["#20B038", "#60D66A"],
		},
		brand: {
			google: "#DD4B39",
			facebook: "#1877F2",
		},
	},
	text: {
		primary: BASE_COLORS.grayscale["900"],
		secondary: BASE_COLORS.grayscale["700"],
		placeholder: BASE_COLORS.grayscale["500"],
		disabled: BASE_COLORS.grayscale["400"],
		white: BASE_COLORS.grayscale["white"],
		brand: BASE_COLORS.brand["500"],
		altPrimary: BASE_COLORS.brand["900"],
		altSecondary: BASE_COLORS.brand["800"],
	},
	common: {
		white: "#FFFFFF",
		black: "#000000",
		brand: "#651BDC",
	},
};

export const FONTS = {
	display: {
		fontWeight: "400",
		fontSize: 32,
		lineHeight: 40,
		letterSpacing: 0,
	},
	titleXLarge: {
		fontWeight: "600",
		fontSize: 32,
		lineHeight: 40,
		letterSpacing: 0,
	},
	//Changed
	titleLarge: {
		fontWeight: "700",
		fontSize: 48,
	},
	//Changed
	titleMedium: {
		fontWeight: "700",
		fontSize: 38,
	},
	//Changed
	titleRegular: {
		fontWeight: "700",
		fontSize: 20,
	},
	//Changed
	titleSmall: {
		fontWeight: "500",
		fontSize: 20,
	},
	//Changed
	bodyPrimary: {
		fontWeight: "400",
		fontSize: 16,
	},
	//Changed
	bodyPrimaryLink: {
		fontWeight: "700",
		fontSize: 16,
	},
	bodyLarge: {
		fontWeight: "400",
		fontSize: 20,
		lineHeight: 28,
		letterSpacing: 0,
	},
	bodySecondary: {
		fontWeight: "400",
		fontSize: 14,
		lineHeight: 20,
		letterSpacing: 0,
	},
	caption: {
		fontWeight: "400",
		fontSize: 12,
		lineHeight: 16,
		letterSpacing: 0,
	},
	tiny: {
		fontWeight: "400",
		fontSize: 10,
		lineHeight: 12,
		letterSpacing: 0,
	},
	subHeading: {
		fontWeight: "600",
		fontSize: 10,
		lineHeight: 12,
		letterSpacing: 0,
	},

	title1: {
		fontWeight: "600",
		fontSize: 24,
		lineHeight: 32,
		letterSpacing: 0,
	},
	title1Mobile: {
		fontWeight: "600",
		fontSize: 20,
		lineHeight: 30,
		letterSpacing: 0,
	},
	title2: {
		fontWeight: "600",
		fontSize: 20,
		lineHeight: 28,
		letterSpacing: 0,
	},
	title2Mobile: {
		fontWeight: "600",
		fontSize: 18,
		lineHeight: 24,
		letterSpacing: 0,
	},
	title3: {
		fontWeight: "600",
		fontSize: 16,
		lineHeight: 24,
		letterSpacing: 0,
	},
	title3Mobile: {
		fontWeight: "500",
		fontSize: 14,
		lineHeight: 20,
		letterSpacing: 0,
	},
	title4: {
		fontWeight: "400",
		fontSize: 14,
		lineHeight: 20,
		letterSpacing: 0,
	},
	textPlaceholder: {
		fontWeight: "400",
		fontSize: 12,
		lineHeight: 16,
		letterSpacing: 0,
	},
	title4Mobile: {
		fontWeight: "400",
		fontSize: 10,
		lineHeight: 16,
		letterSpacing: 0,
	},
	bodyRegularMobile: {
		fontWeight: "400",
		fontSize: 14,
		lineHeight: 18,
		letterSpacing: 0,
	},
	bodyRegular: {
		fontWeight: "400",
		fontSize: 16,
		lineHeight: 24,
		letterSpacing: 0,
	},

	bodySmall: {
		fontWeight: "400",
		fontSize: 10,
		lineHeight: 12,
		letterSpacing: 0,
	},
	buttonLarge: {
		fontWeight: "600",
		fontSize: 16,
		lineHeight: 20,
		letterSpacing: 0,
	},
	buttonRegular: {
		fontWeight: "600",
		fontSize: 14,
		lineHeight: 20,
		letterSpacing: 0,
	},
	captionMobile: {
		fontWeight: "500",
		fontSize: 10,
		lineHeight: 12,
		letterSpacing: 0,
	},
	captionBold: {
		fontWeight: "700",
		fontSize: 12,
		lineHeight: 16,
		letterSpacing: 0,
	},
	text1Bold: {
		fontWeight: "700",
		fontSize: 10,
		lineHeight: 14,
		letterSpacing: 0,
	},
	toast: {
		fontWeight: "400",
		fontSize: 16,
		lineHeight: 24,
		letterSpacing: 0,
	},
	toastMobile: {
		fontWeight: "400",
		fontSize: 10,
		lineHeight: 12,
		letterSpacing: 0,
	},
};

// For backward compatibility
export const DEFAULT_THEME = {
	colorsV2: COLORS,
	fonts: FONTS,
};
