import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../common/store";
import { useApi } from "../../useApi";
import { CustomSliderWrapperProps } from "./CustomSliderWrapper";
import { useEffect } from "react";
import toast from "react-hot-toast";

type CreateMonitorApiPayload = {
	user_id: number;
	name: string;
	devices: string[];
	urls: string[];
	score_thresholds: {
		performance: number;
		accessibility: number;
		"best-practices": number;
		seo: number;
		pwa: number;
	};
	frequency: number;
	tags: string[];
	locations: string[];
	notifications: string[];
};

export type CreateMonitorApiProps = {
	name: string;
	device: string;
	urls: string[];
	sliderValues: CustomSliderWrapperProps[];
	frequency: string;
	tag: string;
	onClose?: () => void;
	fetchDashboardData?: () => Promise<void>;
};

export const useCreateMonitorApi = (props: CreateMonitorApiProps) => {
	const userId = useAppStore((state) => state.userId);

	const navigate = useNavigate();

	const getFrequencyAsNumber = (freq: string) => {
		if (freq === "Weekly") {
			return 7;
		}

		if (freq === "Monthly") {
			return 30;
		}

		return 0;
	};

	const getScoreThresholdsFromSliderValues = (
		sliderValues: CustomSliderWrapperProps[]
	) => {
		const score_thresholds: CreateMonitorApiPayload["score_thresholds"] = {
			performance: 0,
			accessibility: 0,
			"best-practices": 0,
			seo: 0,
			pwa: 0,
		};

		sliderValues.forEach((sv) => {
			//@ts-ignore
			score_thresholds[sv.id] = sv.value;
		});

		return score_thresholds;
	};

	const payload: CreateMonitorApiPayload = {
		user_id: userId,
		name: props.name,
		devices: [props.device],
		urls: props.urls,
		score_thresholds: getScoreThresholdsFromSliderValues(
			props.sliderValues
		),
		frequency: getFrequencyAsNumber(props.frequency),
		locations: ["India"],
		notifications: ["email"],
		tags: [props.tag],
	};

	console.log("payload", payload);

	const { data, error, loading, fetchData } = useApi<any>({
		autoFetch: false,
		method: "post",
		url: "/create/monitor",
		requestData: payload,
	});

	useEffect(() => {
		if (data) {
			navigate("/dashboard");
			props.fetchDashboardData?.();
			toast.success("Monitor Addition Success!");
			props.onClose?.();
		}

		if (error) {
			props.onClose?.();
		}
	}, [data, error]);

	return { data, error, loading, fetchData };
};
