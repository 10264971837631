import { useEffect } from "react";
import { useApi } from "../../useApi";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../common/store";
import toast, { Toaster } from "react-hot-toast";

type AuthResponseSuccess = {
	data: {
		original: {
			token: string;
			user: {
				id: number;
				uuid: string;
			};
			monitor: number;
		};
	};
};

export const useAuthApi = (
	authType: string,
	email: string,
	password: string,
	name: string,
	confirmPassword: string,
	tokenFromUrl?: string,
	id?: string
) => {
	console.log("authType", authType);

	const getUrl = () => {
		if (authType === "login") return "/login";
		if (authType === "signup") return "/register";
		if (authType === "forgot-password") return "/forgot-password";
		if (authType === "password-reset") return "/password-reset";
		if (authType === "google") return "/google-callback";

		return "";
	};

	const getRequestData = () => {
		if (authType === "login") return loginRequestData;
		if (authType === "signup") return signupRequestData;
		if (authType === "forgot-password") return forgotPasswordRequestData;
		if (authType === "password-reset") return resetPasswordRequestData;
		if (authType === "google") return googleData;

		return {};
	};

	const url = getUrl();

	console.log("url", url);

	const navigate = useNavigate();
	const { setToken, setUserId, setUuid } = useAppStore();

	const loginRequestData = {
		email: email,
		password: password,
	};

	const signupRequestData = {
		email: email,
		password: password,
		password_confirmation: password,
		name: name,
	};

	const forgotPasswordRequestData = {
		email: email,
	};

	const resetPasswordRequestData = {
		token: tokenFromUrl,
		email: email,
		password: password,
		password_confirmation: confirmPassword,
	};

	const googleData = {
		email: email,
		name: name,
		id: id ?? "",
	};

	const { data, loading, error, fetchData } = useApi<AuthResponseSuccess>({
		autoFetch: false,
		method: "post",
		url: url,
		requestData: getRequestData(),
		options: {
			contentType: "form-data",
		},
	});

	useEffect(() => {
		if (data) {
			const token = data?.data?.original?.token;
			const user = data?.data?.original?.user;
			const monitor = data?.data?.original?.monitor;
	
			console.log("Token:", token);
			console.log("User:", user);
			console.log("Monitor:", monitor);
	
			if (token) {
				setToken(token);
			}
	
			if (user) {
				setUserId(user.id);
				setUuid(user.uuid);
			}
	
			toast.dismiss();
	
			if (authType === "forgot-password") {
				toast.success("Reset Password Link has been sent");
			} else if (authType === "password-reset") {
				navigate("/login");
			} else if (authType === "signup") {
				navigate("/add-monitor");
			} else if (monitor === 0) {
				console.log("Navigating to /add-monitor");
				navigate("/add-monitor");
			} else {
				console.log("Navigating to /dashboard");
				navigate("/dashboard");
			}
		}
	}, [data]);
	

	return { data, loading, error, fetchData };
};
