import styled from "styled-components";
import { AlertDashboardComponent } from "./AlertDashboardComponent";
import { MonitorDashboardComponent } from "./MonitorsDashboardComponent";
import { StatusDashboardComponent } from "./StatusDashboardComponent";
import { TableComponent } from "./TableComponent";
import { EllipseIcon } from "../../icons/EllipseIcon";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { Modal } from "../../components/Modal";
import SubscribeAlert from "./SubscribeAlert";
import { EditMonitorModal } from "./EditMonitorModal";
import { useEffect, useState } from "react";
import { useApi } from "../../useApi";
import { CircularProgress } from "@mui/material"; // Import CircularProgress for the loading indicator
import toast from "react-hot-toast";
import { AddAnyMonitor } from "./AddAnyMonitor";
import { Input } from "../../components/Input";
import { formatDateObject, formatDateString } from "../../common/utils";
import { format } from "date-fns";
import { IconRefresh } from "@tabler/icons-react";

const addNewMonitorOptions: DropDownOption[] = [
    { id: "single", value: "Single Monitor", selected: false },
    { id: "multiple", value: "Multiple Monitor", selected: false },
];


const allOption: DropDownOption = {
    id: "all",
    value: "All",
    selected: false,
};

const globalStatusOptions: DropDownOption[] = [
    allOption,
    { id: "good", value: "Good", selected: false },
    { id: "needs_improvement", value: "Needs Improvement", selected: false },
    { id: "poor", value: "Poor", selected: false },
    { id: "paused", value: "Paused", selected: false },
];

export const DashboardScreen = (props: {
    dashboardData: any;
    fetchDashboardData: () => Promise<void>;
    userData: any;
    dashboardLoading: boolean;
}) => {
    const monitors: number = props.dashboardData?.length ?? 0;
	const { plan, url_limit: urlLimit } = props.userData;
    const planText = plan === "pro" ? "Pro Plan" : "Free Plan";
    const [selectedMonitor, setSelectedMonitor] = useState<any>();
    const [selectedUuid, setSelectedUuid] = useState("");
    const [method, setMethod] = useState("");
    const [showAddModal, setShowAddModal] = useState("");
    const [tagOptions, setTagOptions] = useState<DropDownOption[]>([]);
    const [selectedTag, setSelectedTag] = useState<DropDownOption>();
    const [selectedStatus, setSelectedStatus] = useState<DropDownOption>();
    const [searchText, setSearchText] = useState("");
    const [statusOptions, setstatusOptions] = useState<DropDownOption[]>([]);

    const { data: alertCountData, fetchData: fetchAlertCount } = useApi<any>({
        method: "get",
        url: "/alert_count",
    });

    useEffect(() => {
        if (props.dashboardLoading) {
            fetchAlertCount();
        }
    }, [props.dashboardLoading]);

    const getNumberOfFilters = () => {
        let filters = 0;
        if (searchText) {
            filters++;
        }
        if (selectedTag) {
            filters++;
        }
        if (selectedStatus) {
            filters++;
        }
        return filters;
    };

    const numberOfFilters = getNumberOfFilters();

    const getStatus = (d: any) => {
        if (d.status !== "active") {
            return "Paused";
        }

        const s = d.performance_logs?.[0]?.performance_status;

        if (s === "poor") {
            return "Poor";
        }

        if (s === "good") {
            return "Good";
        }

        if (s === "needs_improvement") {
            return "Needs Improvement";
        }

        return "Paused";
    };

    const [filteredDashboardData, setFilteredDashboardData] = useState(
        props.dashboardData
    );

    const {
        data: toggleData,
        fetchData: toggle,
        loading: toggleLoading,
    } = useApi<any>({
        autoFetch: false,
        method: "patch",
        url: `/monitors/${selectedUuid}/toggle-status`,
    });

    const {
        data: tagData,
        error: tagError,
        loading: tagLoading,
    } = useApi<any>({
        method: "get",
        url: "/tags",
    });

    const {
        data: deleteData,
        fetchData: deleteApi,
        loading: deleteLoading,
    } = useApi<any>({
        autoFetch: false,
        method: "delete",
        url: `/monitors/${selectedUuid}/delete`,
    });

    useEffect(() => {
        setFilteredDashboardData(props.dashboardData);
        setstatusOptions(globalStatusOptions);
    }, [props.dashboardData]);

    useEffect(() => {
        console.log("dev tagdata", tagData);
        if (tagData) {
            const tempTagOptions = tagData?.map((tag: any) => {
                return {
                    id: tag?.name ?? "",
                    value: tag?.name ?? "",
                    selected: false ?? "",
                };
            });
            setTagOptions([allOption, ...tempTagOptions]);
        }
    }, [tagData]);

    useEffect(() => {
        const filtered = props.dashboardData
            ?.filter((d: any) => {
                if (!searchText) return true;
                return (
                    d?.name?.includes(searchText) ||
                    d?.url?.includes(searchText)
                );
            })
            ?.filter((d: any) => {
                if (!selectedStatus || selectedStatus?.id === "all")
                    return true;
                const s = getStatus(d);

                return s === selectedStatus.value;
            })
            ?.filter((d: any) => {
                if (!selectedTag || selectedTag?.id === "all") return true;
                return d.tag?.[0]?.name === selectedTag.id;
            });

        if (filtered) {
            setFilteredDashboardData(filtered);
        }
    }, [searchText, selectedStatus, selectedTag]);

    useEffect(() => {
        if (toggleLoading) {
            toast.loading("Toggling Status!");
        }

        if (deleteLoading) {
            toast.loading("Deleting..");
        }
    }, [toggleLoading]);

    useEffect(() => {
        if (toggleData || deleteData) {
            setSelectedUuid("");
            props.fetchDashboardData();
        }

        if (toggleData) {
            toast.dismiss();
            toast.success("Toggled successfully");
        } else if (deleteData) {
            toast.dismiss();
            toast.success("Deleted successfully");
        }
    }, [toggleData, deleteData]);

    console.log("selectedUuid", selectedUuid, method);

    useEffect(() => {
        if (selectedUuid) {
            if (method === "toggle") {
                toggle();
            }
            if (method === "delete") {
                deleteApi();
            }
        }
    }, [selectedUuid, method]);

	const getLastCheckedFormatted = () => {
		const dates = props.dashboardData
			?.map((d: any) => {
				return new Date(d?.last_checked);
			})
			?.sort((a: any, b: any) => b.getTime() - a.getTime());

		console.log("dates", dates);

		if (dates) {
			return formatDateObject(dates[0]);
		}
	};

	const [rotation, setRotation] = useState(0);

	return (
		<StyledDashboardScreen>
		
			<div
				style={{
					display: "flex",
					gap: "50px",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
						justifyContent: "center",
						margin: "10px ",
						flex: 1,
					}}
				>
					{/* <div className="welcome-user">Welcome User</div> */}
					<div
				style={{
					display: "flex",
					// justifyContent: "start-flex",
					margin: "10px",
					gap: "20px",
					alignItems: "flex-end",
				}}
			>
				<div className="dashboard-text">Dashboard</div>
				<div>
					<IconRefresh
						stroke={2}
						style={{
							color: "#333333",
							width: "30px",
							height: "30px",
							cursor: "pointer",
							transform: `rotate(${rotation}deg)`,
							transition: "transform 0.2s", // Smooth transition for rotation
						}}
						onClick={(e) => {
							e.stopPropagation();
							setRotation((prev) => {
								return prev + 180;
							});
							props.fetchDashboardData();
						}}
					/>
				</div>
			</div>
					<div className="last-check">
						Last check ran on {getLastCheckedFormatted()}
					</div>
					<div className="free-plan-tag">
						<div className="ellipse-icon">
							<EllipseIcon color="#651BDC" />
						</div>
						<div className="free-text">{planText}</div>
					</div>
				</div>
				<div style={{ display: "flex", flex: "none" }}>
					<MonitorDashboardComponent numberOfMonitors={monitors} urlLimit={urlLimit} />
				</div>
				<div style={{ display: "flex", flex: "none" }}>
					<StatusDashboardComponent data={props.dashboardData} />
				</div>
				<div
					style={{
						display: "flex",
						flex: "none",
						marginRight: "10px",
					}}
				>
					<AlertDashboardComponent data={alertCountData} />
				</div>
			</div>
			<div
				style={{
					display: "flex",
					flex: 1,
					alignItems: "center",
					gap: "40px",
					width: "auto",
					justifyContent: "center",
				}}
			>
				<Input
					value={searchText}
					placeHolder="Search Monitor Name or URL"
					width="300px"
					fontSize="16px"
					style={{ padding: "8px" }}
					onInputChange={setSearchText}
				/>
				<Dropdown
					options={tagOptions}
					placeholder={"Select Tags"}
					onChange={setSelectedTag}
					width={"200px"}
				/>
				<Dropdown
					options={statusOptions}
					placeholder={"Status"}
					onChange={setSelectedStatus}
					width={"200px"}
				/>

				<div className="zero-filters">
					{numberOfFilters} filters applied
				</div>
				<div
					className="clear-filters"
					onClick={() => {
						setSearchText("");
						const tempTagOptions = tagData?.map((tag: any) => {
							return {
								id: tag?.name ?? "",
								value: tag?.name ?? "",
								selected: false ?? "",
							};
						});

						setSelectedTag(undefined);
						setSelectedStatus(undefined);

						setTagOptions([allOption, ...tempTagOptions]);
						setstatusOptions([...globalStatusOptions]);
					}}
				>
					Clear filters
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						flex: 1,
					}}
				>
					<Dropdown
						placeholder={"+ Add new monitor"}
						options={addNewMonitorOptions}
						onChange={(selectedOption: DropDownOption) => {
							setShowAddModal(selectedOption.id);

							// if (selectedOption.id === "single") {
							// 	// navigate("/add-monitor/single");
							// }
							// if (selectedOption.id === "multiple") {
							// 	navigate("/add-monitor/multiple");
							// }
							return;
						}}
						width={"auto"}
						type="button"
					/>
				</div>
			</div>
			<TableComponent
				dashboardLoading={props.dashboardLoading}
				data={filteredDashboardData}
				onEdit={(id: string) => {
					console.log("dev edit", id);

					const monitor = props.dashboardData?.find(
						(monitor: any) => monitor.uuid === id
					);
					setSelectedMonitor(monitor);
				}}
				onPause={(id: string) => {
					setSelectedUuid(id);
					setMethod("toggle");
					// setTimeout(() => toggle(), 500);
					// toggle();
				}}
				onDelete={(id: string) => {
					setSelectedUuid(id);
					setMethod("delete");
				}}
			/>
			{selectedMonitor ? (
				<Modal width={"auto"} onClose={function (): void {}}>
					<EditMonitorModal
						open={selectedMonitor}
						uuid={selectedMonitor?.uuid ?? ""}
						monitorName={selectedMonitor?.name ?? ""}
						sliderValues={selectedMonitor?.score_thresholds ?? []}
						selectedDeviceId={selectedMonitor?.devices?.[0] ?? ""}
						url={selectedMonitor?.url ?? ""}
						selectedTagId={selectedMonitor?.tag?.[0]?.name ?? ""}
						selectedFrequencyId={selectedMonitor?.frequency ?? ""}
						onClose={() => setSelectedMonitor(null)}
						fetchDashboardData={props.fetchDashboardData}
					/>
				</Modal>
			) : (
				<></>
			)}
			{showAddModal ? (
				<Modal width={"auto"} onClose={function (): void {}}>
					<AddAnyMonitor
						monitorType={showAddModal as any}
						onClose={() => setShowAddModal("")}
						fetchDashboardData={props.fetchDashboardData}
					/>
				</Modal>
			) : (
				<></>
			)}
		</StyledDashboardScreen>
	);
};

export const StyledDashboardScreen = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	gap: 20px;
	margin: 30px 50px;
	flex: 1;
	overflow-y: scroll;

	.dashboard-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 38px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.welcome-user {
		color: #000;
		font-family: Lato;
		font-size: 38px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.last-check {
		color: #959595;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.free-plan-tag {
		display: inline-flex;
		padding: 10px 20px;
		gap: 10px;
		border-radius: 40px;
		background: rgba(101, 27, 220, 0.1);
		width: max-content;
	}

	.free-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.ellipse-icon {
		display: flex;
		justify-content: center;
		align-self: center;
	}

	.clear-filters {
		color: #651bdc;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-decoration-line: underline;
		cursor: pointer;
	}

	.zero-filters {
		color: #959595;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;
