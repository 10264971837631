import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Slider } from "@mui/material";
import { COLORS } from "../common/Theme";

export type CustomSliderProps = {
	displayValue: number;
	onChange: (value: number) => void;
	min?: number;
	max?: number;
	sliderColor?: string;
	disabled?: boolean;
};

export const CustomSlider: React.FC<CustomSliderProps> = (props) => {
	const {
		displayValue,
		onChange,
		min = 0,
		max = 100,
		sliderColor,
		disabled,
	} = props;
	const [sliderValue, setSliderValue] = useState(displayValue);

	console.log("displayvalue", displayValue);

	useEffect(() => {
		if (sliderValue !== displayValue) {
			setSliderValue(displayValue);
		}
	}, [displayValue, sliderValue]);

	const getSliderColor = () => {
		if (sliderValue < 50) {
			return "#FF3333";
		} else if (sliderValue < 90) {
			return "#ED7717";
		} else {
			return "#0FC000";
		}
	};

	return (
		<StyledCustomSlider
			min={min}
			max={max}
			value={sliderValue}
			onChange={(event, value) => {
				setSliderValue(value as number);
				onChange(value as number);
			}}
			disabled={disabled}
			sliderColor={sliderColor || getSliderColor()}
			sx={{
				color: props.sliderColor || getSliderColor(),
				display: "flex",
				flex: 1,
			}}
		/>
	);
};

interface StyledCustomSliderProps {
	disabled?: boolean;
	sliderColor?: string;
}

export const StyledCustomSlider = styled(Slider)<StyledCustomSliderProps>`
	&.Mui-disabled {
		color: ${COLORS.common.brand} !important;
	}

	.MuiSlider-thumb {
		display: ${(props) => (props.disabled ? "none" : "flex")};
	}
`;
