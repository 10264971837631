import { useNavigate, useParams } from "react-router-dom";
import { DashboardIcon } from "../../icons/DashboardIcon";
import { ResourcesIcon } from "../../icons/ResourcesIcon";
import { SettingsIcon } from "../../icons/SettingsIcon";
import { LogoOnlyIcon } from "../../icons/LogoOnly";
import { LogoutIcon } from "../../icons/LogoutIcon";
import styled from "styled-components";
import { COLORS } from "../../common/Theme";
import { DashboardScreen } from "../DashboardScreen/DashboardScreen";
import { SettingsScreen } from "../SettingsScreen/SettingsScreen";
import { ResourceScreen } from "../ResourceScreen/Resource";
import { useAppStore } from "../../common/store";
import { useEffect } from "react";
import { useApi } from "../../useApi";
import { SingleUrlScreen } from "../DashboardScreen/SingleUrlScreen/SingleUrlScreen";
import toast, { Toaster } from "react-hot-toast";
import SubscribeAlert from "../DashboardScreen/SubscribeAlert";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import { TempResourceScreen } from "../ResourceScreen/TempResourceScreen";

export const AuthenticatedHome = () => {
    const { token, setToken } = useAppStore();
    const { screenName, id } = useParams();
    const navigate = useNavigate();

    if (!token) {
        navigate("/");
    }

    const changeScreen = (name: string) => {
        if (name !== screenName) {
            navigate(`${name}`);
        }
    };

    const {
        data: dashboardData,
        error,
        loading,
        fetchData: fetchDashboardData,
    } = useApi<any>({
        autoFetch: false,
        method: "get",
        url: "/monitors",
    });

    const { data: userData, loading: userLoading } = useApi<any>({
        method: "get",
        url: "/user",
    });

    useEffect(() => {
        fetchDashboardData();
    }, []);

    useEffect(() => {
        if (!id && screenName === "dashboard") {
            fetchDashboardData();
        }
    }, [screenName, id]);

    const { data: logOutData, fetchData: logout } = useApi<any>({
        autoFetch: false,
        method: "post",
        url: "/logout",
    });

    useEffect(() => {
        if (logOutData) {
            setToken("");
        }
    }, [logOutData]);

    useEffect(() => {
        console.log("User data fetched:", userData);
    }, [userData]);

    if (userLoading) {
        return <div>Loading...</div>; // or any other loading indicator
    }

    if (!userData) {
        return <div>Error fetching user data</div>;
    }

    console.log("Rendering component with user plan:", userData.plan);

    return (
        <StyledAuthenticatedHome>
            <Toaster />
            <Sidebar>
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        flexDirection: "column",
                    }}
                >
                    <div className="icon">
                        <LogoOnlyIcon />
                    </div>
                    <div
                        className="icon"
                        onClick={() => {
                            changeScreen("/dashboard");
                        }}
                    >
                        <DashboardIcon
                            isSelected={screenName === "dashboard" || !!id}
                        />
                    </div>
                    <div
                        className="icon"
                        onClick={() => {
                            changeScreen("/resources");
                        }}
                    >
                        <ResourcesIcon
                            isSelected={screenName === "resources"}
                        />
                    </div>
                    <div
                        className="icon"
                        onClick={() => {
                            changeScreen("/settings");
                        }}
                    >
                        <SettingsIcon isSelected={screenName === "settings"} />
                    </div>
                </div>
                <div
                    className="icon logout"
                    onClick={() => {
                        logout();
                    }}
                >
                    <LogoutIcon />
                </div>
            </Sidebar>
            <MainContent>
                {!id && (
                    <Header>
                        {userData.plan !== "pro" && <SubscribeAlert />}
                        <DropdownWrapper>
                            <Dropdown
                                options={[
                                    {
                                        id: "profile",
                                        value: "Profile",
                                        selected: false,
                                    },
                                ]}
                                placeholder={userData?.name?.[0] ?? ""}
                                onChange={function (selectedOption: DropDownOption): void {
                                    if (selectedOption.id === "profile") {
                                        navigate("/settings");
                                    }
                                }}
                                width={"auto"}
                                openedWidth="100px"
                                type="avatar"
                                disabled={true}
                            />
                        </DropdownWrapper>
                    </Header>
                )}
                {id ? (
                    <SingleUrlScreen
                        id={id}
                        fetchDashboardData={fetchDashboardData}
                        dashboardData={dashboardData}
                        dashboardLoading={loading}
                    />
                ) : screenName === "dashboard" ? (
                    <DashboardScreen
                        dashboardData={dashboardData}
                        fetchDashboardData={fetchDashboardData}
                        userData={userData}
                        dashboardLoading={loading}
                    />
                ) : (
                    <></>
                )}
                {screenName === "resources" ? <TempResourceScreen /> : <></>}
                {screenName === "settings" ? <SettingsScreen /> : <></>}
            </MainContent>
        </StyledAuthenticatedHome>
    );
};



const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    margin: 10px;
    background: ${COLORS.common.brand};
    padding-top: 10px;
    height: calc(100% - 30px);
    flex: 0.05;
`;

const MainContent = styled.div`
    overflow-y: scroll;
    flex: 1;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 50px;
`;

const DropdownWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
`;



export const StyledAuthenticatedHome = styled.div`
	display: flex;
	flex: 1;
	// height: 100vh;

	.icon {
		display: flex;
		justify-content: center;
		align-self: center;
		align-content: center;
		padding: 10px;

		&:hover {
			cursor: pointer;
			border-radius: 10px;
			background: rgba(0, 0, 0, 0.3);
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
		}
	}

	.logout {
		margin-bottom: 10px;
	}
`;