import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useApi } from "../../../useApi";
import styled from "styled-components";
import { COLORS } from "../../../common/Theme";
import { format, isWithinInterval, parseISO } from "date-fns";
import { EllipseIcon } from "../../../icons/EllipseIcon";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "../../../components/Button";
import { CircularProgress, Pagination } from "@mui/material";

export const AlertLogComponent = (props: { id: string; fileName: string }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedId, setSelectedId] = useState("all");
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const [filteredData, setFilteredData] = useState<any[]>([]);

    const { data, error, loading, fetchData } = useApi<any>({
        autoFetch: false,
        method: "get",
        url: `/alert_records_pagination/${props.id}?page=${currentPage}`,
    });

    useEffect(() => {
        fetchData();
    }, [currentPage, props.fileName]);

    useEffect(() => {
        if (data?.alert_data) {
            applyFilters();
        }
    }, [data, selectedId, fromDate, toDate]);

    const applyFilters = () => {
        let filtered = data.alert_data;

        if (selectedId !== "all") {
            filtered = filtered.filter((d: any) => d.quick_results === selectedId);
        }

        if (fromDate && toDate) {
            filtered = filtered.filter((d: any) =>
                isWithinInterval(parseISO(d.date), { start: fromDate, end: toDate })
            );
        }

        setFilteredData(filtered);
    };

    return (
        <StyledAlertLogComponent>
            <div style={{ display: "flex", gap: "10px" }}>
                <div
                    className={`status-filter-container ${
                        selectedId === "all" && "selected-container"
                    }`}
                    onClick={() => setSelectedId("all")}
                >
                    <div
                        className={`status-filter-text ${
                            selectedId === "all" && "selected-text"
                        }`}
                    >
                        All
                    </div>
                </div>
                <div
                    className={`status-filter-container ${
                        selectedId === "good" && "selected-container"
                    }`}
                    onClick={() => setSelectedId("good")}
                >
                    <EllipseIcon color="#0FC000" />
                    <div
                        className={`status-filter-text ${
                            selectedId === "good" && "selected-text"
                        }`}
                    >
                        Good
                    </div>
                </div>
                <div
                    className={`status-filter-container ${
                        selectedId === "needs_improvement" &&
                        "selected-container"
                    }`}
                    onClick={() => setSelectedId("needs_improvement")}
                >
                    <EllipseIcon color="#ED7717" />
                    <div
                        className={`status-filter-text ${
                            selectedId === "needs_improvement" &&
                            "selected-text"
                        }`}
                    >
                        Needs Improvement
                    </div>
                </div>
                <div
                    className={`status-filter-container ${
                        selectedId === "poor" && "selected-container"
                    }`}
                    onClick={() => setSelectedId("poor")}
                >
                    <EllipseIcon color="#E0212E" />
                    <div
                        className={`status-filter-text ${
                            selectedId === "poor" && "selected-text"
                        }`}
                    >
                        Poor
                    </div>
                </div>
                <div>
                    <DatePicker
                        label="From"
                        maxDate={new Date()}
                        value={fromDate}
                        onChange={(value: any) => setFromDate(value)}
                    />
                </div>
                <div>
                    <DatePicker
                        label="To"
                        maxDate={new Date()}
                        value={toDate}
                        onChange={(value: any) => setToDate(value)}
                    />
                </div>
                <div>
                    <Button
                        onClick={applyFilters}
                        text="Filter"
                        width="auto"
                        fontSize={16}
                        style={{ padding: "18px 30px" }}
                    />
                </div>
            </div>
            {loading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ display: "flex", alignSelf: "flex-start" }}>
                    <Timeline>
                        {filteredData?.map((d: any, index: number) => {
                            return (
                                <TimelineItem key={index}>
                                    <TimelineSeparator>
                                        <TimelineDot
                                            style={{
                                                background: COLORS.common.brand,
                                            }}
                                        />
                                        {filteredData?.length - 1 !== index && (
                                            <TimelineConnector
                                                style={{
                                                    background:
                                                        COLORS.common.brand,
                                                }}
                                            />
                                        )}
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <StyledTimeline
                                            status={d.quick_results}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "10px",
                                                }}
                                            >
                                                <div className="status-tag">
                                                    {d.quick_results}
                                                </div>
                                                <div className="date-text">
                                                    {format(
                                                        parseISO(d.date),
                                                        "MMM d, yyyy"
                                                    )}
                                                </div>
                                            </div>
                                            <div className="message-text">
                                                {d.message ?? ""}
                                            </div>
                                        </StyledTimeline>
                                    </TimelineContent>
                                </TimelineItem>
                            );
                        })}
                    </Timeline>
                </div>
            )}

            <Pagination
                style={{ display: "flex", justifyContent: "center" }}
                count={data?.pagination?.last_page ?? 1}
                onChange={(event, value) => {
                    setCurrentPage(value);
                }}
            />
        </StyledAlertLogComponent>
    );
};

const StyledAlertLogComponent = styled.div`
	display: flex;
	flex-direction: column;
	height: 80vh;

	.status-filter-container {
		display: flex;
		padding: 8px 30px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		border-radius: 50px;
		border: 1px solid #cdcdcd;
		background: rgba(205, 205, 205, 0.3);
	}

	.status-filter-text {
		color: #333;
		text-align: center;
		font-family: Lato;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	.selected-container {
		border-radius: 50px;
		background: #651bdc;
	}

	.selected-text {
		color: #fff;
		text-align: center;
		font-family: Lato;
		font-size: 13px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
`;

const StyledTimeline = styled.div<{ status: string }>`
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex: 1;
	text-wrap: nowrap;

	.status-tag {
		border-radius: 20px;
		background: ${(props) =>
			props.status === "good"
				? "rgba(15, 192, 0, 0.2)"
				: props.status === "poor"
				? "rgba(255, 51, 51, 0.2)"
				: "rgba(237, 119, 23, 0.2)"};

		color: ${(props) =>
			props.status === "good"
				? "#0FC000"
				: props.status === "poor"
				? "#E0212E"
				: "#ED7717"};
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: capitalize;

		padding: 0px 10px;
		display: flex;
		align-items: center;
	}

	.date-text {
		display: flex;
		flex: 1;
		color: #616876;
		font-variant-numeric: lining-nums proportional-nums;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px; /* 166.667% */
	}

	.message-text {
		color: #616876;
		font-variant-numeric: lining-nums proportional-nums;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 17px; /* 141.667% */
	}
`;
