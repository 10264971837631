import { Icon } from "../common/icons-props";

export const IndiaIcon: Icon = (props) => {
	const { width, height, color, isSelected } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="21"
			height="16"
			viewBox="0 0 21 16"
			fill="none"
		>
			<g clip-path="url(#clip0_244_2216)">
				<path
					d="M0 0.505615H20.0475V5.5175H0V0.505615Z"
					fill="#FF9933"
				/>
				<path d="M0 5.51733H20.0475V10.5292H0V5.51733Z" fill="white" />
				<path
					d="M0 10.5295H20.0475V15.5414H0V10.5295Z"
					fill="#128807"
				/>
				<path
					d="M10.0262 10.0286C11.1334 10.0286 12.031 9.13099 12.031 8.0238C12.031 6.9166 11.1334 6.01904 10.0262 6.01904C8.91904 6.01904 8.02148 6.9166 8.02148 8.0238C8.02148 9.13099 8.91904 10.0286 10.0262 10.0286Z"
					fill="#000088"
				/>
				<path
					d="M10.0276 9.7781C10.9964 9.7781 11.7818 8.99273 11.7818 8.02394C11.7818 7.05514 10.9964 6.26978 10.0276 6.26978C9.0588 6.26978 8.27344 7.05514 8.27344 8.02394C8.27344 8.99273 9.0588 9.7781 10.0276 9.7781Z"
					fill="white"
				/>
				<path
					d="M10.0266 8.37476C10.2204 8.37476 10.3774 8.21769 10.3774 8.02393C10.3774 7.83017 10.2204 7.6731 10.0266 7.6731C9.83285 7.6731 9.67578 7.83017 9.67578 8.02393C9.67578 8.21769 9.83285 8.37476 10.0266 8.37476Z"
					fill="#000088"
				/>
				<path
					d="M11.7378 8.34105C11.7872 8.34755 11.8325 8.31278 11.839 8.26338C11.8455 8.21399 11.8107 8.16867 11.7613 8.16217C11.7119 8.15566 11.6666 8.19044 11.6601 8.23983C11.6536 8.28923 11.6884 8.33455 11.7378 8.34105Z"
					fill="#000088"
				/>
				<path
					d="M10.0113 9.7761L10.0715 8.7236L10.0113 8.22241L9.95117 8.7236L10.0113 9.7761Z"
					fill="#000088"
				/>
				<path
					d="M11.6002 8.7769C11.6463 8.79597 11.699 8.77411 11.7181 8.72808C11.7372 8.68205 11.7153 8.62927 11.6693 8.61021C11.6233 8.59114 11.5705 8.613 11.5514 8.65903C11.5323 8.70506 11.5542 8.75783 11.6002 8.7769Z"
					fill="#000088"
				/>
				<path
					d="M9.55636 9.71626L9.88686 8.71519L9.95848 8.21552L9.77067 8.68406L9.55636 9.71626Z"
					fill="#000088"
				/>
				<path
					d="M11.3485 9.1651C11.388 9.19543 11.4446 9.18798 11.4749 9.14845C11.5053 9.10892 11.4978 9.05229 11.4583 9.02196C11.4188 8.99163 11.3621 8.99908 11.3318 9.03861C11.3015 9.07814 11.3089 9.13477 11.3485 9.1651Z"
					fill="#000088"
				/>
				<path
					d="M9.13657 9.54406L9.7149 8.66264L9.91341 8.19853L9.61073 8.6025L9.13657 9.54406Z"
					fill="#000088"
				/>
				<path
					d="M11.0075 9.47194C11.0378 9.51146 11.0944 9.51892 11.134 9.48859C11.1735 9.45826 11.181 9.40163 11.1506 9.3621C11.1203 9.32257 11.0637 9.31512 11.0241 9.34545C10.9846 9.37578 10.9771 9.43241 11.0075 9.47194Z"
					fill="#000088"
				/>
				<path
					d="M8.77203 9.26542L9.55879 8.56372L9.87065 8.16679L9.47373 8.47866L8.77203 9.26542Z"
					fill="#000088"
				/>
				<path
					d="M10.612 9.679C10.631 9.72504 10.6838 9.7469 10.7298 9.72783C10.7759 9.70876 10.7977 9.65599 10.7787 9.60996C10.7596 9.56393 10.7068 9.54207 10.6608 9.56113C10.6148 9.5802 10.5929 9.63297 10.612 9.679Z"
					fill="#000088"
				/>
				<path
					d="M8.5029 8.90046L9.44446 8.4263L9.84843 8.12362L9.38432 8.32213L8.5029 8.90046Z"
					fill="#000088"
				/>
				<path
					d="M10.161 9.77461C10.1675 9.82401 10.2128 9.85878 10.2622 9.85228C10.3116 9.84578 10.3464 9.80046 10.3399 9.75106C10.3334 9.70167 10.2881 9.66689 10.2387 9.6734C10.1893 9.6799 10.1545 9.72522 10.161 9.77461Z"
					fill="#000088"
				/>
				<path
					d="M8.32927 8.47731L9.36147 8.26299L9.83002 8.07518L9.33034 8.14681L8.32927 8.47731Z"
					fill="#000088"
				/>
				<path
					d="M9.70778 9.75144C9.70127 9.80084 9.73605 9.84615 9.78544 9.85266C9.83484 9.85916 9.88016 9.82439 9.88666 9.77499C9.89317 9.72559 9.85839 9.68027 9.809 9.67377C9.7596 9.66727 9.71428 9.70204 9.70778 9.75144Z"
					fill="#000088"
				/>
				<path
					d="M8.27249 8.02401L9.32498 8.08415L9.82617 8.02401L9.32498 7.96387L8.27249 8.02401Z"
					fill="#000088"
				/>
				<path
					d="M9.27339 9.61C9.25433 9.65603 9.27618 9.70881 9.32222 9.72787C9.36825 9.74694 9.42102 9.72508 9.44009 9.67905C9.45915 9.63302 9.43729 9.58025 9.39126 9.56118C9.34523 9.54211 9.29246 9.56397 9.27339 9.61Z"
					fill="#000088"
				/>
				<path
					d="M8.3333 7.57028L9.33437 7.90078L9.83404 7.9724L9.3655 7.78459L8.3333 7.57028Z"
					fill="#000088"
				/>
				<path
					d="M8.8742 9.36066C8.84387 9.40019 8.85133 9.45682 8.89086 9.48715C8.93038 9.51748 8.98702 9.51003 9.01735 9.4705C9.04768 9.43097 9.04022 9.37434 9.00069 9.34401C8.96117 9.31368 8.90453 9.32113 8.8742 9.36066Z"
					fill="#000088"
				/>
				<path
					d="M8.49549 7.14707L9.37691 7.7254L9.84102 7.92391L9.43705 7.62123L8.49549 7.14707Z"
					fill="#000088"
				/>
				<path
					d="M8.58031 9.02066C8.54078 9.05099 8.53333 9.10762 8.56366 9.14715C8.59399 9.18668 8.65062 9.19414 8.69015 9.16381C8.72968 9.13347 8.73713 9.07684 8.7068 9.03732C8.67647 8.99779 8.61984 8.99033 8.58031 9.02066Z"
					fill="#000088"
				/>
				<path
					d="M8.78463 6.78375L9.48633 7.5705L9.88325 7.88237L9.57139 7.48545L8.78463 6.78375Z"
					fill="#000088"
				/>
				<path
					d="M8.35347 8.61124C8.30743 8.6303 8.28558 8.68308 8.30464 8.72911C8.32371 8.77514 8.37648 8.797 8.42251 8.77793C8.46854 8.75886 8.4904 8.70609 8.47134 8.66006C8.45227 8.61403 8.3995 8.59217 8.35347 8.61124Z"
					fill="#000088"
				/>
				<path
					d="M9.13006 6.5051L9.60422 7.44666L9.9069 7.85063L9.70839 7.38651L9.13006 6.5051Z"
					fill="#000088"
				/>
				<path
					d="M8.25224 8.16297C8.20284 8.16947 8.16807 8.21479 8.17457 8.26419C8.18108 8.31358 8.22639 8.34836 8.27579 8.34185C8.32519 8.33535 8.35996 8.29003 8.35346 8.24064C8.34696 8.19124 8.30164 8.15647 8.25224 8.16297Z"
					fill="#000088"
				/>
				<path
					d="M9.54955 6.32952L9.76386 7.36172L9.95167 7.83026L9.88005 7.33059L9.54955 6.32952Z"
					fill="#000088"
				/>
				<path
					d="M8.29934 7.7068C8.24995 7.7003 8.20463 7.73507 8.19813 7.78447C8.19162 7.83387 8.22639 7.87918 8.27579 7.88569C8.32519 7.89219 8.37051 7.85742 8.37701 7.80802C8.38351 7.75862 8.34874 7.7133 8.29934 7.7068Z"
					fill="#000088"
				/>
				<path
					d="M10.0258 6.27175L9.96565 7.32425L10.0258 7.82544L10.0859 7.32425L10.0258 6.27175Z"
					fill="#000088"
				/>
				<path
					d="M8.43882 7.27095C8.39279 7.25188 8.34002 7.27374 8.32095 7.31977C8.30189 7.36581 8.32375 7.41858 8.36978 7.43764C8.41581 7.45671 8.46858 7.43485 8.48765 7.38882C8.50672 7.34279 8.48486 7.29002 8.43882 7.27095Z"
					fill="#000088"
				/>
				<path
					d="M10.4827 6.33159L10.1522 7.33266L10.0806 7.83233L10.2684 7.36379L10.4827 6.33159Z"
					fill="#000088"
				/>
				<path
					d="M8.69256 6.88299C8.65304 6.85266 8.5964 6.86012 8.56607 6.89965C8.53574 6.93917 8.5432 6.99581 8.58273 7.02614C8.62225 7.05647 8.67888 7.04901 8.70922 7.00948C8.73955 6.96996 8.73209 6.91332 8.69256 6.88299Z"
					fill="#000088"
				/>
				<path
					d="M10.9044 6.50404L10.3261 7.38545L10.1276 7.84957L10.4303 7.4456L10.9044 6.50404Z"
					fill="#000088"
				/>
				<path
					d="M9.03354 6.5764C9.00321 6.53688 8.94657 6.52942 8.90705 6.55975C8.86752 6.59008 8.86006 6.64671 8.89039 6.68624C8.92072 6.72577 8.97736 6.73322 9.01688 6.70289C9.05641 6.67256 9.06387 6.61593 9.03354 6.5764Z"
					fill="#000088"
				/>
				<path
					d="M11.269 6.78292L10.4822 7.48462L10.1704 7.88154L10.5673 7.56968L11.269 6.78292Z"
					fill="#000088"
				/>
				<path
					d="M9.431 6.36836C9.41193 6.32233 9.35916 6.30047 9.31313 6.31953C9.2671 6.3386 9.24524 6.39137 9.2643 6.43741C9.28337 6.48344 9.33614 6.5053 9.38217 6.48623C9.42821 6.46716 9.45007 6.41439 9.431 6.36836Z"
					fill="#000088"
				/>
				<path
					d="M11.5401 7.1469L10.5985 7.62107L10.1945 7.92375L10.6587 7.72524L11.5401 7.1469Z"
					fill="#000088"
				/>
				<path
					d="M9.88 6.27275C9.8735 6.22335 9.82818 6.18858 9.77878 6.19508C9.72938 6.20159 9.69461 6.2469 9.70112 6.2963C9.70762 6.3457 9.75294 6.38047 9.80233 6.37397C9.85173 6.36746 9.8865 6.32215 9.88 6.27275Z"
					fill="#000088"
				/>
				<path
					d="M11.7117 7.57006L10.6795 7.78437L10.211 7.97218L10.7107 7.90056L11.7117 7.57006Z"
					fill="#000088"
				/>
				<path
					d="M10.3391 6.29568C10.3456 6.24628 10.3108 6.20097 10.2614 6.19446C10.212 6.18796 10.1667 6.22273 10.1602 6.27213C10.1537 6.32153 10.1885 6.36684 10.2379 6.37335C10.2873 6.37985 10.3326 6.34508 10.3391 6.29568Z"
					fill="#000088"
				/>
				<path
					d="M11.7744 8.02311L10.7219 7.96297L10.2207 8.02311L10.7219 8.08325L11.7744 8.02311Z"
					fill="#000088"
				/>
				<path
					d="M10.7754 6.43663C10.7945 6.3906 10.7726 6.33782 10.7266 6.31876C10.6806 6.29969 10.6278 6.32155 10.6087 6.36758C10.5897 6.41361 10.6115 6.46638 10.6576 6.48545C10.7036 6.50452 10.7564 6.48266 10.7754 6.43663Z"
					fill="#000088"
				/>
				<path
					d="M11.7155 8.47635L10.7145 8.14585L10.2148 8.07423L10.6833 8.26204L11.7155 8.47635Z"
					fill="#000088"
				/>
				<path
					d="M11.1707 6.68817C11.201 6.64864 11.1936 6.59201 11.1541 6.56168C11.1145 6.53135 11.0579 6.5388 11.0276 6.57833C10.9972 6.61786 11.0047 6.67449 11.0442 6.70482C11.0838 6.73515 11.1404 6.7277 11.1707 6.68817Z"
					fill="#000088"
				/>
				<path
					d="M11.5494 8.90176L10.668 8.32343L10.2039 8.12492L10.6079 8.4276L11.5494 8.90176Z"
					fill="#000088"
				/>
				<path
					d="M11.4627 7.02792C11.5022 6.99759 11.5096 6.94096 11.4793 6.90143C11.449 6.8619 11.3923 6.85445 11.3528 6.88478C11.3133 6.91511 11.3058 6.97174 11.3362 7.01127C11.3665 7.0508 11.4231 7.05825 11.4627 7.02792Z"
					fill="#000088"
				/>
				<path
					d="M11.2583 9.26483L10.5566 8.47808L10.1597 8.16621L10.4716 8.56313L11.2583 9.26483Z"
					fill="#000088"
				/>
				<path
					d="M11.6895 7.43661C11.7355 7.41755 11.7574 7.36477 11.7383 7.31874C11.7193 7.27271 11.6665 7.25085 11.6205 7.26992C11.5744 7.28899 11.5526 7.34176 11.5716 7.38779C11.5907 7.43382 11.6435 7.45568 11.6895 7.43661Z"
					fill="#000088"
				/>
				<path
					d="M10.9129 9.54275L10.4387 8.60119L10.1361 8.19722L10.3346 8.66134L10.9129 9.54275Z"
					fill="#000088"
				/>
				<path
					d="M11.7907 7.88488C11.8401 7.87838 11.8749 7.83306 11.8684 7.78367C11.8619 7.73427 11.8166 7.69949 11.7672 7.706C11.7178 7.7125 11.683 7.75782 11.6895 7.80722C11.696 7.85661 11.7413 7.89139 11.7907 7.88488Z"
					fill="#000088"
				/>
				<path
					d="M10.4934 9.71833L10.2791 8.68613L10.0913 8.21759L10.1629 8.71727L10.4934 9.71833Z"
					fill="#000088"
				/>
			</g>
			<defs>
				<clipPath id="clip0_244_2216">
					<rect
						width="20.0475"
						height="15.0357"
						fill="white"
						transform="translate(0 0.505615)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
