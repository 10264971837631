import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	TooltipItem,
} from "chart.js";
import { useApi } from "../../../useApi";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

interface PerformanceData {
	date: string;
	quick_results: {
		pwa_score: number;
		seo_score: number;
		performance_score: number;
		accessibility_score: number;
		best_practices_score: number;
	};
}

interface Props {
	performance_data: PerformanceData[];
	id: number;
	fileName?: string;
}

const performanceDatas = [
	{
		date: "2024-03-12",
		quick_results: {
			pwa_score: 0.29,
			seo_score: 0.75,
			performance_score: 0.36,
			accessibility_score: 0.76,
			best_practices_score: 0.96,
		},
	},
	{
		date: "2024-03-13",
		quick_results: {
			pwa_score: 0.31,
			seo_score: 0.76,
			performance_score: 0.37,
			accessibility_score: 0.8,
			best_practices_score: 0.92,
		},
	},
	{
		date: "2024-03-14",
		quick_results: {
			pwa_score: 0.31,
			seo_score: 0.76,
			performance_score: 0.37,
			accessibility_score: 0.8,
			best_practices_score: 0.92,
		},
	},
	{
		date: "2024-03-15",
		quick_results: {
			pwa_score: 0.31,
			seo_score: 0.76,
			performance_score: 0.37,
			accessibility_score: 0.8,
			best_practices_score: 0.92,
		},
	},
	{
		date: "2024-03-16",
		quick_results: {
			pwa_score: 0.31,
			seo_score: 0.76,
			performance_score: 0.37,
			accessibility_score: 0.8,
			best_practices_score: 0.92,
		},
	},
	// Add more data points as needed
];

export const PerformanceChart: React.FC<Props> = ({ id, fileName }) => {
	const {
		data: performanceresponse,
		error,
		loading,
		fetchData,
	} = useApi<Props>({
		method: "get",
		url: `/score_recors/${id}`,
	});

	const dates = performanceresponse?.performance_data?.map(
		(data) => data.date
	);

	useEffect(() => {
		fetchData();
	}, [fileName]);
	const datasets = [
		{
			label: "Performance Score",
			data: performanceresponse?.performance_data?.map(
				(data) => data.quick_results.performance_score
			),
			borderColor: "#0FC000",
			backgroundColor: "#0FC000",
		},
		{
			label: "Accessibility Score",
			data: performanceresponse?.performance_data?.map(
				(data) => data.quick_results.accessibility_score
			),
			borderColor: "#651BDC",
			backgroundColor: "#651BDC",
		},
		{
			label: "Best Practices Score",
			data: performanceresponse?.performance_data?.map(
				(data) => data.quick_results.best_practices_score
			),
			borderColor: "#0494A0",
			backgroundColor: "#0494A0",
		},
		{
			label: "SEO Score",
			data: performanceresponse?.performance_data?.map(
				(data) => data.quick_results.seo_score
			),
			borderColor: "#ED7717",
			backgroundColor: "#ED7717",
		},
		{
			label: "PWA Score",
			data: performanceresponse?.performance_data?.map(
				(data) => data.quick_results.pwa_score
			),
			borderColor: "#FF3333",
			backgroundColor: "#FF3333",
		},

		// Add other scores (performance, accessibility, best practices) following the same pattern
	];

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				max: 100,
				ticks: {
					stepSize: 10,
				},
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					// This function is called when the tooltip is about to be shown
					beforeBody: (tooltipItems: TooltipItem<"line">[]) => {
						const dataIndex = tooltipItems[0].dataIndex;
						const data =
							performanceresponse?.performance_data?.[dataIndex]
								.quick_results;
						return [
							`PWA: ${data?.pwa_score ?? 0}%`,
							`SEO: ${data?.seo_score ?? 0}%`,
							`Performance: ${data?.performance_score ?? 0}%`,
							`Accessibility: ${data?.accessibility_score ?? 0}%`,
							`Best Practices: ${
								data?.best_practices_score ?? 0
							}%`,
						];
					},
				},
			},
		},
	};

	const data = {
		labels: dates,
		datasets,
	};

	return <Line options={options} data={data} />;
};
