import React from "react";
import styled from "styled-components";

interface ErrorProps {
	message: string;
}

const ErrorMessage = styled.div`
	color: #ff0000; // Red color for errors
	background-color: #fff1f1; // Light red background
	padding: 16px;
	border-radius: 8px;
	margin: 16px 0;
	border: 1px solid #ffcccc; // Light red border
`;

export const Error: React.FC<ErrorProps> = ({ message }) => {
	return <ErrorMessage>{message}</ErrorMessage>;
};
