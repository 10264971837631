import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TitleLarge, TitleSmall } from "../../common/Typography";
import { Input } from "../../components/Input";
import { UploadFileInput } from "../AddMonitorScreen/UploadFileInput";
import { MultipleUrlInput } from "../AddMonitorScreen/MultipleUrlInput";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import InputWithDropdown from "./InputWithDropdown";
import CustomSliderWrapper, {
	CustomSliderWrapperProps,
} from "../AddMonitorScreen/CustomSliderWrapper";
import { Button } from "../../components/Button";
import { COLORS } from "../../common/Theme";
import {
	deviceOptions,
	frequencyOptions,
	locationOptions,
	sliderOptions,
} from "../AddMonitorScreen/SingleMonitor";
import { useApi } from "../../useApi";
import { useUpdateMonitorApi } from "./useUpdateMonitorApi";

export const EditMonitorModal = (props: {
	open: boolean;
	uuid: string;
	monitorName: string;
	sliderValues: CustomSliderWrapperProps[];
	selectedDeviceId: string;
	url: string;
	selectedTagId: string;
	selectedFrequencyId: number;
	onClose: () => void;
	fetchDashboardData: () => Promise<void>;
}) => {
	const [selectedDevice, setSelectedDevice] = useState<DropDownOption | null>(
		deviceOptions.find((option) => option.selected) || null
	);
	// // Initial extraction of protocol and rest of the URL
	// const urlProtocolRegex = /^(https?):\/\/(.*)$/;
	// const urlMatch = props.url.match(urlProtocolRegex);
	// const initialProtocol = urlMatch ? urlMatch[1] : 'https';
	// const initialUrl = urlMatch ? urlMatch[2] : props.url;

	// const [selectedProtocol, setSelectedProtocol] = useState(initialProtocol);
	// const [urlWithoutProtocol, setUrlWithoutProtocol] = useState(initialUrl);
	// const protocolOptions = [
	// 	{ id: 'https', value: 'https', label: 'HTTPS' , selected: selectedProtocol === 'https'  },
	// 	{ id: 'http', value: 'http', label: 'HTTP', selected: selectedProtocol === 'http' }
	// ];
	
	  
	const [selectedLocation, setSelectedLocation] =
		useState<DropDownOption | null>(
			locationOptions.find((option) => option.selected) || null
		);

	const [selectedFrequency, setSelectedFrequency] =
		useState<DropDownOption | null>(
			frequencyOptions.find((option) => option.selected) || null
		);
	const [url, setUrl] = useState<string>(props.url);

	const [monitorName, setMonitorName] = useState(props.monitorName);

	const [sliderValues, setSliderValues] = useState<
		CustomSliderWrapperProps[]
	>([]);

	const [selectedTag, setSelectedTag] = useState(props.selectedTagId);

	const [tagOptions, setTagOptions] = useState<DropDownOption[]>([]);

	const {
		data: tagData,
		error: tagError,
		loading: tagLoading,
	} = useApi<any>({
		method: "get",
		url: "/tags",
	});

	const [thisDeviceOptions, setthisDeviceOptions] = useState<
		DropDownOption[]
	>([]);
	const [thisFrequencyOptions, setThisFrequencyOptions] = useState<
		DropDownOption[]
	>([]);

	useEffect(() => {
		console.log("dev setting");

		setthisDeviceOptions(
			deviceOptions?.map((deviceOption) => {
				if (deviceOption.id === props.selectedDeviceId.toLowerCase()) {
					setSelectedDevice(deviceOption);
					return {
						...deviceOption,
						selected: true,
					};
				}
				return deviceOption;
			})
		);

		setSliderValues(
			sliderOptions.map((so) => {
				return {
					...so,
					//@ts-ignore
					value: props.sliderValues?.[so.id],
				};
			})
		);

		setThisFrequencyOptions(
			frequencyOptions.map((fo) => {
				if (fo.id === "weekly" && props.selectedFrequencyId === 7) {
					setSelectedFrequency(fo);
					return {
						...fo,
						selected: true,
					};
				}

				if (fo.id === "monthly" && props.selectedFrequencyId !== 7) {
					setSelectedFrequency(fo);
					return {
						...fo,
						selected: true,
					};
				}

				return fo;
			})
		);
	}, [props.open]);

	console.log("dev editmonitormodal", {
		thisDeviceOptions,
		props,
		selectedFrequency,
		selectedDevice,
		thisFrequencyOptions,
		slider: props.sliderValues,
		sliderValues,
	});

	useEffect(() => {
		console.log("dev tagdata", tagData);
		if (tagData) {
			const tempTagOptions = tagData?.map((tag: any) => {
				if (props.selectedTagId === tag?.name) {
					setSelectedTag(tag?.name);
				}
				return {
					id: tag?.name ?? "",
					value: tag?.name ?? "",
					selected: props.selectedTagId === tag?.name,
				};
			});
			setTagOptions(tempTagOptions);
		}
	}, [tagData]);

	//const fullUrl = `${selectedProtocol}://${urlWithoutProtocol}`;

	const {
		fetchData: update,
		loading: updateLoading,
		data: updateData,
		error: updateError,

	} = useUpdateMonitorApi({
		uuid: props.uuid,
		name: monitorName,
		device: selectedDevice?.value ?? "",
		url: url,
		sliderValues: sliderValues,
		frequency: selectedFrequency?.value ?? "",
		tag: selectedTag,
		onClose: props.onClose,
		fetchDashboardData: props.fetchDashboardData,
	});

	return (
		<>
			<ContentSection>
				<ContentWrapper>
					<TitleLarge
						color={COLORS.common.black}
						style={{ fontSize: "28px" }}
					>
						Add URL Monitor
					</TitleLarge>
					<TitleSmall color="#959595" style={{ fontSize: "14px" }}>
						You can add a monitor and threshold score to set alerts,
						get notified, and track history.
					</TitleSmall>
					<Input
						placeHolder="Monitor Name*"
						fontSize="16px"
						inputValue={monitorName}
						onInputChange={setMonitorName}
					/>
					{/* <Dropdown
					options={protocolOptions}
					placeholder="Protocol*"
					onChange={(option) => setSelectedProtocol(option.value)}
					width="256px"
					/>

 */}

					<Input
						placeHolder="URL (http://www.example.com)*"
						inputValue={url}
						onInputChange={(value) => setUrl(value)}
						fontSize="16px"
					/>

					<DropdownSection>
						<Dropdown
							options={thisDeviceOptions}
							placeholder="Device*"
							onChange={setSelectedDevice}
							width="256px"
						/>
						<Dropdown
							options={locationOptions}
							placeholder="Location*"
							onChange={setSelectedLocation}
							width="256px"
						/>
					</DropdownSection>
					<SomeOtherSection>
						<InputWithDropdown
							onInputChange={(input) => setSelectedTag(input)}
							options={tagOptions}
							defaultValue={props.selectedTagId}
						/>
						<Dropdown
							options={thisFrequencyOptions}
							placeholder="Check Weekly Once*"
							onChange={setSelectedFrequency}
							width="256px"
						/>
					</SomeOtherSection>
				</ContentWrapper>
				<SlidersAndFinishSection>
					{sliderValues.map((sliderValue, index) => (
						<CustomSliderWrapper
							key={sliderValue.id}
							id={sliderValue.id}
							label={sliderValue.label}
							value={sliderValue.value}
							onChange={(
								id: string,
								value: number,
								label: string
							) => {
								const filteredSliderValues = sliderValues.map(
									(sv) => {
										if (sv.id !== id) {
											return sv;
										} else {
											return {
												id,
												label,
												value,
											};
										}
									}
								);

								setSliderValues(filteredSliderValues);
							}}
						/>
					))}
					<TitleSmall color="#959595" style={{ fontSize: "14px" }}>
						You will be notified when one or more scores drops below
						the threshold value through email.
					</TitleSmall>
					<FinishButton style={{ marginTop: "30px" }}>
						<Button
							text="Cancel"
							size="small"
							buttonType="inverse"
							fontSize={16}
							onClick={() => {
								props.onClose();
							}}
						/>
						<Button
							isLoading={updateLoading}
							text={"Update"}
							size="small"
							buttonType="primary"
							width="115px"
							fontSize={16}
							disabled={updateLoading}
							onClick={() => {
								update();
								// notify(); // Show success toast after update
							}}
						/>
					</FinishButton>
				</SlidersAndFinishSection>
			</ContentSection>
		</>
	);
};

const ContentSection = styled.div`
	display: flex;
	padding: 40px;
	margin: 50px;
	border-radius: 20px;
	background: ${COLORS.common.white};
	box-shadow: 0px -0.055px 2.684px 0px rgba(0, 0, 0, 0.03),
		0px -0.133px 6.451px 0px rgba(0, 0, 0, 0.04),
		0px -0.25px 12.146px 0px rgba(0, 0, 0, 0.05),
		0px -0.447px 21.666px 0px rgba(0, 0, 0, 0.05),
		0px -0.836px 40.524px 0px rgba(0, 0, 0, 0.06),
		0px -2px 97px 0px rgba(0, 0, 0, 0.09);
`;

const ContentWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 10px;
	gap: 30px;
`;

const DropdownSection = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
`;

const SlidersAndFinishSection = styled.div`
	display: flex;
	flex: 1;
	padding: 10px;
	flex-direction: column;
	justify-content: space-evenly;
`;

const FinishButton = styled.div`
	display: flex;
	width: 30px;
	align-self: flex-end;
	justify-content: flex-end;
`;

const SomeOtherSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
