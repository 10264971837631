import React from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { CalculateIcon } from "../../../icons/CalculateIcon";

export const CalculateComponent = (props: { onClick: () => void }) => {
	return (
		<StyledCalculateComponent>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: "20px",
					padding: "20px",
				}}
			>
				<CalculateIcon />
				<div className="calculate-text">
					Values are estimated and may vary. See what you can do to
					increase the performance score.
				</div>
			</div>
			<div style={{ display: "flex", alignItems: "center" }}>
				<Button
					onClick={props.onClick}
					text={"Calculate"}
					buttonType="inverse"
					width="auto"
					fontSize={20}
					className="calc-button"
				/>
			</div>
		</StyledCalculateComponent>
	);
};

export const StyledCalculateComponent = styled.div`
	border-radius: 10px;
	background: #fff;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin: 10px;
	margin-top: 20px;

	.calculate-text {
		color: #333;
		font-family: Lato;
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.calc-button {
		border-radius: 5px;
		border: 1px solid #651bdc;
		background: #fff;
		display: inline-flex;
		padding: 10px 30px;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}
`;
