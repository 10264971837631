import { Icon } from "../common/icons-props";

export const DashboardIcon: Icon = (props) => {
	const { width, height, color, isSelected } = props;

	return isSelected ? (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
		>
			<path
				d="M13.125 7.5C11.2708 7.5 9.45824 8.04983 7.91653 9.07997C6.37482 10.1101 5.17321 11.5743 4.46363 13.2873C3.75406 15.0004 3.56841 16.8854 3.93014 18.704C4.29188 20.5225 5.18476 22.193 6.49588 23.5041C7.807 24.8152 9.47746 25.7081 11.296 26.0699C13.1146 26.4316 14.9996 26.2459 16.7127 25.5364C18.4257 24.8268 19.8899 23.6252 20.92 22.0835C21.9502 20.5418 22.5 18.7292 22.5 16.875H13.125V7.5Z"
				fill="white"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.875 13.125H26.25C26.25 10.6386 25.2623 8.25403 23.5041 6.49587C21.746 4.73772 19.3614 3.75 16.875 3.75V13.125Z"
				fill="white"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	) : (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
		>
			<path
				d="M13.125 7.5C11.2708 7.5 9.45824 8.04983 7.91653 9.07997C6.37482 10.1101 5.17321 11.5743 4.46363 13.2873C3.75406 15.0004 3.56841 16.8854 3.93014 18.704C4.29188 20.5225 5.18476 22.193 6.49588 23.5041C7.807 24.8152 9.47746 25.7081 11.296 26.0699C13.1146 26.4316 14.9996 26.2459 16.7127 25.5364C18.4257 24.8268 19.8899 23.6252 20.92 22.0835C21.9502 20.5418 22.5 18.7292 22.5 16.875H13.125V7.5Z"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.875 13.125H26.25C26.25 10.6386 25.2623 8.25403 23.5041 6.49587C21.746 4.73772 19.3614 3.75 16.875 3.75V13.125Z"
				stroke="white"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
