import React from "react";
import styled from "styled-components";
import { WarningIcon } from "../../../icons/WarningIcon";
import { Button } from "../../../components/Button";

export const WarningComponent = () => {
	return (
		<StyledWarningComponent>
			<div>
				<WarningIcon />
			</div>
			<div className="warning-text">Warning!</div>
			<div className="warning-desc-text">
				Your Performance Score is dropped to poor. Fix your page speed
				issues now
			</div>
			<div>
				<Button
					fontSize={16}
					width="100%"
					text={"Schedule A Consultation"}
				/>
			</div>
		</StyledWarningComponent>
	);
};

export const StyledWarningComponent = styled.div`
	display: flex;
	width: 25%;
	height: 293px;
	padding: 30px 50px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	flex-shrink: 0;
	border-radius: 20px;
	background: rgba(221, 99, 110, 0.1);
	margin: 20px;
	flex-wrap: wrap;

	.warning-text {
		color: #dd636e;
		text-align: center;
		font-family: Lato;
		font-size: 33.645px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.warning-desc-text {
		color: #000;
		text-align: center;
		font-family: Lato;
		font-size: 19.626px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 27.477px */
	}
`;

export const StyledWarningShimmerComponent = styled.div`
	display: flex;
	width: 30%;
	height: 293px;
	padding: 30px 50px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	flex-shrink: 0;
	border-radius: 20px;
	background: #fff;
	margin: 20px;
`;
