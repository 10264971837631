import { Icon } from "../common/icons-props";

export const RunIcon: Icon = (props) => {
	const { width, height, color, isSelected } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="11"
			height="13"
			viewBox="0 0 11 13"
			fill="none"
		>
			<path
				d="M1 2.26921C1 1.69854 1.61133 1.33721 2.11133 1.61187L9.80467 5.84321C9.92242 5.9079 10.0206 6.00303 10.089 6.11867C10.1574 6.2343 10.1935 6.36619 10.1935 6.50054C10.1935 6.63489 10.1574 6.76678 10.089 6.88241C10.0206 6.99805 9.92242 7.09318 9.80467 7.15787L2.11133 11.3892C1.99712 11.452 1.86852 11.4839 1.73821 11.4819C1.60789 11.4798 1.48036 11.4439 1.36818 11.3775C1.256 11.3112 1.16305 11.2167 1.09849 11.1035C1.03392 10.9903 0.99998 10.8622 1 10.7319V2.26921Z"
				fill="#651BDC"
				stroke="#651BDC"
				stroke-width="1.5"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};
