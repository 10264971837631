// Modal.tsx
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Define props interface
interface MenuProps {
	width: string;
	
}

export const Menu: React.FC<MenuProps> = ({ width }) => {
    const navigate = useNavigate();
	return (

<div style={{ height: '120px', position: 'relative',padding:" 1px 40px",  background: 'white', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)', display:"flex",alignItems:"center", justifyContent:"space-between" }}>
<div style={{width: 220, height: 40, display:"flex", alignContent:"center", alignItems:"center"}} onClick={() => {
						navigate("/");
					}}>
   <svg width="300" height="30" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M187.599 32.5305C176.932 34.7972 164.932 40.2639 138.932 54.6639C89.7323 81.8638 77.4656 87.5972 63.0656 89.8639L53.8655 91.3305L64.1322 103.464C69.7322 110.264 74.2655 116.13 74.2655 116.53C74.2655 118.93 43.3323 129.064 24.6656 132.664C15.8657 134.397 8.39899 135.997 8.13226 136.397C6.93231 137.464 14.1323 152.131 20.2656 161.331C33.0656 180.664 54.1322 195.597 76.5323 201.464C82.5323 202.931 93.8655 204.531 103.332 204.931C119.066 205.731 119.599 205.864 117.466 208.264C114.132 211.864 102.666 216.797 93.1989 218.797C88.6656 219.731 84.9323 221.197 84.9323 222.131C84.9323 225.197 103.866 243.997 113.466 250.397C134.399 264.397 155.332 270.131 181.466 268.931C211.066 267.731 238.932 256.397 260.266 236.931L266.799 231.064L258.932 232.131C240.666 234.664 226.132 229.331 199.732 210.797C175.066 193.464 166.799 188.397 159.466 185.997C155.866 184.797 152.932 183.464 152.932 182.931C152.932 182.531 155.999 178.931 159.866 175.064C163.599 171.197 166.532 167.864 166.132 167.597C165.905 167.256 159.375 164.782 150.925 161.58L150.918 161.577C149.461 161.025 147.947 160.452 146.399 159.864C135.866 155.997 127.066 152.131 126.666 151.197C125.732 148.797 130.666 140.131 136.799 133.064C148.132 120.264 163.599 112.664 183.599 110.131L196.132 108.53L188.932 105.864C184.932 104.264 181.466 102.397 181.199 101.597C180.532 99.3305 192.932 87.4639 201.066 82.7972C212.532 76.2639 221.199 74.1305 236.932 73.9972C248.799 73.9972 252.666 74.5305 262.266 77.7305C268.532 79.7305 277.866 84.1305 282.932 87.3305C287.999 90.5305 292.532 92.9305 292.799 92.6639C293.066 92.2639 292.266 89.1972 290.932 85.7305C285.999 72.9305 255.866 47.7305 234.266 38.5305C216.532 31.0638 203.066 29.3305 187.599 32.5305Z" fill="#651BDC"/>
</svg>

    </div>
   
    <div style={{display:"flex", gap: "20px", alignItems: "center"}}>
    <div style={{paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10, position: 'relative', cursor:'pointer', background: '#651BDC', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
        <div style={{color: 'white', fontSize: 20, fontFamily: 'Lato', fontWeight: '700', wordWrap: 'break-word'}} onClick={() => {
						navigate("/signup");
					}}>Sign up</div>
    </div>
    <div style={{position: 'relative', color: '#651BDC', fontSize: 20, fontFamily: 'Lato', fontWeight: '700', wordWrap: 'break-word'}}>Features</div>
    <div style={{position: 'relative', color: '#651BDC', fontSize: 20, fontFamily: 'Lato', fontWeight: '700', wordWrap: 'break-word'}}>Pricing</div>
    </div>
    
</div>	);
};
