import styled from "styled-components";
import { MonitorDashboardIcon } from "../../icons/StatusDashboardIcon";
import { CustomSlider } from "../../components/CustomSlider";

export const MonitorDashboardComponent = (props: {
	numberOfMonitors: number;
	urlLimit: number | null; // Added prop for URL limit
}) => {
	return (
		<StyledMonitorDashboardComponent>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div>Monitors</div>
				<div className="dashboard-icon">
					<MonitorDashboardIcon />
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					gap: "10px",
					justifyContent: "space-between",
				}}
			>
				<div className="big-text">{props.numberOfMonitors}</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						gap: "10px",
					}}
				>
					<div style={{ width: "193px" }}>
						<CustomSlider
							displayValue={props.numberOfMonitors ?? 0}
							onChange={(value) => {}}
							min={0}
							max={props.urlLimit ?? 30} // Use the URL limit if provided
							sliderColor="#651BDC"
							disabled={true}
						/>
					</div>
					<div className="small-text">
						{props.urlLimit !== null ? `/${props.urlLimit} monitors` : null}
					</div>
				</div>
			</div>
		</StyledMonitorDashboardComponent>
	);
};

export const StyledMonitorDashboardComponent = styled.div`
	display: flex;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
	flex-direction: column;
	gap: 10px;
	padding: 20px;
	justify-content: center;

	.dashboard-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: rgba(101, 27, 220, 0.1);
		padding: 10px;
	}

	.big-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 72px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.small-text {
		color: #333;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;
