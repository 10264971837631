import { Icon } from "../common/icons-props";

export const UploadFilesIcon: Icon = (props) => {
	const { width, height, color = "white" } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || "40"}
			height={height || "40"}
			viewBox="0 0 40 40"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.6654 12.917C11.1128 12.917 10.5829 13.1365 10.1922 13.5272C9.80152 13.9179 9.58203 14.4478 9.58203 15.0003C9.58203 15.5529 9.80152 16.0828 10.1922 16.4735C10.5829 16.8642 11.1128 17.0837 11.6654 17.0837C12.2179 17.0837 12.7478 16.8642 13.1385 16.4735C13.5292 16.0828 13.7487 15.5529 13.7487 15.0003C13.7487 14.4478 13.5292 13.9179 13.1385 13.5272C12.7478 13.1365 12.2179 12.917 11.6654 12.917ZM7.08203 15.0003C7.08203 13.7848 7.56492 12.619 8.42446 11.7594C9.284 10.8999 10.4498 10.417 11.6654 10.417C12.8809 10.417 14.0467 10.8999 14.9063 11.7594C15.7658 12.619 16.2487 13.7848 16.2487 15.0003C16.2487 16.2159 15.7658 17.3817 14.9063 18.2412C14.0467 19.1008 12.8809 19.5837 11.6654 19.5837C10.4498 19.5837 9.284 19.1008 8.42446 18.2412C7.56492 17.3817 7.08203 16.2159 7.08203 15.0003ZM16.9087 26.0087C14.8254 28.342 12.652 31.5303 10.3554 35.6137C10.1918 35.9008 9.92119 36.1115 9.60272 36.1996C9.28425 36.2878 8.94383 36.2463 8.65589 36.0841C8.36795 35.922 8.15594 35.6524 8.0662 35.3344C7.97647 35.0164 8.01631 34.6757 8.17703 34.387C10.522 30.2203 12.7954 26.8603 15.0437 24.3437C17.2854 21.832 19.5637 20.0903 21.9304 19.272C24.3437 18.4353 26.752 18.592 29.1287 19.6987C31.462 20.787 33.7237 22.772 35.9687 25.527C36.1782 25.784 36.2771 26.1138 36.2435 26.4437C36.2099 26.7736 36.0466 27.0766 35.7895 27.2862C35.5325 27.4957 35.2028 27.5945 34.8728 27.5609C34.5429 27.5273 34.2399 27.364 34.0304 27.107C31.9104 24.5037 29.9304 22.832 28.072 21.9653C26.2554 21.1187 24.517 21.022 22.7487 21.632C20.9354 22.262 18.997 23.6703 16.9087 26.0087Z"
				fill="#651BDC"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M2.73435 6.06833C3.46956 5.33323 4.34237 4.75014 5.30293 4.35236C6.2635 3.95458 7.29301 3.74989 8.33268 3.75H20.466C20.7975 3.75 21.1155 3.8817 21.3499 4.11612C21.5843 4.35054 21.716 4.66848 21.716 5C21.716 5.33152 21.5843 5.64946 21.3499 5.88388C21.1155 6.1183 20.7975 6.25 20.466 6.25H8.33268C6.89609 6.25 5.51834 6.82068 4.50252 7.8365C3.4867 8.85233 2.91602 10.2301 2.91602 11.6667V28.3333C2.91602 29.7699 3.4867 31.1477 4.50252 32.1635C5.51834 33.1793 6.89609 33.75 8.33268 33.75H28.3327C29.7693 33.75 31.147 33.1793 32.1628 32.1635C33.1787 31.1477 33.7494 29.7699 33.7494 28.3333V20C33.7494 19.6685 33.881 19.3505 34.1155 19.1161C34.3499 18.8817 34.6678 18.75 34.9994 18.75C35.3309 18.75 35.6488 18.8817 35.8832 19.1161C36.1177 19.3505 36.2494 19.6685 36.2494 20V28.3333C36.2494 30.433 35.4153 32.4466 33.9306 33.9313C32.4459 35.4159 30.4323 36.25 28.3327 36.25H8.33268C6.23305 36.25 4.21942 35.4159 2.73475 33.9313C1.25009 32.4466 0.416016 30.433 0.416016 28.3333V11.6667C0.416016 9.56667 1.24935 7.55333 2.73435 6.06833Z"
				fill="#651BDC"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M31.25 0.133789C31.5815 0.133789 31.8995 0.265485 32.1339 0.499906C32.3683 0.734326 32.5 1.05227 32.5 1.38379V14.7171C32.5 15.0486 32.3683 15.3666 32.1339 15.601C31.8995 15.8354 31.5815 15.9671 31.25 15.9671C30.9185 15.9671 30.6005 15.8354 30.3661 15.601C30.1317 15.3666 30 15.0486 30 14.7171V1.38379C30 1.05227 30.1317 0.734326 30.3661 0.499906C30.6005 0.265485 30.9185 0.133789 31.25 0.133789Z"
				fill="#651BDC"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M30.3662 0.500189C30.4823 0.384031 30.6202 0.291885 30.7719 0.229016C30.9236 0.166148 31.0862 0.133789 31.2504 0.133789C31.4146 0.133789 31.5772 0.166148 31.7289 0.229016C31.8806 0.291885 32.0185 0.384031 32.1346 0.500189L37.4679 5.83352C37.6887 6.07048 37.8089 6.38389 37.8032 6.70773C37.7975 7.03156 37.6663 7.34054 37.4373 7.56956C37.2082 7.79858 36.8993 7.92977 36.5754 7.93548C36.2516 7.9412 35.9382 7.82099 35.7012 7.60019L31.2512 3.15019L26.8012 7.60019C26.6868 7.723 26.5488 7.8215 26.3955 7.88982C26.2421 7.95814 26.0766 7.99488 25.9088 7.99784C25.7409 8.0008 25.5742 7.96993 25.4186 7.90706C25.2629 7.84419 25.1215 7.75062 25.0028 7.63192C24.8841 7.51322 24.7906 7.37183 24.7277 7.21619C24.6648 7.06054 24.634 6.89382 24.6369 6.72599C24.6399 6.55815 24.6766 6.39262 24.7449 6.23929C24.8133 6.08596 24.9118 5.94796 25.0346 5.83352L30.3662 0.500189Z"
				fill="#651BDC"
			/>
		</svg>
	);
};
