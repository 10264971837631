import { create } from "zustand";

// Helper functions to get and set cookies with TypeScript type annotations
function getCookie(name: string): string | undefined {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		const part = parts.pop();
		return part ? part.split(";").shift() : undefined;
	}
	return undefined;
}

function setCookie(name: string, value: string, days?: number): void {
	let expires = "";
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

// Define the state and actions for your store
type AppState = {
	token: string; // State to hold the token, initially null or a default value
	setToken: (token: string) => void; // Action to update the token

	url: string;
	setUrl: (url: string) => void;

	userId: number;
	setUserId: (userId: number) => void;

	uuid: string;
	setUuid: (uuid: string) => void;
};

// Create the store using Zustand
export const useAppStore = create<AppState>((set) => ({
	// Initial state
	token: getCookie("token") || "",
	url: "",
	userId: parseInt(getCookie("userId") || "0", 10),
	uuid: "",

	// Action to set the token
	setToken: (token: string) => {
		set({ token });
		setCookie("token", token, 7); // Store in cookies, with an expiry of 7 days
	},
	setUrl: (url) => set({ url }),
	setUserId: (userId: number) => {
		set({ userId });
		setCookie("userId", userId.toString(), 7); // Store in cookies, with an expiry of 7 days
	},
	setUuid: (uuid) => set({ uuid }),
}));
