import styled from "styled-components";
import { MonitorDashboardIcon } from "../../icons/StatusDashboardIcon";
import { StatusDashboardIcon } from "../../icons/MonitorDashboardIcon";
import { EllipseIcon } from "../../icons/EllipseIcon";
import DonutChart from "react-donut-chart";
import { useState } from "react";

export const StatusDashboardComponent = (props: { data: any }) => {
	const statusObject = {
		good: 0,
		needs_improvement: 0,
		poor: 0,
		paused: 0,
	};

	const [statusCount, setStatusCount] = useState(statusObject);

	console.log("statusObject", statusObject);

	props.data?.forEach((d: any) => {
		if (d.status !== "active") {
			statusObject.paused++;
		} else {
			console.log("d", d);
			const s = d.performance_logs?.[0]?.performance_status;
			if (s === "poor") {
				statusObject.poor++;
			} else if (s === "good") {
				statusObject.good++;
			} else if (s === "needs_improvement") {
				statusObject.needs_improvement++;
			} else {
				statusObject.paused++;
			}
		}
	});

	console.log("statusObject after", statusObject);

	return (
		<StyledStatusDashboardComponent>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div>Status</div>
				<div className="dashboard-icon">
					<StatusDashboardIcon />
				</div>
			</div>
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
					}}
				>
					<StatusRowText>
						<EllipseIcon
							className="status-dashboard-icon"
							color="#0FC000"
						/>
						<div className="status-dashboard-text">Good</div>
						<div className="status-dashboard-text-right">
							{statusObject.good}
						</div>
					</StatusRowText>
					<StatusRowText>
						<EllipseIcon
							className="status-dashboard-icon"
							color="#ED7717"
						/>
						<div className="status-dashboard-text">
							Needs Improvement
						</div>
						<div className="status-dashboard-text-right">
							{statusObject.needs_improvement}
						</div>
					</StatusRowText>
					<StatusRowText>
						<EllipseIcon
							className="status-dashboard-icon"
							color="#FF3333"
						/>
						<div className="status-dashboard-text">Poor</div>
						<div className="status-dashboard-text-right">
							{statusObject.poor}
						</div>
					</StatusRowText>
					<StatusRowText>
						<EllipseIcon
							className="status-dashboard-icon"
							color="#959595"
						/>
						<div className="status-dashboard-text">Paused</div>
						<div className="status-dashboard-text-right">
							{statusObject.paused}
						</div>
					</StatusRowText>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						gap: "10px",
						margin: "10px",
					}}
				>
					<DonutChart
						data={[
							{
								label: "",
								value: statusObject.good,
							},
							{
								label: "",
								value: statusObject.needs_improvement,
							},
							{
								label: "",
								value: statusObject.poor,
							},
							{
								label: "",
								value: statusObject.paused,
							},
						]}
						colors={["#0FC000", "#ED7717", "#FF3333", "#959595"]}
						strokeColor="#e0e0e0"
						interactive={false}
						legend={false}
						width={72}
						height={75}
						outerRadius={0.5}
						innerRadius={0.9}
					/>
				</div>
			</div>
		</StyledStatusDashboardComponent>
	);
};

export const StyledStatusDashboardComponent = styled.div`
	display: flex;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
	flex-direction: column;
	gap: 10px;
	padding: 20px;
	justify-content: center;

	.dashboard-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		background-color: rgba(237, 158, 168, 0.1);
		padding: 10px;
	}

	.big-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 72px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.small-text {
		color: #333;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;

export const StatusRowText = styled.div`
	display: flex;
	width: 154px;
	justify-content: space-between;
	align-items: center;
	gap: 10px;

	.status-dashboard-icon {
		display: flex;
		justify-content: center;
	}

	.status-dashboard-text {
		color: #000;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.status-dashboard-text-right {
		color: #000;
		text-align: right;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.alert-dashboard-text-right {
		color: #000;
		text-align: right;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;
