import React from "react";
import { Input } from "../../components/Input";
import { MinusIcon } from "../../icons/MinusIcon";
import { BodyPrimary, BodyPrimaryLink } from "../../common/Typography";
import { COLORS } from "../../common/Theme";
import styled from "styled-components";
import { validateUrl } from "../../common/utils";
import toast from "react-hot-toast";

type MultipleUrlInputProps = {
    urls: string[];
    onUrlsChange: (urls: string[]) => void;
    onUploadClick: () => void;
};

export const MultipleUrlInput: React.FC<MultipleUrlInputProps> = ({ urls, onUrlsChange, onUploadClick }) => {
    const handleInputEnter = (value: string) => {
        const urlList = value.split("\n").map((url) => url.trim()).filter((url) => url);

        for (let url of urlList) {
            if (!validateUrl(url)) {
                toast.error(`Invalid URL: ${url}`);
                return;
            }
        }

        onUrlsChange([...urls, ...urlList]);
    };

    return (
        <Container>
            <Input
                placeHolder="Please enter all the URLs you want to monitor:"
                onEnter={handleInputEnter}
                fontSize="16px"
                multiline={true}
                rows={5}
            />
            <UrlsBoxContainer>
                <UrlsBox urls={urls} onUrlsChange={onUrlsChange} />
            </UrlsBoxContainer>
            <BodyPrimary
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
                color={"#1E1E1E"}
            >
                You can also&nbsp;
                <BodyPrimaryLink color={COLORS.common.brand} onClick={onUploadClick}>
                    upload
                </BodyPrimaryLink>
                &nbsp; your URLs
            </BodyPrimary>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const UrlsBoxContainer = styled.div`
    height: 125px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    margin-top: 10px;
`;

type UrlsBoxProps = {
    urls: string[];
    onUrlsChange: (urls: string[]) => void;
};

export const UrlsBox: React.FC<UrlsBoxProps> = ({ urls, onUrlsChange }) => {
    const handleDeleteItem = (index: number) => {
        const newItems = [...urls];
        newItems.splice(index, 1);
        onUrlsChange(newItems);
    };

    return (
        <>
            {urls.map((item, index) => (
                <UrlItem key={index}>
                    <span>{item}</span>
                    <DeleteButton onClick={() => handleDeleteItem(index)}>
                        <MinusIcon />
                    </DeleteButton>
                </UrlItem>
            ))}
        </>
    );
};

const UrlItem = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DeleteButton = styled.div`
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
`;
