import styled from "styled-components";
import { AlertDashboardIcon } from "../../icons/AlertDashboardIcon";
import { StatusRowText } from "./StatusDashboardComponent";
import { EllipseIcon } from "../../icons/EllipseIcon";
import { useApi } from "../../useApi";

export const AlertDashboardComponent = (props: { data: any }) => {
	const { data } = props;
	return (
		<StyledAlertDashboardComponent>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div>Alerts</div>
				<div className="dashboard-icon">
					<AlertDashboardIcon />
				</div>
			</div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "10px",
				}}
			>
				<StatusRowTextAlerts>
					<div
						style={{
							display: "flex",
							gap: "10px",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<EllipseIcon
							className="status-dashboard-icon"
							color="#0FC000"
						/>
						<div className="status-dashboard-text">
							{data?.last_24_hours ?? 0} Alerts
						</div>
					</div>

					<div className="alert-dashboard-text-right">
						in last 24 hours
					</div>
				</StatusRowTextAlerts>
				<StatusRowTextAlerts>
					<div
						style={{
							display: "flex",
							gap: "10px",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<EllipseIcon
							className="status-dashboard-icon"
							color="#ED7717"
						/>
						<div className="status-dashboard-text">
							{data?.last_7_days ?? 0} Alerts
						</div>
					</div>

					<div className="alert-dashboard-text-right">
						in last 7 days
					</div>
				</StatusRowTextAlerts>
				<StatusRowTextAlerts>
					<div
						style={{
							display: "flex",
							gap: "10px",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<EllipseIcon
							className="status-dashboard-icon"
							color="#FF3333"
						/>
						<div className="status-dashboard-text">
							{data?.last_30_days ?? 0} Alerts
						</div>
					</div>

					<div className="alert-dashboard-text-right">
						in last 30 days
					</div>
				</StatusRowTextAlerts>
			</div>
		</StyledAlertDashboardComponent>
	);
};

export const StyledAlertDashboardComponent = styled.div`
	display: flex;
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
	flex-direction: column;
	padding: 20px;
	justify-content: space-around;

	.dashboard-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 22px;
		height: 20px;
		border-radius: 10px;
		background-color: rgba(15, 192, 0, 0.1);
		padding: 10px;
		align-self: center;
	}

	.big-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 72px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.small-text {
		color: #333;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;

export const StatusRowTextAlerts = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 40px;
	width: 253px;

	.status-dashboard-icon {
		display: flex;
		justify-content: center;
	}

	.status-dashboard-text {
		color: #000;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.status-dashboard-text-right {
		color: #000;
		text-align: right;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}

	.alert-dashboard-text-right {
		color: #000;
		text-align: right;
		font-family: Lato;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;
