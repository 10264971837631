import styled from "styled-components";
import { COLORS, FONTS } from "./Theme";

const addPixels = (value: number) => {
	return value + "px";
};

export const TitleLarge = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.titleLarge.fontWeight};
	font-size: ${addPixels(FONTS.titleLarge.fontSize)};
	line-height: normal;
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const TitleMedium = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.titleMedium.fontWeight};
	font-size: ${addPixels(FONTS.titleMedium.fontSize)};
	line-height: normal;
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const SmallRegular = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.title4.fontWeight};
	font-size: ${addPixels(FONTS.title4.fontSize)};
	line-height: ${addPixels(FONTS.title4.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const TitleRegular = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.titleRegular.fontWeight};
	font-size: ${addPixels(FONTS.titleRegular.fontSize)};
	line-height: normal;
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;
export const TitleSmall = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.titleSmall.fontWeight};
	font-size: ${addPixels(FONTS.titleSmall.fontSize)};
	line-height: "normal";
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const Display = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.display.fontWeight};
	font-size: ${addPixels(FONTS.display.fontSize)};
	line-height: ${addPixels(FONTS.display.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const BodyLarge = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.bodyPrimary.fontWeight};
	font-size: ${addPixels(FONTS.bodyLarge.fontSize)};
	line-height: ${addPixels(FONTS.bodyLarge.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;
export const BodyPrimary = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.bodyPrimary.fontWeight};
	font-size: ${addPixels(FONTS.bodyPrimary.fontSize)};
	line-height: normal;
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;
export const BodySecondary = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.bodySecondary.fontWeight};
	font-size: ${addPixels(FONTS.bodySecondary.fontSize)};
	line-height: ${addPixels(FONTS.bodySecondary.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const BodyPrimaryLink = styled(BodyPrimary)`
	text-decoration: underline;
	color: ${COLORS.content.brand};
	text-decoration-color: ${COLORS.content.brand};
	font-weight: ${FONTS.bodyPrimaryLink.fontWeight};
	font-size: ${addPixels(FONTS.bodyPrimaryLink.fontSize)};
	cursor: pointer;
`;

export const BodySecondaryLink = styled(BodySecondary)`
	text-decoration: underline;
	color: ${COLORS.content.brand};
	text-decoration-color: ${COLORS.content.brand};
	cursor: pointer;
`;

export const BodyCaption = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.caption.fontWeight};
	font-size: ${addPixels(FONTS.caption.fontSize)};
	line-height: ${addPixels(FONTS.caption.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;
export const BodyTiny = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.tiny.fontWeight};
	font-size: ${addPixels(FONTS.tiny.fontSize)};
	line-height: ${addPixels(FONTS.tiny.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const ButtonLarge = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.buttonLarge.fontWeight};
	font-size: ${addPixels(FONTS.buttonLarge.fontSize)};
	line-height: ${addPixels(FONTS.buttonLarge.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;
export const ButtonRegular = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.buttonRegular.fontWeight};
	font-size: ${addPixels(FONTS.buttonRegular.fontSize)};
	line-height: ${addPixels(FONTS.buttonRegular.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const SubHeading = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.subHeading.fontWeight};
	font-size: ${addPixels(FONTS.subHeading.fontSize)};
	line-height: ${addPixels(FONTS.subHeading.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const BodyRegular = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${(props) => props.color || COLORS.text.primary};
	font-weight: ${FONTS.bodyRegular.fontWeight};
	font-size: ${addPixels(FONTS.bodyRegular.fontSize)};
	line-height: ${addPixels(FONTS.bodyRegular.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;

export const Caption = styled.div<{
	numberOfLines?: number;
	color?: string;
}>`
	font-family: Lato;
	color: ${COLORS.text.primary};
	font-weight: ${FONTS.caption.fontWeight};
	font-size: ${addPixels(FONTS.caption.fontSize)};
	line-height: ${addPixels(FONTS.caption.lineHeight)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => props.numberOfLines};
`;
