import styled from "styled-components";
import {
	BodyPrimary,
	BodyPrimaryLink,
	BodyTiny,
	TitleLarge,
	TitleMedium,
	TitleSmall,
} from "../../common/Typography";
import { COLORS } from "../../common/Theme";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuthApi } from "./useAuthApi";
import { Error } from "../../common/Error";
import toast, { Toaster } from "react-hot-toast";
import { useGoogleLogin } from "@react-oauth/google";
import { ContinueWithGoogle } from "./GoogleSignInButton";


export type AuthPageProps = {
	pageType: "login" | "signup" | "forgot-password" | "password-reset";
};

const authPageConfig: Record<AuthPageProps["pageType"], AuthPageConfig> = {
	login: {
		left: {
			imageUrl: "../../Image.png",
			title: "Your One-Stop Analytic Platform to Boost your Page Speed",
			subTitle:
				"AI powered tool that helps you monitor and optimize website performance continuously.",
		},
		right: {
			pageTitle: "Login",
			pageSubTitle: "Enter your credentials to login",
			showPasswordField: true,
			buttonText: "Login",
			footerText: "Don't have an account? ",
			footerButtonText: "Sign Up",
			infoText: "",
		},
	},
	signup: {
		left: {
			imageUrl: "../../Image.png",
			title: "Your One-Stop Analytic Platform to Boost your Page Speed",
			subTitle:
				"AI powered tool that helps you monitor and optimize website performance continuously.",
		},
		right: {
			pageTitle: "Sign Up",
			pageSubTitle: "Please enter your details",
			showPasswordField: true,
			buttonText: "Sign Up",
			footerText: "Already have an account? ",
			footerButtonText: "Login",
			infoText:
				'By clicking the "Sign up" button, you are creating a Cyces Tool account and you agree to Cyces Tool Terms of Service and Privacy Policy',
		},
	},
	"forgot-password": {
		left: {
			imageUrl: "../../Image.png",
			title: "Your One-Stop Analytic Platform to Boost your Page Speed",
			subTitle:
				"AI powered tool that helps you monitor and optimize website performance continuously.",
		},
		right: {
			pageTitle: "Forgot Password",
			pageSubTitle:
				"Enter your E-mail to get link to change a new password.",
			showPasswordField: false,
			buttonText: "Send Link",
			footerText: "Remembered the Password? ",
			footerButtonText: "Login",
			infoText: "",
		},
	},
	"password-reset": {
		left: {
			imageUrl: "../../Image.png",
			title: "Your One-Stop Analytic Platform to Boost your Page Speed",
			subTitle:
				"AI powered tool that helps you monitor and optimize website performance continuously.",
		},
		right: {
			pageTitle: "Reset Password",
			pageSubTitle: "Change a new Password",
			showPasswordField: true,
			buttonText: "Update",
			infoText: "",
			footerText: "Remembered the password,",
			footerButtonText: "Login",
		},
	},
};

type AuthPageConfig = {
	left: {
		imageUrl: string;
		title: string;
		subTitle: string;
	};
	right: {
		pageTitle: string;
		pageSubTitle: string;
		showPasswordField: boolean;
		buttonText: string;
		infoText: string;
		footerText: string;
		footerButtonText: string;
	};
};

export const AuthPage = (props: AuthPageProps) => {
	const { token: tokenFromUrl } = useParams();
	const search = useLocation().search;
	const emailFromUrl = new URLSearchParams(search).get("email");

	console.log("token", tokenFromUrl, emailFromUrl);

	const [email, setEmail] = useState(emailFromUrl || "");
	const [password, setPassword] = useState("");
	const [name, setName] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [googleId, setGoogleId] = useState("");
	const navigate = useNavigate();

	const handleLogin = () => {
        if (validateEmail(email)) {
            // Perform login logic
        } else {
            setErrorMessage('Invalid email address');
        }
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

	const { data, loading, error, fetchData } = useAuthApi(
		!!googleId ? "google" : props.pageType,
		email,
		password,
		name,
		confirmPassword,
		tokenFromUrl,
		googleId
	);

	useEffect(() => {
		console.log("error", error, data);

		if (error) {
			setErrorMessage(error);
		}
	}, [error]);

	const validate = () => {
		if (props.pageType === "login") {
			if (!email.trim() || !password.trim())
				return "Please enter all the fields";
		}

		if (props.pageType === "signup") {
			if (!email.trim() || !password.trim() || !name.trim())
				return "Please enter all the fields";
		}

		if (props.pageType === "forgot-password") {
			if (!email.trim()) return "Please enter all the fields";
		}

		if (props.pageType === "password-reset") {
			if (!password.trim() || !confirmPassword.trim())
				return "Please enter all the fields";
		}
	};

	const onSubmit = () => {
		const err = validate();
		if (err) {
			setErrorMessage(err);
			return;
		}

		fetchData();

		console.log("submit", { email, password });
	};

	const onRedirect = () => {
		setErrorMessage("");

		if (props.pageType === "login") {
			navigate("/signup");
		}

		if (props.pageType === "signup") {
			navigate("/login");
		}

		if (props.pageType === "forgot-password") {
			navigate("/login");
		}

		if (props.pageType === "password-reset") {
			navigate("/login");
		}
	};

	useEffect(() => {
		if (name && email && googleId) {
			fetchData();
		}
	}, [name, email, googleId]);

	const loginG = useGoogleLogin({
		onSuccess: async (codeResponse: any) => {
			toast.loading("Loading..");
			console.log("resp", codeResponse);
			const g = await fetch(
				`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
				{
					headers: {
						Authorization: `Bearer ${codeResponse.access_tokenl}`,
						Accept: "application/json",
					},
				}
			);
			const final = await g.json();
			console.log("final", final);
			setEmail(final.email);
			setName(final.name);
			setGoogleId(final.id);
		},
		onError: (error: any) => {
			console.log("Error", error);
		},
	});

	return (
		<StyledAuthPage>
			<Toaster />
			<div className="left-side">
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img
						src={require("../../Image.png")}
						alt="images"
						width={482}
						height={501}
					/>
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						gap: "10px",
					}}
				>
					<TitleMedium
						color={COLORS.common.white}
						style={{ display: "flex", textAlign: "center" }}
					>
						{authPageConfig[props.pageType].left.title}
					</TitleMedium>
					<TitleSmall color={COLORS.common.white}>
						{authPageConfig[props.pageType].left.subTitle}
					</TitleSmall>
				</div>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "5px",
					}}
				>
					<div className="ellipsis-small"></div>
					<div className="ellipsis-large"></div>
					<div className="ellipsis-small"></div>
				</div>
			</div>
			<div
				style={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
					gap: "20px",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						flex: 1,
						flexDirection: "column",
						justifyContent: "center",
						margin: "50px 100px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							margin: "10px",
							alignItems: "flex-start",
						}}
					>
						<TitleLarge color={COLORS.common.black}>
							{authPageConfig[props.pageType].right.pageTitle}
						</TitleLarge>
						<TitleSmall color="#959595">
							{authPageConfig[props.pageType].right.pageSubTitle}
						</TitleSmall>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "20px",
							alignItems: "flex-start",
							margin: "10px",
						}}
					>
						{props.pageType === "signup" && (
							<Input
								type={"text"}
								inputValue={name}
								onInputChange={setName}
								placeHolder={"Enter Name"}
								style={{
									fontSize: "16px",
									width: "100%",
								}}
							/>
							
						)}

						{props.pageType !== "password-reset" && (
							<Input
								type={"text"}
								inputValue={email}
								onInputChange={setEmail}
								placeHolder={"EMail"}
								
								style={{
									fontSize: "16px",
									width: "100%",
								}}
							/>
						)}

						{authPageConfig[props.pageType].right
							.showPasswordField && (
							<Input
								type={"password"}
								inputValue={password}
								onInputChange={setPassword}
								placeHolder={"Enter Password"}
								style={{
									fontSize: "16px",
									width: "100%",
								}}
							/>
						)}

						{props.pageType === "password-reset" &&
							authPageConfig[props.pageType].right
								.showPasswordField && (
								<Input
									type={"password"}
									inputValue={confirmPassword}
									onInputChange={setConfirmPassword}
									placeHolder={"confirm Password"}
									style={{
										fontSize: "16px",
										width: "100%",
									}}
								/>
							)}
					</div>
					{props.pageType === "login" && (
						<div
							style={{
								display: "flex",
								justifyContent: "flex-start",
								alignItems: "center",
								margin: "15px",
							}}
						>
							<BodyPrimaryLink
								color={COLORS.common.brand}
								style={{
									display: "inline-flex",
									textDecoration: "none",
								}}
								onClick={() => navigate("/forgot-password")}
							>
								Forgot Password?
							</BodyPrimaryLink>
						</div>
					)}
					{errorMessage && <Error message={errorMessage} />}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							margin: "30px 10px 10px",
						}}
					>

						<Button
							text={
								authPageConfig[props.pageType].right.buttonText
							}
							buttonType="primary"
							size="large"
							onClick={onSubmit}
							isLoading={loading}
						/>
					</div>
					{["login", "signup"].includes(props.pageType) && (
						<div
							style={{
								justifyContent: "center",
								justifySelf: "center",
								alignContent: "center",
								display: "flex",
								marginTop: "20px",
								marginBottom: "20px",
								cursor: "pointer",
							}}
						>
							<ContinueWithGoogle
								className="continue-with-google-centre-fixed"
								frameClassName="continue-with-google-instance"
								text="Sign In with Google"
								onClick={loginG}
							/>
						</div>
					)}
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							margin: "15px",
						}}
					>
						<BodyPrimary color={COLORS.common.black}>
							{authPageConfig[props.pageType].right.footerText}
							&nbsp;
							<BodyPrimaryLink
								color={COLORS.common.brand}
								style={{ display: "inline-flex" }}
								onClick={onRedirect}
							>
								{
									authPageConfig[props.pageType].right
										.footerButtonText
								}
							</BodyPrimaryLink>
						</BodyPrimary>
					</div>

					{authPageConfig[props.pageType].right.infoText ? (
						<BodyPrimary
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								margin: "10px",
							}}
							color="#959595"
						>
							{authPageConfig[props.pageType].right.infoText}
						</BodyPrimary>
					) : (
						<></>
					)}
				</div>
				<div
					style={{
						display: "flex",
						margin: "30px",
						justifyContent: "center",
					}}
				>
					<BodyTiny>Privacy Policy | Terms & Conditions</BodyTiny>
				</div>
			</div>
		</StyledAuthPage>
	);
};

export const StyledAuthPage = styled.div`
	display: flex;
	flex: 1;

	.left-side {
		display: flex;
		flex: 1;
		flex-direction: column;
		margin: 10px;
		border-radius: 20px;
		background: linear-gradient(
			208deg,
			#651bdc 0%,
			#9a2c80 33.35%,
			#f66060 68.23%,
			#ffb19b 101.08%
		);
		justify-content: space-evenly;
	}

	.ellipsis-small {
		width: 10px;
		height: 10px;
		border-radius: 10px;
		background-color: #ffffff;
	}

	.ellipsis-large {
		width: 25px;
		height: 10px;
		border-radius: 10px;
		background-color: #ffffff;
	}

	input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginBottom: 12,
        paddingLeft: 8,
    }
    errorText: {
        color: 'red',
        marginBottom: 12,
    },
`;

