import React from "react";
import "./success.css";
import { GreenSuccessIcon } from "../../../icons/GreenSuccessIcon";

export const Good = (props: { performanceScore: number }) => {
	return (
		<div className="good">
			<GreenSuccessIcon className="frame" />
			<div className="text-wrapper">All Good</div>
			<p className="your-performance">
				<span className="span">
					Your Performance Score is&nbsp;&nbsp;
				</span>
				<span className="text-wrapper-2">
					{props.performanceScore}%
				</span>
				<span className="span">
					{" "}
					which recommended as good by Google.
				</span>
			</p>
		</div>
	);
};
