//@ts-nocheck
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { GoogleIcon } from "../../icons/GoogleIcon";

const StyledContinueWithGoogle = styled.div`
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 2px 3px #0000002b, 0px 0px 3px #00000015;
	height: 54px;

	display: flex;
	align-self: center;

	& .frame {
		align-items: flex-start;
		background-color: #ffffff;
		border-radius: 10px;
		display: flex;
		gap: 15px;
		
		padding: 15px 40px;
		position: relative;
	}

	& .google-logo {
		height: 24px;
		position: relative;
		width: 24px;
	}

	& .text-wrapper {
		color: #0000008a;
		font-family: "Roboto-Medium", Helvetica;
		font-size: 20px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: normal;
		margin-top: -1px;
		position: relative;
		white-space: nowrap;
		width: fit-content;
	}
`;

export const ContinueWithGoogle = ({
	className,
	frameClassName,
	googleLogo = "google-logo.svg",
	text = "Continue with Google",
	onClick,
}) => {
	return (
		<StyledContinueWithGoogle
			className={`continue-with-google ${className}`}
			onClick={onClick}
		>
			<div className={`frame ${frameClassName}`}>
				<GoogleIcon className="google-logo" />
				<div className="text-wrapper">{text}</div>
			</div>
		</StyledContinueWithGoogle>
	);
};

ContinueWithGoogle.propTypes = {
	googleLogo: PropTypes.string,
	text: PropTypes.string,
};
