import { useNavigate } from "react-router-dom";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import styled from "styled-components";
import { formatDateString, getRunningTime } from "../../common/utils";
import { InfoIcon } from "../../icons/InfoIcon";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { InfoHoverComponent } from "./InfoHoverComponent";
import { Shimmer } from "../../common/ShimmerComponent";
import { parse } from "date-fns";


export const TableComponent = (props: {
	data: any;
	onPause: (id: string) => void;
	onEdit: (id: string) => void;
	onDelete: (id: string) => void;
	dashboardLoading: boolean;
}) => {
	const navigate = useNavigate();

	const getStatus = (d: any) => {
		if (d.progress_status) {
			return "In progress";
		}

		if (d.status !== "active") {
			return "Paused";
		}

		const performanceIndex = d.performance_logs?.length ?? 1;
		const s =
			d.performance_logs?.[performanceIndex - 1]?.performance_status;

		if (s === "poor") {
			return "Poor";
		}

		if (s === "good") {
			return "Good";
		}

		if (s === "needs_improvement") {
			return "Needs Improvement";
		}

		return "Paused";
	};

	const initializeTableRows = () => {
		return (
			props.data?.map((d: any, index: number) => {
				const tableRow: TableRowType = {
					uuid: d.uuid,
					id: d.id,
					index: index,
					name: d.name,
					url: d.url,
					status: getStatus(d),
					tag: d.tag?.[0]?.name,
					frequency: d.frequency === 7 ? "Weekly" : "Monthly",
					lastUpdated: formatDateString(d.last_checked),
					device: d.devices[0],
					performanceLogs: d.performance_logs?.[0] ?? [],
					runningTime: getRunningTime(
						new Date(d.last_checked),
						new Date()
					)?.toString(),
					onPause: function (id: string): void {},
					onEdit: function (id: string): void {},
					onDelete: function (id: string): void {},
					onClick: function (id: string): void {},
				};
				return tableRow;
			}) ?? []
		);
	};

	const tableRows: TableRowType[] = initializeTableRows();
	// const tableRowOptions: DropDownOption[] = [
	// 	{ id: "pause", value: props.status, selected: true },
	// 	{ id: "edit", value: "Edit", selected: false },
	// 	{ id: "delete", value: "Delete", selected: false },
	// ];
	
	return (
		<StyledTableComponent>
			<div className="table-header">
				<div className="header-text flex-lil">S. No</div>
				<div className="header-text flex-one">Monitor Name</div>
				<div className="header-text flex-two">URL</div>
				<div className="header-text flex-one">Status</div>
				<div className="header-text flex-one">Running Time</div>
				<div className="header-text flex-two center">Tag</div>
				<div className="header-text flex-one">Devices</div>
				<div className="header-text flex-one">Frequency</div>
				<div className="header-text flex-one">Last checked</div>
				<div className="flex-one"></div>
			</div>

			{props.dashboardLoading ? (
				<div>
					<StyledTableRow status="">
						<Shimmer boxes={[{ width: "100%", height: "20px" }]} />
					</StyledTableRow>
					<StyledTableRow status="">
						<Shimmer boxes={[{ width: "100%", height: "20px" }]} />
					</StyledTableRow>
					<StyledTableRow status="">
						<Shimmer boxes={[{ width: "100%", height: "20px" }]} />
					</StyledTableRow>
					<StyledTableRow status="">
						<Shimmer boxes={[{ width: "100%", height: "20px" }]} />
					</StyledTableRow>
					<StyledTableRow status="">
						<Shimmer boxes={[{ width: "100%", height: "20px" }]} />
					</StyledTableRow>
					<StyledTableRow status="">
						<Shimmer boxes={[{ width: "100%", height: "20px" }]} />
					</StyledTableRow>
				</div>
			) : (
				tableRows.map((tableRow) => {
					return (
						<TableRow
							uuid={tableRow.uuid}
							id={tableRow.id}
							index={tableRow.index}
							name={tableRow.name}
							url={tableRow.url}
							status={tableRow.status}
							tag={tableRow.tag}
							frequency={tableRow.frequency}
							lastUpdated={tableRow.lastUpdated}
							device={tableRow.device}
							runningTime={tableRow.runningTime}
							performanceLogs={tableRow.performanceLogs}
							onPause={function (id: string): void {
								props.onPause(id);
							}}
							onEdit={function (id: string): void {
								props.onEdit(tableRow.uuid);
							}}
							onDelete={function (id: string): void {
								props.onDelete(id);
							}}
							onClick={function (id: string): void {
								navigate(`${id}`);
							}}
						/>
					);
				})
			)}
		</StyledTableComponent>
	);
};

export type TableRowType = {
	uuid: string;
	id: string;
	index: number;
	name: string;
	url: string;
	status: string;
	tag: string;
	frequency: string;
	lastUpdated: string;
	device: string;
	runningTime: string;
	performanceLogs: any;
	onPause: (id: string) => void;
	onEdit: (id: string) => void;
	onDelete: (id: string) => void;
	onClick: (id: string) => void;
};

export const TableRow = (props: TableRowType) => {
	return (
		<StyledTableRow
			onClick={(e) => {
				props.onClick(props.id);
			}}
			status={props.status}
		>
			<div className="row-text flex-lil">{props.index + 1}</div>
			<div className="row-text flex-one">{props.name}</div>
			<div className="row-text flex-two">{props.url}</div>
			<div
				className="flex-two"
				style={{ display: "flex", flexDirection: "row", gap: "2px" }}
			>
				<div className="status-container">
					<div className="status-text center">{props.status}</div>
				</div>
				<BootstrapTooltip
					title={
						<InfoHoverComponent
							createdDate={
								props.performanceLogs?.created_at ??
								new Date().toISOString()
							}
							quickResults={
								props.performanceLogs?.quick_results ?? {}
							}
						/>
					}
					placement={"right-end"}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							alignContent: "center",
						}}
					>
						<InfoIcon />
					</div>
				</BootstrapTooltip>
			</div>

			<div className="row-text flex-two center">{props.runningTime}</div>

			<div className="row-text tag flex-two center text-capitalize">
				{props.tag ?? "null"}{" "}
			</div>
			<div className="row-text flex-one center">{props.device}</div>
			<div className="row-text flex-one center">{props.frequency}</div>
			<div className="row-text flex-one center">{props.lastUpdated}</div>
			<div className="flex-one">
				<Dropdown
					options={[
						{ id: "pause", value: props.status === "Good" ? "Active" : props.status, selected: true },
						{ id: "edit", value: "Edit", selected: false },
						{ id: "delete", value: "Delete", selected: false },
					]}
					placeholder={""}
					onChange={(selectedOption) => {
						if (selectedOption.id === "edit") {
							props.onEdit(props.uuid);
						}

						if (selectedOption.id === "pause") {
							props.onPause(props.uuid);
						}

						if (selectedOption.id === "delete") {
							props.onDelete(props.uuid);
						}
					}}
					width={"100px"}
					type="icon"
				/>
			</div>
		</StyledTableRow>
	);
};

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#fff",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#fff",
	},
}));

export const StyledTableComponent = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: scroll;
	max-height: 50vh;
	border: 1px solid #d9d9d9;

	.table-header {
		background: #d7c5f3;
		display: flex;
		gap: 10px;
		padding: 10px;
		align-items: center;
		position: sticky;
		top: 0; /* Ensures the header is sticky at the top of the viewport */
		z-index: 5;
	}

	.header-text {
		color: #585858;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin: 5px;
	}

	.flex-lil {
		/* display: flex; */
		flex: 1;
	}

	.flex-one {
		/* display: flex; */
		flex: 2;
	}

	.flex-two {
		/* display: flex; */
		flex: 4;
	}

	.center {
		display: flex;
		justify-content: center;
		text-align: center;
	}
`;

export const StyledTableRow = styled.div<{ status: string }>`
	border: 1px solid #d9d9d9;
	background: #fff;
	display: flex;
	flex: 1;
	padding: 10px;
	align-items: center;

	&:hover {
		// background-color: #e0e0e0;
		cursor: pointer;
	}

	.row-text {
		overflow: hidden;
		color: #000;
		text-overflow: ellipsis;
		font-family: Lato;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		margin: 5px;
		gap: 10px;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.flex-lil {
		flex: 1;
	}

	.flex-one {
		flex: 2;
	}

	.flex-two {
		flex: 3;
	}

	.flex-three {
		flex: 4;
	}

	.center {
		display: flex;
		justify-content: center;
	}

	.tag {
		border-radius: 30px;
		border: 1px solid #cdcdcd;
		display: inline-flex;
		padding: 3px 5px;
		justify-content: center;
		align-items: center;
		gap: 10px;
	}

	.status-container {
		display: flex;
		flex: 2;
		border-radius: 20px;
		background: ${(props) =>
			props.status === "Good"
				? "#0FC000"
				: props.status === "Poor"
				? "#FF3333"
				: props.status === "Needs Improvement"
				? "#ED7717"
				: "#959595"};
		justify-content: center;
		padding: 5px 0px;
		align-items: center;
	}

	.status-text {
		color: #fff;
		font-family: Lato;
		font-size: 10px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
`;
