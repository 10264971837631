import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useApi } from "../../../useApi";
import { SingleUrlDetails } from "./SingleUrlDetails";
import { WarningComponent } from "./WarningComponent";
import { CalculateComponent } from "./CalculateComponent";
import { TabContent, Tabs } from "../../SettingsScreen/SettingsScreen";
import { Good } from "./SuccessComponent";
import { SingleUrlShimmer } from "./SingleUrlShimmer";
import { EditMonitorModal } from "../EditMonitorModal";
import { Modal } from "../../../components/Modal";
import { PerformanceChart } from "./ChartComponent";
import toast, { Toaster } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { BasicPlatform } from "chart.js/dist";
import { AlertLogComponent } from "./AlertLogComponent";

export const SingleUrlScreen = (props: {
	id: string;
	fetchDashboardData: () => Promise<void>;
	dashboardData: any;
	dashboardLoading: boolean;
}) => {
	const { data, error, loading, fetchData } = useApi<any>({
		autoFetch: false,
		method: "get",
		url: `/performance_logs/${props.id}`,
	});

	const [monitorDetails, setMonitorDetails] = useState<any>({});
	const [imageUrl, setImageUrl] = useState("");
	const [fileName, setFileName] = useState("");

	const [showEditModal, setshowEditModal] = useState(false);

	console.log("dev single url screen data", { data, monitorDetails, props });

	useEffect(() => {
		if (props.dashboardData) {
			setMonitorDetails(
				props.dashboardData?.find(
					(monitor: any) => monitor?.id === parseInt(props.id)
				)
			);
		}
	}, [props.dashboardData]);

	const {
		data: runData,
		error: runError,
		loading: runLoading,
		fetchData: run,
	} = useApi<any>({
		autoFetch: false,
		method: "get",
		url: `/report/retrigger/${data?.performance_data?.uuid}`,
	});

	const getStatusColor = (s: any) => {
		if (s === "good") return "#0FC000";
		if (s === "poor") return "#FF3333";
		if (s === "needs_improvement") return "#ED7717";
		return "#959595";
	};

	const getStatus = (s: any) => {
		if (!s) return "In progress";
		if (s === "good") return "Good";
		if (s === "poor") return "Poor";
		if (s === "needs_improvement") return "Needs Improvement";
		return "Paused";
	};

	useEffect(() => {
		if (data) {
			const monitorDetails = data?.performance_data?.monitor;
			// setMonitorDetails(monitorDetails);

			const fileName = data?.performance_data?.file_name;
			console.log("dev fileName", fileName);

			setFileName(fileName);

			const imageUrl = data?.performance_data?.screenshot;
			setImageUrl(imageUrl);

			console.log("dev progress_status", data?.progress_status);

			if (data?.progress_status) {
				setTimeout(() => {
					fetchData();
				}, 5000);
			}
		}
	}, [data]);

	useEffect(() => {
		if (runLoading) {
			toast.loading("Job is being scheduled!");
		}

		if (runData) {
			toast.dismiss();
			toast.success("Job scheduled successfully!");
		}

		if (runError) {
			toast.dismiss();
			toast.error("Job schedule failed");
		}
	}, [runData, runLoading, runError]);

	useEffect(() => {
		fetchData();
		const intervalId = setInterval(fetchData, 5 * 60 * 1000);

		return clearInterval(intervalId);
	}, [props.id]);

	useEffect(() => {
		if (error) {
			fetchData();
		}
	}, [error]);

	if (loading && props.dashboardLoading) {
		return <SingleUrlShimmer />;
	}

	return (
		<StyledSingleScreen>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<SingleUrlDetails
					loading={props.dashboardLoading}
					name={monitorDetails?.name ?? ""}
					url={monitorDetails?.url ?? ""}
					device={monitorDetails?.devices?.[0] ?? ""}
					lastChecked={monitorDetails?.last_checked ?? ""}
					frequency={monitorDetails?.frequency ?? 0}
					tag={monitorDetails?.tag?.[0]?.name ?? ""}
					activeStatus={monitorDetails?.status ?? ""}
					imageUrl={imageUrl ?? ""}
					status={getStatus(data?.performanceStatus)}
					statusColor={getStatusColor(data?.performanceStatus)}
					onEdit={() => {
						setshowEditModal(true);
					}}
					onRun={() => {
						run();
					}}
					onRefresh={() => {
						fetchData();
					}}
				/>
				{data?.performanceStatus === "good" ? (
					<Good
						performanceScore={
							data?.performance_data?.quick_results?.[
								"performance_score"
							] * 100
						}
					/>
				) : (
					<WarningComponent />
				)}
			</div>
			<div>
				<CalculateComponent
					onClick={() => {
						window.open(data?.calculator_button, "_blank");
					}}
				/>
			</div>
			<div>
				<Tabs>
					<TabContent label="Latest Page Speed Report">
						{!error && fileName ? (
							<div
								style={{
									display: "flex",
									flex: 1,
								}}
							>
								<iframe
									src={fileName}
									width="100%"
									height="80vh"
									style={{
										border: "none",
										height: "80vh",
									}}
									title="Iframe Content"
								></iframe>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									height: "50vh",
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<CircularProgress style={{}} />
							</div>
						)}
					</TabContent>
					<TabContent label="Score History">
						<PerformanceChart
							performance_data={[]}
							id={parseInt(props.id) ?? 0}
							fileName={fileName}
						/>
					</TabContent>
					<TabContent label="Alert Log">
						<AlertLogComponent id={props.id} fileName={fileName} />
					</TabContent>
				</Tabs>
			</div>
			{showEditModal && (
				<Modal width={"auto"} onClose={function (): void {}}>
					<EditMonitorModal
						open={true}
						uuid={monitorDetails?.uuid}
						monitorName={monitorDetails?.name ?? ""}
						sliderValues={monitorDetails?.score_thresholds ?? []}
						selectedDeviceId={monitorDetails?.devices?.[0] ?? ""}
						url={monitorDetails?.url ?? ""}
						selectedTagId={monitorDetails?.tag?.[0]?.name ?? ""}
						selectedFrequencyId={monitorDetails?.frequency ?? 0}
						onClose={async function () {
							await fetchData();
							setshowEditModal(false);
						}}
						fetchDashboardData={props.fetchDashboardData}
					/>
				</Modal>
			)}
		</StyledSingleScreen>
	);
};

export const StyledSingleScreen = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 20px;
	background: #f9f9f9;
	flex: 1;
	padding: 10px 50px;
`;
