import { Icon } from "../common/icons-props";

export const WarningIcon: Icon = (props) => {
	const { width, height, color, isSelected } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="56"
			height="56"
			viewBox="0 0 56 56"
			fill="none"
		>
			<path
				d="M26.3199 51.1318H26.3196C19.0586 51.1318 12.9494 50.7911 8.18966 50.3736C5.25568 50.1163 3.41862 47.0789 4.54343 44.3569C7.66346 36.8062 11.5155 29.3473 16.0345 22.1132C19.1104 17.189 22.0799 13.0386 24.5909 9.77309C26.3421 7.49569 29.768 7.49503 31.5194 9.77233C34.0179 13.0215 36.9715 17.1485 40.0321 22.0423C44.528 29.231 48.3656 36.643 51.4805 44.1472C52.6114 46.8717 50.7673 49.9135 47.8288 50.1717C40.5726 50.8095 33.3616 51.1318 26.3199 51.1318Z"
				fill="#DD636E"
			/>
			<path
				d="M29.0812 8.18647C28.2292 8.41047 27.4316 8.94477 26.8419 9.78816C24.5383 13.0825 21.8145 17.2702 18.9931 22.2381C14.8476 29.5364 11.3138 37.0615 8.45219 44.6785C7.51682 47.1667 8.81313 49.9163 11.0678 50.6037C10.1079 50.5344 9.14854 50.4578 8.1898 50.3739C5.25538 50.1164 3.41908 47.0796 4.54313 44.3567C7.66327 36.8068 11.5159 29.3478 16.0348 22.1136C19.1109 17.1898 22.0799 13.0385 24.5911 9.77383C25.6996 8.33194 27.4805 7.80256 29.0812 8.18647Z"
				fill="#DA4A54"
			/>
			<path
				d="M48.3253 29.7568V29.4402C48.3253 28.3237 49.1898 27.3979 50.3038 27.3214L53.7301 27.0864C54.9574 27.0021 55.9992 27.975 55.9992 29.2052V29.9918C55.9992 31.2219 54.9573 32.1948 53.7301 32.1106L50.3038 31.8756C49.1899 31.7992 48.3253 30.8734 48.3253 29.7568ZM45.6812 19.5725L45.523 19.2983C44.9647 18.3313 45.2505 17.0973 46.1769 16.4741L49.0267 14.5575C50.0475 13.8709 51.4361 14.1925 52.0512 15.2578L52.4446 15.9391C53.0597 17.0044 52.6437 18.3679 51.5388 18.9086L48.454 20.4182C47.4511 20.9091 46.2395 20.5395 45.6812 19.5725ZM38.2993 12.0748L38.0251 11.9165C37.0581 11.3582 36.6886 10.1466 37.1794 9.14372L38.689 6.05891C39.2298 4.954 40.5932 4.53816 41.6585 5.15318L42.3398 5.54649C43.4051 6.16161 43.7267 7.55024 43.0402 8.57104L41.1235 11.4208C40.5004 12.3472 39.2663 12.633 38.2993 12.0748Z"
				fill="#DD636E"
			/>
			<path
				d="M7.67397 29.7568V29.4402C7.67397 28.3237 6.80947 27.3979 5.69548 27.3214L2.26909 27.0864C1.04191 27.0021 0 27.975 0 29.2052V29.9918C0 31.2219 1.04191 32.1948 2.26909 32.1106L5.69548 31.8756C6.80936 31.7992 7.67397 30.8734 7.67397 29.7568ZM10.318 19.5725L10.4763 19.2983C11.0345 18.3313 10.7487 17.0973 9.82231 16.4741L6.97255 14.5575C5.95175 13.8709 4.56313 14.1925 3.948 15.2578L3.55469 15.9391C2.93956 17.0044 3.35552 18.3679 4.46042 18.9086L7.54523 20.4182C8.54809 20.9091 9.75975 20.5395 10.318 19.5725ZM17.6999 12.0748L17.9741 11.9165C18.9411 11.3582 19.3106 10.1466 18.8198 9.14372L17.3102 6.05891C16.7695 4.954 15.406 4.53816 14.3407 5.15318L13.6594 5.54649C12.5941 6.16161 12.2725 7.55024 12.9591 8.57104L14.8758 11.4208C15.4989 12.3472 16.733 12.633 17.6999 12.0748Z"
				fill="#DD636E"
			/>
			<path
				d="M26.7848 19.7008C27.6018 19.6186 28.4188 19.6186 29.2359 19.7008C30.2696 19.8048 31.0609 20.6681 31.0991 21.7063C31.2236 25.1011 31.2236 28.4994 31.0991 31.8942C31.061 32.9324 30.2696 33.7958 29.2359 33.8997C28.4188 33.9819 27.6018 33.9819 26.7848 33.8997C25.7511 33.7957 24.9597 32.9324 24.9216 31.8942C24.797 28.4994 24.797 25.1011 24.9216 21.7063C24.9597 20.668 25.7511 19.8047 26.7848 19.7008ZM26.844 37.6268C27.6216 37.5896 28.3991 37.5896 29.1767 37.6268C30.2482 37.6781 31.1088 38.5406 31.1571 39.6123C31.2043 40.657 31.2043 41.7018 31.1571 42.7466C31.1088 43.8183 30.2482 44.6808 29.1767 44.7321C28.3991 44.7693 27.6216 44.7693 26.844 44.7321C25.7725 44.6808 24.9119 43.8183 24.8636 42.7466C24.8164 41.7024 24.8164 40.6565 24.8636 39.6123C24.9119 38.5406 25.7725 37.6781 26.844 37.6268Z"
				fill="white"
			/>
		</svg>
	);
};
