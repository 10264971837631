import { useState, useEffect, useRef } from "react";

export default function Component() {
  return (
    <div style={{width: '100%', height: '100%', paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10, background: '#EAE3F6', borderRadius: 6, justifyContent: 'space-between', alignItems: 'center', gap: 20, display: 'inline-flex', marginRight: "20px"}}>
    <div style={{color: 'black', fontSize: 16, fontFamily: 'Lato', fontWeight: '500', wordWrap: 'break-word'}}>You are using free plan. You can add upto 30 monitors in free plan. To add More monitors or Group Monitors upgrade to pro plan</div>
        <button style={{border:'none', paddingLeft: 30, paddingRight: 30, paddingTop: 10, paddingBottom: 10, background: '#651BDC', boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)', borderRadius: 10, justifyContent: 'center', alignItems: 'center', gap: 10, display: 'flex', width: "max-content",  position: 'relative', cursor: 'pointer'}}>
           
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Frame">
                <g id="Group">
                <path id="Vector" d="M1 9.25C5.65525 10.3025 6 15 6 15C6 15 6.34475 10.3025 11 9.25C6.34475 8.1975 6 3.5 6 3.5C6 3.5 5.65525 8.1975 1 9.25ZM9.5 12.5C12.0603 13.05 12.25 15.5 12.25 15.5C12.25 15.5 12.4397 13.05 15 12.5C12.4397 11.95 12.25 9.5 12.25 9.5C12.25 9.5 12.0603 11.95 9.5 12.5ZM7.75 4.5C10.3103 5.05 10.5 7.5 10.5 7.5C10.5 7.5 10.6897 5.05 13.25 4.5C10.6897 3.95 10.5 1.5 10.5 1.5C10.5 1.5 10.3103 3.95 7.75 4.5Z" fill="white"/>
                </g>
                </g>
                </svg>
                
          
       
        <span style={{color: 'white', fontSize: 16, fontFamily: 'Lato', fontWeight: '700', wordWrap: 'break-word', cursor: 'pointer'}}>Upgrade to Pro</span>
        </button>
    
</div>
  );
}


