import React, { useEffect, useState } from "react";
import { Modal } from "../../components/Modal";
import { TitleMedium } from "../../common/Typography";
import { Input } from "../../components/Input";
import { COLORS } from "../../common/Theme";
import { Button } from "../../components/Button";

export const AddEditEmailModal = (props: {
	type: "add" | "edit";
	onSubmit: (input: string) => void;
	onClose: () => void;
	defaultInput: string;
}) => {
	const [input, setInput] = useState(props.defaultInput);

	useEffect(() => {
		setInput(props.defaultInput);
	}, [props.defaultInput]);

	return (
		<Modal width={"500px"} onClose={props.onClose}>
			<div
				style={{
					background: "white",
					padding: "40px",
					borderRadius: "10px",
					display: "flex",
					flexDirection: "column",
					gap: "10px",
				}}
			>
				<TitleMedium color={COLORS.common.black}>
					{props.type === "add" ? "Add Email" : "Edit Email"}
				</TitleMedium>
				<Input
					inputValue={input}
					onInputChange={setInput}
					placeHolder="Enter email"
					width="auto"
					style={{ margin: "20px 0px" }}
				/>
				<div
					style={{
						display: "flex",
						gap: "10px",
						alignItems: "center",
						justifyContent: "flex-end",
					}}
				>
					<Button
						buttonType="inverse"
						text="Cancel"
						width="auto"
						fontSize={14}
						onClick={() => {
							props.onClose();
						}}
					/>
					<Button
						buttonType="primary"
						text={props.type === "add" ? "Add" : "Update"}
						width="auto"
						fontSize={14}
						onClick={() => {
							props.onSubmit(input);
						}}
					/>
				</div>
			</div>
		</Modal>
	);
};
