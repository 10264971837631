import { ThemeProvider } from "styled-components";
import { UnAuthenticatedHome } from "./Screens/HomeScreen/UnAuthenticatedHome";
import { DEFAULT_THEME } from "./common/Theme";
import { AuthPage } from "./Screens/AuthScreen/AuthScreen";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AddMonitor } from "./Screens/AddMonitorScreen/AddMonitor";
import { SingleMonitor } from "./Screens/AddMonitorScreen/SingleMonitor";
import { AuthenticatedHome } from "./Screens/HomeScreen/AuthenticatedHome";
import { useAppStore } from "./common/store";
import { useEffect, useState } from "react";
import { InstantUrlReportScreen } from "./Screens/InstantUrlReportScreen/InstantUrlReportScreen";
import { useApi } from "./useApi";

interface DashboardData {
    monitor_count: number;
}
export const ROUTES = {
	UNAUTHENTICATED_HOME: "/login",
};

const App = () => {
	const { token } = useAppStore();
	const navigate = useNavigate();
	const location = useLocation();
	const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);

    useEffect(() => {
        const authPages = ["/login", "/signup", "/forgot-password"];

        if (token && authPages.includes(location.pathname)) {
            const fetchDashboardData = async () => {
                try {
                    const accessToken = useAppStore.getState().token; // Get the token from the state
                    const response = await fetch("https://pagespeed.fulgid.in/api/monitor-count", {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                    });

                    if (!response.ok) {
                        throw new Error("Failed to fetch monitor count");
                    }

                    const data: DashboardData = await response.json(); // Specify the type explicitly here
                    setDashboardData(data);
                } catch (error) {
                    if (error instanceof Error) {
                        console.error("Error fetching monitor count:", error.message);
                    } else {
                        console.error("Unknown error occurred while fetching monitor count");
                    }
                }
            };

            fetchDashboardData();
        } else if (!token && !authPages.includes(location.pathname)) {
            navigate("/login"); // Redirect to login if not authenticated
        }
    }, [token, location.pathname, navigate]);

	const fetchDashboardData = async () => {
		try {
			const accessToken = useAppStore.getState().token; // Get the token from the state
			const response = await fetch("https://pagespeed.fulgid.in/api/monitor-count", {
				method: "GET",
				headers: {
					Authorization: `Bearer ${accessToken}`,
					"Content-Type": "application/json",
				},
			});
	
			if (!response.ok) {
				throw new Error("Failed to fetch monitor count");
			}
	
			const data = await response.json();
			const monitorCount = data.monitor_count; // Extract monitor count from the API response
			console.log("Monitor count:", monitorCount);
	
			// Now you can use the monitor count for further processing
		} catch (error) {
			console.error("Error fetching monitor count:");
		}
	};
	

	return (
		<ThemeProvider theme={DEFAULT_THEME}>
			<div className="App">
				<Routes>
					<Route path="/" element={<UnAuthenticatedHome />} />
					<Route
						path="/instant-report/:device"
						element={<InstantUrlReportScreen />}
					/>

					<Route
						path="/login"
						element={<AuthPage pageType={"login"} />}
					/>
					
					<Route
						path="/signup"
						element={<AuthPage pageType={"signup"} />}
					/>
					<Route
						path="/forgot-password"
						element={<AuthPage pageType={"forgot-password"} />}
					/>

					<Route
						path="/password-reset/:token"
						element={<AuthPage pageType={"password-reset"} />}
					/>

					<Route path="/add-monitor" element={<AddMonitor />} />
					<Route
						index
						path="/add-monitor/:monitorType"
						element={<SingleMonitor />}
					/>
					<Route
						path="/:screenName"
						element={<AuthenticatedHome />}
					/>
					<Route
						path="/:screenName/:id"
						element={<AuthenticatedHome />}
					/>
				</Routes>
			</div>
		</ThemeProvider>
	);
};

export default App;
