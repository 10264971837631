import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { COLORS } from "../../common/Theme";
import { TitleLarge, TitleSmall } from "../../common/Typography";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import CustomSliderWrapper, {
	CustomSliderWrapperProps,
} from "../AddMonitorScreen/CustomSliderWrapper";
import { MultipleUrlInput } from "../AddMonitorScreen/MultipleUrlInput";
import {
	deviceOptions,
	locationOptions,
	frequencyOptions,
	sliderOptions,
} from "../AddMonitorScreen/SingleMonitor";
import { UploadFileInput } from "../AddMonitorScreen/UploadFileInput";
import InputWithDropdown from "./InputWithDropdown";
import styled from "styled-components";
import { Button } from "../../components/Button";
import { validateUrl } from "../../common/utils";
import { Input } from "../../components/Input";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../useApi";
import { useCreateMonitorApi } from "../AddMonitorScreen/useCreateMonitorApi";

export const AddAnyMonitor = (props: {
	monitorType: "single" | "multiple";
	onClose?: () => void;
	fetchDashboardData?: () => Promise<void>;
}) => {
	const { monitorType } = props;
	// const [selectedProtocol, setSelectedProtocol] = useState('https');
	// const protocolOptions = [
	// 	{ id: 'https', value: 'https', label: 'HTTPS' , selected: false },
	// 	{ id: 'http', value: 'http', label: 'HTTP', selected: true }
	// ];
	
	const [selectedDevice, setSelectedDevice] = useState<DropDownOption | null>(
		deviceOptions.find((option) => option.selected) || null
	);
	const [selectedLocation, setSelectedLocation] =
		useState<DropDownOption | null>(
			locationOptions.find((option) => option.selected) || null
		);

	const [selectedFrequency, setSelectedFrequency] =
		useState<DropDownOption | null>(
			frequencyOptions.find((option) => option.selected) || null
		);
	const [urls, setUrls] = useState<string[]>([]);
	const [multipleUrlType, setMultipleUrlType] = useState<"manual" | "file">(
		urls.length ? "manual" : "file"
	);

	const [monitorName, setMonitorName] = useState("");

	const navigate = useNavigate();

	const [sliderValues, setSliderValues] = useState<CustomSliderWrapperProps[]>(sliderOptions);

	const [selectedTag, setSelectedTag] = useState("");
	const [tagOptions, setTagOptions] = useState<DropDownOption[]>([]);
	const [errorMessage, setErrorMessage] = useState("");

	const { data, error, loading, fetchData } = useCreateMonitorApi({
		name: monitorName,
		device: selectedDevice?.value ?? "",
		urls: urls,
		sliderValues: sliderValues,
		frequency: selectedFrequency?.value ?? "",
		tag: selectedTag,
		fetchDashboardData: props.fetchDashboardData,
		onClose: props.onClose,
	});

	const {
		data: tagData,
		error: tagError,
		loading: tagLoading,
	} = useApi<any>({
		method: "get",
		url: "/tags",
	});

	useEffect(() => {
		if (error) {
			toast.error(error);
		}
	}, [error]);

	const validate = () => {
		const filteredUrls = urls.filter((url) => !validateUrl(url));

		if (filteredUrls && filteredUrls.length > 0) {
			return "The Url must be a valid URL. Please include http:// or https:// if necessary.";
		}

		if (!selectedFrequency?.id) {
			return "Please select the frequency";
		}

		if (!selectedTag) {
			return "Please enter tag";
		}

		if (!selectedDevice) {
			return "Please select device";
		}

		if (!monitorName) {
			return "Please enter monitor name";
		}

		if (!monitorName) {
			return "Please enter monitor name";
		}
	};

	useEffect(() => {
		console.log("dev tagdata", tagData);
		if (tagData) {
			const tempTagOptions = tagData?.map((tag: any) => {
				return {
					id: tag?.name ?? "",
					value: tag?.name ?? "",
					selected: false ?? "",
				};
			});
			setTagOptions(tempTagOptions);
		}
	}, [tagData]);

	return (
		<ContentSection>
			<ContentWrapper style={{ padding: "60px 30px" }}>
				<TitleLarge
					color={COLORS.common.black}
					style={{ fontSize: "35px" }}
				>
					Add URL Monitor
				</TitleLarge>
				<TitleSmall color="#959595" style={{ fontSize: "15px" }}>
					You can add a monitor and threshold score to set alerts, get
					notified, and track history.
				</TitleSmall>
				<Input
					placeHolder="Monitor Name*"
					fontSize="16px"
					inputValue={monitorName}
					onInputChange={setMonitorName}
				/>
											<SomeOtherSection>
								{/* <DropdownSection>

					  <Dropdown
					  options={protocolOptions}
					  placeholder="Protocol*"
					  onChange={(option) => setSelectedProtocol(option.value)}
					  width="156px"
				  />
				  				</DropdownSection> */}

				{monitorType === "single" && (
				
				
					<Input
						placeHolder="URL (http://www.example.com)*"
						onInputChange={(value) => setUrls([value])}


						fontSize="16px"
						
					/>
				)}
	</SomeOtherSection>
				{monitorType === "multiple" &&
					(multipleUrlType === "file" ? (
						<UploadFileInput
							urls={urls}
							onUploadSuccess={setUrls}
							onManualClick={() => setMultipleUrlType("manual")}
						/>
					) : (
						<MultipleUrlInput
							urls={urls}
							onUrlsChange={setUrls}
							onUploadClick={() => setMultipleUrlType("file")}
						/>
					))}
				<DropdownSection>
					<Dropdown
						options={deviceOptions}
						placeholder="Device*"
						onChange={setSelectedDevice}
						width="256px"
					/>
					<Dropdown
						options={locationOptions}
						placeholder="Location*"
						onChange={setSelectedLocation}
						width="256px"
					/>
				</DropdownSection>
				<SomeOtherSection>
					<InputWithDropdown
						onInputChange={(input) => setSelectedTag(input)}
						options={tagOptions}
					/>
					<Dropdown
						options={frequencyOptions}
						placeholder="Check Weekly Once*"
						onChange={setSelectedFrequency}
						width="256px"
					/>
				</SomeOtherSection>
			</ContentWrapper>
			<SlidersAndFinishSection>
				{sliderValues.map((sliderValue, index) => (
					<CustomSliderWrapper
						key={sliderValue.id}
						id={sliderValue.id}
						label={sliderValue.label}
						value={sliderValue.value}
						onChange={(
							id: string,
							value: number,
							label: string
						) => {
							const filteredSliderValues = sliderValues.map(
								(sv) => {
									if (sv.id !== id) {
										return sv;
									} else {
										return {
											id,
											label,
											value,
										};
									}
								}
							);

							setSliderValues(filteredSliderValues);
						}}
					/>
				))}
				<TitleSmall color="#959595" style={{ fontSize: "15px" }}>
					You will be notified when one or more scores drops below the
					threshold value through email.
				</TitleSmall>
				<FinishButton>
					{props.onClose && (
						<Button
							text="Cancel"
							size="small"
							buttonType="inverse"
							fontSize={16}
							onClick={() => {
								props.onClose?.();
							}}
						/>
					)}
					<Button
						isLoading={loading}
						text="Finish"
						size="small"
						buttonType="primary"
						fontSize={16}
						onClick={() => {
							const err = validate();
							if (err) {
								toast.error(err);
							}
							fetchData();
						}}
					/>
				</FinishButton>
			</SlidersAndFinishSection>
		</ContentSection>
	);
};

const ContentSection = styled.div`
	display: flex;
	padding: 20px;
	margin: 50px;
	border-radius: 20px;
	background: ${COLORS.common.white};
	box-shadow: 0px -0.055px 2.684px 0px rgba(0, 0, 0, 0.03),
		0px -0.133px 6.451px 0px rgba(0, 0, 0, 0.04),
		0px -0.25px 12.146px 0px rgba(0, 0, 0, 0.05),
		0px -0.447px 21.666px 0px rgba(0, 0, 0, 0.05),
		0px -0.836px 40.524px 0px rgba(0, 0, 0, 0.06),
		0px -2px 97px 0px rgba(0, 0, 0, 0.09);
`;

const ContentWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 10px;
	gap: 30px;
`;

const DropdownSection = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px 0;
`;

const SlidersAndFinishSection = styled.div`
	display: flex;
	flex: 1;
	padding: 10px;
	flex-direction: column;
	justify-content: space-evenly;
`;

const FinishButton = styled.div`
	display: flex;
	width: 30px;
	align-self: flex-end;
	justify-content: flex-end;
`;

const SomeOtherSection = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
