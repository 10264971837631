import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "./Button";
import { OptionsIcon } from "../icons/OptionsIcon";
import { Avatar } from "@mui/material";
import { COLORS } from "../common/Theme";
import { DownArrowIcon } from "../icons/DownArrowIcon";
// import DownArrowSvg from "./downArrow.svg"; // Import your SVG file

export type DropDownOption = {
	id: string;
	value: string;
	selected: boolean;
};

export type DropdownProps = {
	options: DropDownOption[];
	placeholder: string;
	onChange: (selectedOption: DropDownOption) => void;
	width: string;
	type?: "normal" | "button" | "icon" | "avatar";
	openedWidth?: string;
	disabled?: boolean;
};

const CustomDropdown = styled.div`
	position: relative;
	display: inline-flex;
	flex-direction: column;
`;

const ToggleButton = styled.div`
	cursor: pointer;
	padding: 8px;
	border-bottom: 1px solid #d9d9d9;
	/* background-color: #fff; */
	display: flex;
	// justify-content: center;
	// align-items: center;
	// width: 50px;
	// border-radius: 50%;
	// height: 50px;

	.dropdown-placeholder {
		color: #959595;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.dropdown-text {
		color: #000;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;

const StyledOption = styled.div`
	cursor: pointer;
	padding: 8px;
	border-radius: 6px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: "auto";

	.dropdown-placeholder {
		color: #959595;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.dropdown-text {
		color: #000;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;

const OptionsContainer = styled.div<{ openedWidth: string }>`
	position: fixed;
	/* top: 100%; */
	/* top: 10; */
	/* right: 0; */
	margin-top: 5px;
	width: ${(props) => props.openedWidth};
	background-color: #fff;
	border: 1px solid #ccc;
	border-top: none;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	z-index: 200;
	visibility: visible;
`;

const Option = styled.div<{ isSelected: boolean }>`
	padding: 8px;
	cursor: pointer;
	background-color: ${(props) => "transparent"};
	color: ${(props) => "inherit"};

	&:hover {
		background-color: #f2f2f2;
	}

	color: #000;
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Dropdown: React.FC<DropdownProps> = ({
	options,
	placeholder,
	onChange,
	width,
	type = "normal",
	openedWidth,
	disabled = false,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(
		options.find((option) => option.selected) || null
	);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const [dropdownStyle, setDropdownStyle] = useState({});

	const handleOptionClick = (option: DropDownOption) => {
		onChange(option);
		setIsOpen(false);

		if (type === "normal") {
			setSelectedOption(option);
		}
	};

	useEffect(() => {
		setSelectedOption(options.find((option) => option.selected) || null);
	}, [options]);

	const handleClickOutside = (event: MouseEvent) => {
		if (
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (isOpen && dropdownRef.current) {
			const rect = dropdownRef.current.getBoundingClientRect();
			const bottomSpace = window.innerHeight - rect.bottom;
			const dropdownHeight = 100; // You might need a way to dynamically get this or set a reasonable default

			let top = rect.top + window.scrollY + rect.height;
			if (bottomSpace < dropdownHeight) {
				top = rect.top + window.scrollY - dropdownHeight; // Adjust to open upwards
			}

			const left = rect.left + window.scrollX;

			setDropdownStyle({
				top: `${top}px`,
			});
		}
	}, [isOpen, dropdownRef]);

	return (
		<CustomDropdown ref={dropdownRef} style={{ width }}>
			{type === "button" ? (
				<Button
					width={width}
					style={{
						height: "auto",
					}}
					text={placeholder}
					onClick={() => setIsOpen(!isOpen)}
				>
					<div
						className={
							selectedOption
								? "dropdown-text"
								: "dropdown-placeholder"
						}
					>
						{selectedOption ? selectedOption.value : placeholder}
					</div>
					{/* <DownArrowIcon src={""} alt="Down Arrow" /> */}
				</Button>
			) : type === "icon" ? (
				<StyledOption
					onClick={(e) => {
						e.stopPropagation();
						setIsOpen(!isOpen);
					}}
				>
					<OptionsIcon>
						<div
							className={
								selectedOption
									? "dropdown-text"
									: "dropdown-placeholder"
							}
						>
							{selectedOption
								? selectedOption.value
								: placeholder}
						</div>
					</OptionsIcon>
				</StyledOption>
			) : type === "avatar" ? (
				<div onClick={() => setIsOpen(!isOpen)}>
					<Avatar style={{ background: COLORS.common.brand }}>
						{placeholder}
					</Avatar>
				</div>
			) : (
				<ToggleButton onClick={() => setIsOpen(!isOpen)}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							width: "100%",
							alignItems: "center",
						}}
					>
						<div
							className={
								selectedOption
									? "dropdown-text"
									: "dropdown-placeholder"
							}
						>
							{selectedOption
								? selectedOption.value
								: placeholder}
						</div>
						<DownArrowIcon />
					</div>
				</ToggleButton>
			)}

			{isOpen && !disabled && (
				<OptionsContainer
					openedWidth={openedWidth ?? width}
					style={dropdownStyle}
				>
					{options.map((option) => (
						<Option
							key={option.id}
							isSelected={option.selected}
							onClick={(e) => {
								e.stopPropagation();
								handleOptionClick(option);
							}}
						>
							{option.value}
						</Option>
					))}
				</OptionsContainer>
			)}
		</CustomDropdown>
	);
};

export default Dropdown;
