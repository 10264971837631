import React from "react";
import Dropdown, { DropDownOption } from "../../components/Dropdown";
import styled from "styled-components";
import { ConfigureIcon } from "../../icons/ConfigureIcon";

export const deviceOptionsUnauthenticated: DropDownOption[] = [
	{ id: "desktop", value: "Desktop", selected: true },
	{ id: "mobile", value: "Mobile", selected: false },
];

export const ConfigureComponent = (props: {
	device: DropDownOption;
	setDevice: (device: DropDownOption) => void;
}) => {
	return (
		<StyledConfigureComponent>
			<div
				style={{
					display: "flex",
					gap: "20px",
					flexDirection: "column",
					margin: "20px",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						gap: "10px",
					}}
				>
					<div style={{ display: "flex" }}>
						<ConfigureIcon />
					</div>
					<div className="configure-text">Configure</div>
				</div>
				<div
					style={{
						display: "flex",
						gap: "20px",
						flexDirection: "column",
					}}
				>
					<div>
						<div className="dropdown-label-text">Device</div>
					</div>
					<Dropdown
						options={deviceOptionsUnauthenticated}
						placeholder={"Device"}
						width={"251px"}
						onChange={function (
							selectedOption: DropDownOption
						): void {
							props.setDevice(selectedOption);
						}}
					/>
				</div>
				<div
					style={{
						display: "flex",
						gap: "20px",
						flexDirection: "column",
					}}
				>
					<div className="dropdown-label-text">Location</div>
					<Dropdown
						options={[
							{
								id: "india",
								value: "India",
								selected: true,
							},
						]}
						placeholder={"India"}
						width={"251px"}
						onChange={function (
							selectedOption: DropDownOption
						): void {}}
					/>
				</div>
			</div>
			<div></div>
		</StyledConfigureComponent>
	);
};

const StyledConfigureComponent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #959595;
	margin: 10px;

	.configure-text {
		color: #651bdc;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.dropdown-label-text {
		color: #959595;
		font-family: Lato;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
`;
