import { Icon } from "../common/icons-props";

export const AlertDashboardIcon: Icon = (props) => {
	const { width, height, color } = props;

	return (
		<svg
			width="22"
			height="20"
			viewBox="0 0 22 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.7529 1.125V7.125C21.7529 7.42337 21.6344 7.70952 21.4234 7.92049C21.2124 8.13147 20.9263 8.25 20.6279 8.25C20.3295 8.25 20.0434 8.13147 19.8324 7.92049C19.6214 7.70952 19.5029 7.42337 19.5029 7.125V3.84375L12.4238 10.9237C12.3193 11.0286 12.1951 11.1118 12.0584 11.1686C11.9216 11.2254 11.775 11.2546 11.627 11.2546C11.4789 11.2546 11.3323 11.2254 11.1955 11.1686C11.0588 11.1118 10.9346 11.0286 10.8301 10.9237L7.87789 7.96875L1.92383 13.9209C1.71248 14.1323 1.42584 14.251 1.12695 14.251C0.828065 14.251 0.541421 14.1323 0.330076 13.9209C0.118732 13.7096 4.45375e-09 13.4229 0 13.1241C-4.45375e-09 12.8252 0.118732 12.5385 0.330076 12.3272L7.08008 5.57719C7.18459 5.47231 7.30878 5.38909 7.44553 5.33231C7.58228 5.27553 7.72889 5.2463 7.87695 5.2463C8.02502 5.2463 8.17163 5.27553 8.30837 5.33231C8.44512 5.38909 8.56931 5.47231 8.67383 5.57719L11.6279 8.53125L17.9091 2.25H14.6279C14.3295 2.25 14.0434 2.13147 13.8324 1.9205C13.6214 1.70952 13.5029 1.42337 13.5029 1.125C13.5029 0.826631 13.6214 0.540483 13.8324 0.329505C14.0434 0.118526 14.3295 6.28765e-09 14.6279 0H20.6279C20.9263 0 21.2124 0.118526 21.4234 0.329505C21.6344 0.540483 21.7529 0.826631 21.7529 1.125Z"
				fill="#0FC000"
			/>
		</svg>
	);
};
