import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "../../components/Input";
import { CustomSlider } from "../../components/CustomSlider";

export type CustomSliderWrapperProps = {
	id: string;
	label: string;
	value: number;
	onChange?: (id: string, value: number, label: string) => void;
};

export const CustomSliderWrapper = (props: CustomSliderWrapperProps) => {
	const [percentageValue, setPercentageValue] = useState(props.value);

	return (
		<SliderWrapper>
			<Header>
				<h4>{props.label}</h4>
				<Input
					width="120px"
					inputValue={String(percentageValue)}
					onInputChange={(input) => {
						setPercentageValue(Number(input));
						props.onChange?.(props.id, Number(input), props.label);
					}}
					fontSize="16px"
				/>
			</Header>
			<CustomSlider
				displayValue={percentageValue}
				onChange={(value) => {
					setPercentageValue(value);
					props.onChange?.(props.id, value, props.label);
				}}
			/>
		</SliderWrapper>
	);
};

const SliderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 569px;
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
`;

export default CustomSliderWrapper;
