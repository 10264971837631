import React from "react";
import styled from "styled-components";

export const BillingAndPlan = () => {
	return <StyledBillingPlan>Coming Soon</StyledBillingPlan>;
};

const StyledBillingPlan = styled.h1`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: 50vh;
`;
