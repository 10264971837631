import { Shimmer } from "../../../common/ShimmerComponent";
import { StyledSingleUrlDetailScreen } from "./SingleUrlDetails";
import { StyledSingleScreen } from "./SingleUrlScreen";
import { StyledWarningShimmerComponent } from "./WarningComponent";

export const SingleUrlDetailsShimmer = () => {
	return (
		<StyledSingleUrlDetailScreen>
			<div>
				<Shimmer boxes={[{ width: "100%", height: "50px" }]} />
			</div>
			<div style={{ display: "flex", gap: "10px", width: "100%" }}>
				<div>
					<Shimmer boxes={[{ width: "200px", height: "200px" }]} />
				</div>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						width: "100%",
					}}
				>
					<Shimmer boxes={[{ width: "100%", height: "40px" }]} />
					<Shimmer boxes={[{ width: "100%", height: "40px" }]} />
					<Shimmer boxes={[{ width: "100%", height: "40px" }]} />
					<Shimmer boxes={[{ width: "100%", height: "40px" }]} />
				</div>
			</div>
		</StyledSingleUrlDetailScreen>
	);
};

export const SingleUrlShimmer = () => {
	return (
		<StyledSingleScreen>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					gap: "10px",
					justifyContent: "space-between",
				}}
			>
				<SingleUrlDetailsShimmer />
				<StyledWarningShimmerComponent>
					<Shimmer boxes={[{ width: "100%", height: "100%" }]} />
					<Shimmer boxes={[{ width: "100%", height: "100%" }]} />
					<Shimmer boxes={[{ width: "100%", height: "100%" }]} />
				</StyledWarningShimmerComponent>
			</div>
			<div
				style={{
					margin: "10px",
					padding: "10px",
					display: "flex",
					background: "#fff",
				}}
			>
				<Shimmer boxes={[{ width: "100%", height: "80px" }]} />
			</div>
			<div
				style={{ display: "flex", background: "#fff", padding: "20px" }}
			>
				<Shimmer boxes={[{ width: "100%", height: "400px" }]} />
			</div>
		</StyledSingleScreen>
	);
};
