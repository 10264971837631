import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
	TitleLarge,
	TitleMedium,
	TitleRegular,
	TitleSmall,
} from "../../common/Typography";
import { COLORS } from "../../common/Theme";
import { Button } from "../../components/Button";

export const InstantUrlRightContainer = () => {
	const navigate = useNavigate();

	return (
		<ImageContainer>
			<StyledImage
				src={require("../../../src/instant_background.png")}
				alt="Overlay Image"
			/>
			<Overlay>
				<TitleMedium
					color={COLORS.common.black}
					style={{ textAlign: "center", fontSize:"24px", fontWeight:"800", padding:"10px" }}>
					Want to configure this on a weekly basis and set alerts?
				</TitleMedium>
				<Button
					buttonType="primary"
					onClick={() => {
						navigate("/signup");
					}}
					text={"Signup"}
					style={{background:"#ED7717"}}
				/>
			</Overlay>
		</ImageContainer>
	);
};

const ImageContainer = styled.div`
	position: relative;
	width: 50%; /* Adjust based on your requirement */
	height: 293px; /* Adjust based on your requirement */
	background: white;
	margin: 20px;

	height:360px;
`;

const StyledImage = styled.img`
	width: 100%;
	height: 100%;
	background: white;
`;

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column; /* Stack items vertically */
	gap: 10px;
`;
